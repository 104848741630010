'use client'

import React, { useRef, FC, useCallback, useState } from "react";
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet";
//import Input from "shared/Input/Input";
import { Input } from '../../_components/Input'
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link } from "react-router-dom";
import axios from "axios";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from '../../_providers/Auth'
import { useForm, SubmitHandler } from "react-hook-form"
import dotenv from "dotenv"

export interface PageSignUpProps {
  className?: string;
}

const loginSocials = [
  {
    name: "Continue with Facebook",
    href: "#",
    icon: facebookSvg,
  },
  {
    name: "Continue with Twitter",
    href: "#",
    icon: twitterSvg,
  },
  {
    name: "Continue with Google",
    href: "#",
    icon: googleSvg,
  },
];


type FormData = {
  name: string
  email: string
  password: string
  passwordConfirm: string
}



const PageSignUp: FC<PageSignUpProps> = ({ className = "" }) => {

  const searchParams = useSearchParams()
  const allParams = searchParams.toString() ? `?${searchParams.toString()}` : ''
  const { login } = useAuth()
  const router = useNavigate()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<FormData>()

  const password = useRef({})
  password.current = watch('password', '')

  const onSubmit = useCallback(
    
    async (data: FormData) => { console.log(JSON.stringify(data));
      const response = await fetch(`http://localhost:3000/api/users`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (!response.ok) {  
        const message = response.statusText || 'There was an error creating the account.'
        console.log(message)
        setError(message)
        return
      }

   console.log(response.ok+"---Response : "+JSON.stringify(response))

    //  const redirect = searchParams.get('redirect')

      const timer = setTimeout(() => {
        setLoading(true)
      }, 1000)

      try {
        await login(data)
        clearTimeout(timer)
//        if (redirect) router.push(redirect as string)
//        else router.push(`/account?success=${encodeURIComponent('Account created successfully')}`)
      } catch (_) {
        clearTimeout(timer)
        setError('There was an error with the credentials provided. Please try again.')
        console.log('There was an error with the credentials provided. Please try again.')
      }
    },
    [login, router, searchParams],
  )


  return (
    <div className={`nc-PageSignUp  ${className}`} data-nc-id="PageSignUp">
      <Helmet>
        <title>Sign up || Booking React Template</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
        S'inscrire
        </h2>
        <div className="max-w-md mx-auto space-y-6 ">
          <div className="grid gap-3">
            {loginSocials.map((item, index) => (
              <a
                key={index}
                href={item.href}
                className="nc-will-change-transform flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
              >
                <img
                  className="flex-shrink-0"
                  src={item.icon}
                  alt={item.name}
                />
                <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                  {item.name}
                </h3>
              </a>
            ))}
          </div>
          {/* OR */}
          <div className="relative text-center">
            <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
              OR
            </span>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
          </div>
          {/* FORM */}
          <form onSubmit={handleSubmit(onSubmit)} className="grid grid-cols-1 gap-6" action="#" method="post">
          <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
              Nom
              </span>
              <Input
                name="name"
                type="text"
                label="Nom"
                required
                register={register}
                error={errors.name}
              />
            </label>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
              Adresse e-mail
              </span>
              <Input
                name="email"
                type="email"
                label="Email Address"
                required
                register={register}
                error={errors.email}
              />
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
              Mot de passe
              </span>
              <Input name="password" 
        label="Password"
        required
        register={register}
        error={errors.password}
              type="password" 
               />
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
              Confirmer le mot de passe
              </span>
              <Input name="passwordConfirmation" 
                      label="Confirm Password"
                      required
                      register={register}
                      validate={value => value === password.current || 'The passwords do not match'}
                      error={errors.passwordConfirm}
              type="password" 
              />
            </label>
            <ButtonPrimary type="submit">Continuer</ButtonPrimary>
          </form>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
          Vous avez déjà un comptet? {` `}
            <Link to="/login">Se connecter</Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageSignUp;
