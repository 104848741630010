// icon:airplane-clock | Material Design Icons https://materialdesignicons.com/ | Austin Andrews
import * as React from "react";

function IconAirplaneTicket(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg fill="#162d66" height="60px" width="60px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" 
	 viewBox="0 0 189.773 189.773" >
<g>
	<g>
		<g>
			<path d="M182.681,50.032l-1.867-10.588c-1.508-8.531-10.638-14.053-20.399-12.333L33.423,49.505h143.851
				C179.13,49.505,180.937,49.692,182.681,50.032z"/>
			<path d="M177.274,62.731H12.501C5.608,62.731,0,67.261,0,72.827v80.052c0,5.566,5.608,10.096,12.501,10.096h164.773
				c6.891,0,12.499-4.53,12.499-10.096V72.827C189.772,67.259,184.165,62.731,177.274,62.731z M58.773,152.694H45.547v-11.383
				h13.226V152.694z M58.773,129.927H45.547v-11.383h13.226V129.927z M58.773,107.161H45.547V95.777h13.226V107.161z M58.773,84.394
				H45.547V73.01h13.226V84.394z M155.951,98.098l-10.178,9.521l9.17,30.63l-5.136,5.136c-0.564,0.562-1.477,0.562-2.041,0
				l-13.669-24.843l-12.572,12.4l2.002,9.772l-3.199,3.201c-0.425,0.425-1.115,0.425-1.536,0.002l-18.109-18.109
				c-0.425-0.425-0.425-1.113,0-1.536l3.199-3.203l9.593,1.957l12.408-12.62l-24.669-13.579c-0.564-0.56-0.564-1.473-0.004-2.037
				l5.141-5.136l30.427,9.108l9.589-10.25c2.802-2.802,7.219-2.925,9.865-0.278C158.874,90.879,158.748,95.297,155.951,98.098z"/>
		</g>
	</g>
</g>    </svg>
  );
}

export default IconAirplaneTicket;
