import { DEMO_POSTS } from "data/posts";
import { PostDataType } from "data/types";
import React from "react";
import { Link } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Comment from "shared/Comment/Comment";
import NcImage from "shared/NcImage/NcImage";
import SocialsList from "shared/SocialsList/SocialsList";
import Textarea from "shared/Textarea/Textarea";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import { Helmet } from "react-helmet";
import imageAero1 from "images/talAirplane1.jpg";
import imageFlotteTal from "images/flotte-tal1.jpg";

const BlogSingle = () => {
  const renderHeader = () => {
    return (
      <header className="container rounded-xl">
        <div className="space-y-5 text-center">
          <h1
            className=" text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl dark:text-neutral-100 max-w-4xlcontent-center relative left-1/2 transform -translate-x-1/2"
            title="Quiet ingenuity: 120,000 lunches and counting" 
          >
            Charte de protection des données personnelles
          </h1>

          <div className="w-full border-b border-neutral-100 dark:border-neutral-800"></div>
        </div>
      </header>
    );
  };

  const renderContent = () => {
    return (
      <div
      id="single-entry-content"
      className="prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark"
    >









<h3>I. PREAMBULE</h3>

<p className="text-justify">Votre vie privée est très importante. Nous attachons une grande importance à la protection de vos données personnelles. Notre principale préoccupation est de protéger votre vie privée lors du traitement des données personnelles et nous surveillons cela de très près dans toutes les opérations commerciales. Nous prenons très au sérieux les responsabilités qui nous incombent quant à la protection des informations personnelles conformément à la loi et à la règlementation en vigueur. En conséquence, Tassili Airlines (TAL) a mis en place la présente charte afin de vous informer des conditions et modalités de collecte et traitement de vos données à caractère personnel en application d’une politique stricte de confidentialité et de sécurité à travers laquelle nous nous engageons à les gérer dans ce cadre légal et à les protéger.</p>

<h3>II. BUT ET CATEGORIES DE DONNEES PERSONNELLES COLLECTEES</h3>
<h4>II.1/ A quel moment recueillez-vous des données personnelles me concernant ?</h4>
<ul>
<li className="text-justify">Lorsque vous achetez un billet d’avion ou que vous réservez une place sur les vols de Tassili Airlines, via une agence TAL ou une agence de voyages accréditée ou Internet, vous êtes appelés à fournir certains renseignements personnels pour conclure la transaction ;</li>
<li className="text-justify">Les informations statistiques recueillies lorsque vous naviguez sur notre site internet www.tassiliairlines.dz ;</li>
<li className="text-justify">Lorsque vous réservez en ligne, Tassili Airlines recueille vos informations à des fins de réservations, d’achat de billets, de services spécifiques, de prospection, de fidélisation, d’informations commerciales, de sondages et études statistiques ; ces informations sont également stockées dans des cookies afin qu’ils puissent être récupérées automatiquement lors de réservations futures ;</li>
<li className="text-justify">Si vous répondez à une enquête client ou si vous nous faites parvenir vos commentaires ;</li>
<li className="text-justify">Si vous demandez un service personnalisé, il se peut que nous devions recueillir des renseignements personnels supplémentaires</li>
<li className="text-justify">Nous pourrions également recueillir d’autres catégories particulières de Données Personnelles vous concernant soit avec votre consentement explicite préalable, ou dans un cadre contractuel ou bien encore si nous y sommes tenus en vertu d’une obligation légale nous incombant.</li>
</ul>
<h4>II.2/ Quelles sont les informations demandées ?</h4>

<p className="text-justify">Votre nom et prénom, date et lieu de naissance, adresse, numéros de téléphone, adresse électronique, réservation de vol, achat, transaction et crédit, mode de paiement préféré, Document de voyage et information contenu (passeport, carte de séjours) ainsi que toute autre information liée aux services et prestations de Tassili Airlines ;</p>

<h4>II.3/ Avec qui partagez-vous mes données personnelles ?</h4>

<p className="text-justify">Conformément à l’article L.232-7 du code de sécurité intérieure, nous vous informons que les transporteurs aériens peuvent être amenés à transmettre les données de réservation, d’enregistrement et d’embarquement de leurs passagers ( API/PNR) à l’administration française, selon les modalités de traitement et pour les finalités fixées dans le décret n° 2014-1095 du 26/09/2014 modifié par le décret 2018/714 du 3/08/18. Les informations concernant vos données personnelles, votre réservation ou votre itinéraire de vol peuvent également être communiquées aux autorités nationales ou internationales, conformément aux lois et règlements en vigueur. Nous serons amenés à communiquer vos données personnelles à des tiers, notamment à d’autres compagnies aériennes, aux exploitants d’aéroports, aux autorités. Nous protégeons vos données personnelles lors de tels transferts en utilisant des clauses et garanties contractuelles adéquates de la part de nos sous-traitants. Nous ne vendons aucune information personnelle à des tiers et nous n’autorisons pas les tiers à vous faire parvenir des informations marketing, à moins que nous ayons obtenu votre accord.</p>

<h4>II. 4/ Comment exercer mes droits ?</h4>

<p className="text-justify">Vous disposez de droits d’accès à l’information sur le traitement de vos données, de rectification, de suppression et d’effacement, de limitation du traitement et d’opposition sur les données vous concernant. Vous pouvez exercer vos droits par e-mail adressé à l’adresse suivante : DPO@tassiliairlines.com</p>

<h4>III. GESTION ET TRAITEMENT DE VOS DONNEES PERSONNELLES COLLECTEES</h4>

<p>Le traitement de vos données personnelles est effectué aux fins de :</p>
<ul>
<li>La gestion de la clientèle ;</li>
<li>Réservation et achat de billet ;</li>
<li>Campagnes d’appels en cas d’irrégularité de vols « Call Center » (TAL ou sous-traitant) ;</li>
<li>Gestion des dossiers passagers ;</li>
<li>Marketing : marketing direct et relations publiques ;</li>
<li>Programmes de fidélisation clientèles, enquêtes de satisfaction client, sondages, études statistiques, étude de marché et l’évaluation des produits et services;</li>
<li>Gestion des litiges et le contrôle des fraudes;</li>
</ul>

<h3>IV. SECURITE DES DONNEES</h3>

<p className="text-justify">Tassili Airlines met en œuvre des mesures techniques et organisationnelles en matière de sécurité, visant à protéger les données vous concernant, contre les manipulations fortuites ou intentionnelles, la perte, la destruction ou contre l’intrusion de personnes non habilitées à cet effet. Nos mesures de sécurité sont perfectionnées en permanence, en fonction de l’évolution des technologies.</p>
<p className="text-justify">Vos données à caractère personnel sont stockées sur nos serveurs et sur ceux de nos partenaires.</p>

<h3>V. CONFIDENTIALITE</h3>

<p className="text-justify">Nous nous engageons à assurer la sécurité et la confidentialité des données personnelles fournies par nos clients. Nous prenons toutes les mesures nécessaires pour protéger les données personnelles recueillies et éviter les risques associés à leur traitement, afin de préserver la confidentialité des données personnelles et surtout d’éviter leur détérioration, leur endommagement ou leur diffusion et/ou utilisation par/pour des tiers non autorisés. La compagnie s’engage à respecter les conditions légales de confidentialité applicables en Algérie et à l’international et à ne pas divulguer ces informations à des tiers.</p>

<h3>VI. DUREE DE CONSERVATION</h3>

<p>Nous conserverons vos Données Personnelles dans nos systèmes pendant :</p>
<ul>
<li>la durée nécessaire au regard des finalités des données collectées et traitées ;</li>
<li>toute période de conservation imposée par la loi ;</li>
</ul>
<h3>VII. RECLAMATIONS</h3>

<p className="text-justify">Pour toutes les questions relatives au traitement de vos données personnelles, nous vous invitons à contacter le Délégué à la Protection des Données (DPO) au sein de notre compagnie à l’adresse suivante : DPO@tassiliairlines.com
Toute l’équipe se tient à votre disposition pour prendre en compte vos demandes de renseignements, vos commentaires ou vos réclamations.</p>

<h3>VIII. AMENDEMENT DE LA PRESENTE CHARTE</h3>

<p className="text-justify">La présente charte peut faire l’objet de mises à jour, notamment en raison de l’évolution des législations et réglementations en vigueur régissant la protection des données.</p>















        
      </div>
    );
  };

  const renderTags = () => {
    return (
      <div className="max-w-screen-md mx-auto flex flex-wrap">
        <a
          className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
          href="##"
        >
          Location
        </a>
        <a
          className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
          href="##"
        >
          Aéronef
        </a>
        <a
          className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
          href="##"
        >
          Contrat
        </a>
      </div>
    );
  };

  const renderAuthor = () => {
    return (
      <div className="max-w-screen-md mx-auto ">
        <div className="nc-SingleAuthor flex">
          <Avatar sizeClass="w-11 h-11 md:w-24 md:h-24" />
          <div className="flex flex-col ml-3 max-w-lg sm:ml-5 space-y-1">
            <span className="text-xs text-neutral-400 uppercase tracking-wider">
              WRITEN BY
            </span>
            <h2 className="text-lg font-semibold text-neutral-900 dark:text-neutral-200">
              <a href="/">Fones Mimi</a>
            </h2>
            <span className="text-sm text-neutral-500 sm:text-base dark:text-neutral-300">
              There’s no stopping the tech giant. Apple now opens its 100th
              store in China.There’s no stopping the tech giant.
              <a className="text-primary-6000 font-medium ml-1" href="/">
                Readmore
              </a>
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderCommentForm = () => {
    return (
      <div className="max-w-screen-md mx-auto pt-5">
        <h3 className="text-xl font-semibold text-neutral-800 dark:text-neutral-200">
          Manifestation d'intéré pour la mise à disposition d’appareils
        </h3>
        <form className="nc-SingleCommentForm mt-5">

                <label className="block">
                  <Label>Votre Nom & Prénom</Label>
                  <Input
                    placeholder="Nom Prénom"
                    type="text"
                    className="mt-1"
                  />
                </label>

                <label className="block">
                  <Label>Société ou organisme</Label>
                  <Input
                    placeholder=""
                    type="text"
                    className="mt-1"
                  />
                </label>

                <label className="block">
                  <Label>Téléphone</Label>
                  <Input
                    placeholder=""
                    type="text"
                    className="mt-1"
                  />
                </label>

                <label className="block">
                  <Label>Votre adresse email</Label>

                  <Input
                    type="email"
                    placeholder="adresse@email.com"
                    className="mt-1"
                  />
                </label>
                <label className="block">
                  <Label>Votre message</Label>
          <Textarea />
          </label>
          <div className="mt-2 space-x-3">
            <ButtonPrimary>Envoyez</ButtonPrimary>
          </div>
        </form>
      </div>
    );
  };

  const renderCommentLists = () => {
    return (
      <div className="max-w-screen-md mx-auto">
        <ul className="nc-SingleCommentLists space-y-5">
          <li>
            <Comment />
            <ul className="pl-4 mt-5 space-y-5 md:pl-11">
              <li>
                <Comment isSmall />
              </li>
            </ul>
          </li>
          <li>
            <Comment />
            <ul className="pl-4 mt-5 space-y-5 md:pl-11">
              <li>
                <Comment isSmall />
              </li>
            </ul>
          </li>
        </ul>
      </div>
    );
  };

  const renderPostRelated = (post: PostDataType) => {
    return (
      <div
        key={post.id}
        className="relative aspect-w-3 aspect-h-4 rounded-3xl overflow-hidden group"
      >
        <Link to={post.href} />
        <NcImage
          className="object-cover w-full h-full transform group-hover:scale-105 transition-transform duration-300"
          src={post.featuredImage}
        />
        <div>
          <div className="absolute bottom-0 inset-x-0 h-1/2 bg-gradient-to-t from-black"></div>
        </div>
        <div className="flex flex-col justify-end items-start text-xs text-neutral-300 space-y-2.5 p-4">
          <Badge name="Categories" />
          <h2 className="block text-lg font-semibold text-white ">
            <span className="line-clamp-2">{post.title}</span>
          </h2>

          <div className="flex">
            <span className="block text-neutral-200 hover:text-white font-medium truncate">
              {post.author.displayName}
            </span>
            <span className="mx-1.5 font-medium">·</span>
            <span className="font-normal truncate">{post.date}</span>
          </div>
        </div>
        <Link to={post.href} />
      </div>
    );
  };

  return (
    <div className="nc-PageSingle pt-8 lg:pt-16 ">
    <Helmet>
      <title>Tassili Airlines || Charte de protection des données personnelles</title>
    </Helmet>
    {renderHeader()}

    <div className="nc-SingleContent container space-y-10">
      {renderContent()}
        <div className="max-w-screen-md mx-auto border-b border-t border-neutral-100 dark:border-neutral-700"></div>
        {/*renderAuthor()*/}
        {/*renderCommentLists()*/}
      </div>

<br/>
{/*
      <div className="relative bg-neutral-100 dark:bg-neutral-800 py-16 lg:py-28 mt-16 lg:mt-24">
        <div className="container ">
          <h2 className="text-3xl font-semibold">Autres services</h2>
          <div className="mt-10 grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 md:gap-8">
            {DEMO_POSTS.filter((_, i) => i < 4).map(renderPostRelated)}
          </div>
        </div>
      </div>

*/}

    </div>
  );
};

export default BlogSingle;
