import React, { FC, useState } from "react";
import miniLogoPng from "images/mini-logo.png";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import bakclassimg from "images/bak-class-1.png";
import bakclassimgecoflex from "images/bak-class-2.png";
import bakclassimgecorelax from "images/bak-class-3.png";
import bakclassimgecopromo from "images/bak-class-4.png";
import bakclassimgbusinessrpromo from "images/bak-class-5.png";
import bakclassimgbusinessrrelax from "images/bak-class-6.png";
import airplaneseparator from "data/iconairplaneseparator";
import Icon from '@mdi/react';
import { mdiAirplaneTakeoff,mdiAirplaneLanding,mdiAirplane,mdiAirplaneCheck } from '@mdi/js';
import Checkbox from "shared/Checkbox/Checkbox";


export interface FlightCardProps {
  className?: string;
  defaultOpen?: number;
  infoVol?: string;
  active?: boolean;
  validee?: boolean;
  onChange?: (newType: string,index:any) => void;
  data: {
    position_date: number,
    departure_date: string,
    fareOfFlights:[{
      departure_date:string,
      departure_date_day_of_week:string,
      departure_date_day_of_month:string,
      flight_id:string,
      flight_number:string,
      airline_rcd:string,
      business_fare:string,
      business_fare_id:string,
      business_booking_class:string,
      business_boarding_class:null,
    economic_fare:string,
    economic_fare_id:string,
    economic_booking_class:string,
    economic_boarding_class:string,
    lowest_fare:string,
    lowest_fare_id:string,
    lowest_booking_class:string,
    lowest_boarding_class:string,
    currency_lowest:string,
    currency_economic:string,
    currency_business:string,
    planned_departure_time:string,
    planned_arrival_time:string,
    aircraft_rcd:string,
    departure_airport_name:string,
    arrival_airport_name:string,
    origin_rcd:string,
    destination_rcd:string,
    duration:string,
    transit_points:string,
    transit_points_name:string,
    timelimit_hours_before_flight_business:string,
    timelimit_hours_before_flight_economic:string,
    timelimit_hours_before_flight_lowest:string,
    timelimit_hours_after_booking_business:string,
    timelimit_hours_after_booking_economic:string,
    timelimit_hours_after_booking_lowest:string,
    Nbusiness_fare:string,
    Nbusiness_fare_id:string,
    Nbusiness_booking_class:string,
    Nbusiness_boarding_class:string,
    Neconomic_fare:string,
    Neconomic_fare_id:string,
    Neconomic_booking_class:string,
    Neconomic_boarding_class:null,
    Nlowest_fare:string,
    Nlowest_fare_id:string,
    Nlowest_booking_class:string,
    Nlowest_boarding_class:string,
    Ncurrency_lowest:string,
    Ncurrency_economic:string,
    Ncurrency_business:string,
    timelimit_hours_before_flight_Nbusiness:string,
    timelimit_hours_before_flight_Neconomic:string,
    timelimit_hours_before_flight_Nlowest:string,
    timelimit_hours_after_booking_Nbusiness:string,
    timelimit_hours_after_booking_Neconomic:string,
    timelimit_hours_after_booking_Nlowest:string,
    business_redemption_points:string,
    business_total_redemption_points:string,
    economic_redemption_points:string,
    economic_total_redemption_points:string,
    lowest_redemption_points:string,
    lowest_total_redemption_points:string,
    Nbusiness_redemption_points:string,
    Nbusiness_total_redemption_points:string,
    Neconomic_redemption_points:string,
    Neconomic_total_redemption_points:string,
    Nlowest_redemption_points:string,
    Nlowest_total_redemption_points:string,
  }],
    FareColumn1:[],
    FareColumn2:[],
    FareColumn3:[]},
  
}


export type TypeDropOffFlightType = "Business Tassili Flex" | "Business Tassili Relax" | "Business Tassili Promo" | 
                                  "Économique Tassili Flex" | "Économique Tassili Relax" | "Économique Tassili Promo" | "";


const FlightCard: FC<FlightCardProps> = ({
  className = "",
  data,
  defaultOpen = -1,
  active=false,
  validee=false,
  infoVol='',
  onChange,
}) => {
  const [isOpen, setIsOpen] = useState(active);

  let [tarif, setTarif] = useState("");




  let [dropOffFlightType, setDropOffFlightType] =
    useState<TypeDropOffFlightType>("");


function OnChange(strInfoVol:string,ndefaultOpen:number,strTarif:string)
{
  if (onChange) onChange(strInfoVol,ndefaultOpen);
  setTarif(strTarif);
  tarif=strTarif;
}

function SetDropOffFlightType(strDropOffFlightType:TypeDropOffFlightType)
{
  setDropOffFlightType(strDropOffFlightType);
  dropOffFlightType=strDropOffFlightType;
  setIsOpen(true);
  console.log("IsOpen:"+isOpen+"-"+active);
}


  const renderDetailTop = () => {
    return (
      <div>
        <div className="flex flex-col md:flex-row ">
          <div className="w-24 md:w-20 lg:w-24 flex-shrink-0 md:pt-7">
            <img src={miniLogoPng} className="w-10" alt="" />
          </div>
          <div className="flex my-5 md:my-0">
            <div className="flex-shrink-0 flex flex-col items-center py-2">
              <span className="block w-6 h-6 rounded-full border border-neutral-400"></span>
              <span className="block flex-grow border-l border-neutral-400 border-dashed my-1"></span>
              <span className="block w-6 h-6 rounded-full border border-neutral-400"></span>
            </div>
            <div className="ml-4 space-y-28 text-sm">
              <div className="flex flex-col space-y-1">
                <span className=" text-neutral-500 dark:text-neutral-400">
                  
                {data.fareOfFlights[0].departure_date+" · "+data.fareOfFlights[0].planned_departure_time}
                </span>
                <span className=" font-semibold flex">
                <Icon path={mdiAirplaneTakeoff} size={1} />{data.fareOfFlights[0].departure_airport_name+" ("+data.fareOfFlights[0].origin_rcd+")"}
                </span>
              </div>
              <div className="flex flex-col space-y-1">
                <span className=" text-neutral-500 dark:text-neutral-400">
                  {data.fareOfFlights[0].departure_date+" · "+data.fareOfFlights[0].planned_arrival_time}
                </span>
                <span className=" font-semibold flex">
                <Icon path={mdiAirplaneLanding} size={1} />{data.fareOfFlights[0].arrival_airport_name+" ("+data.fareOfFlights[0].destination_rcd+")"}
                </span>
              </div>
            </div>
          </div>
          <div className="border-l border-neutral-200 dark:border-neutral-700 md:mx-6 lg:mx-10"></div>
          
          <div className={dropOffFlightType!=="Économique Tassili Flex"?"hidden":"space-y-1"}><h1>Classe {dropOffFlightType}</h1>
          <ul className="text-sm text-neutral-500 dark:text-neutral-400 space-y-1 md:space-y-2">
            <li className="flex"> <span className="w-25 h-25" >
            <svg aria-hidden="true" width="24" height="24"><g fill="#9099a2"><path d="M20.523 2.947h-2.529v-1.5a.5.5 0 1 0-1 0v1.5H7.018v-1.5a.5.5 0 1 0-1 0v1.5H3.477a2.5 2.5 0 0 0-2.5 2.5v15.042a2.5 2.5 0 0 0 2.5 2.5h17.046a2.5 2.5 0 0 0 2.5-2.5V5.447a2.5 2.5 0 0 0-2.5-2.5zm-17.046 1h2.541v1.5a.5.5 0 0 0 1 0v-1.5h9.976v1.5a.5.5 0 0 0 1 0v-1.5h2.529a1.5 1.5 0 0 1 1.5 1.5v1.478H1.977V5.447a1.5 1.5 0 0 1 1.5-1.5zm17.046 18.042H3.477a1.5 1.5 0 0 1-1.5-1.5V7.925h20.046v12.564a1.5 1.5 0 0 1-1.5 1.5z"></path><path d="M14.021 10.856a.5.5 0 0 0-.123.7l1.947 2.781H7.229a.5.5 0 0 0 0 1h8.563l-1.9 2.787a.5.5 0 1 0 .827.563l2.656-3.9-2.66-3.8a.5.5 0 0 0-.694-.131z"></path></g></svg>
              </span><span className="py-1 px-2">Flexibilité de pouvoir faire 1 modification</span></li>
              <li className="flex"> <span className="w-25 h-25" >
              <svg aria-hidden="true" width="24" height="24"><g fill="#9099a2"><path d="M22.862 1.101a.5.5 0 0 0-.707 0L1.137 22.118a.5.5 0 0 0 .354.854.5.5 0 0 0 .353-.147L22.862 1.808a.5.5 0 0 0 0-.707z"></path><path d="M23.023 5.876a.5.5 0 0 0-1 0v4.161a2.042 2.042 0 0 0 0 3.958v4.542a.5.5 0 0 1-.5.5H8.227a.5.5 0 1 0 0 1h13.3a1.5 1.5 0 0 0 1.5-1.5v-5.48h-.5a1.041 1.041 0 0 1 0-2.081h.5V5.888z"></path><path d="M1.506 18.741a.5.5 0 0 0 .5-.5v-4.246a2.041 2.041 0 0 0 0-3.958V5.591a.5.5 0 0 1 .5-.5h3.518V13.681a.5.5 0 0 0 1 0v-8.59h8.541a.5.5 0 1 0 0-1H2.506a1.5 1.5 0 0 0-1.5 1.5v5.385h.5a1.041 1.041 0 1 1 0 2.081h-.5v5.184a.5.5 0 0 0 .5.5z"></path></g></svg>
              </span><span className="py-1 px-2">Annulation sous 24 heures sans frais</span></li>

              <li className="flex"> <span className="w-25 h-25" >
              <svg aria-hidden="true" width="24" height="24" viewBox="0 0 24 24"><g className="nc-icon-wrapper"><defs><clipPath id="b_cbg"><path d="M0 0h24v24H0z"></path></clipPath></defs><g id="a_cbg" clip-path="url(#b_cbg)"><g transform="translate(1.021 .972)"><path d="M20.428 3.906h-4.456V2a.989.989 0 0 0-.989-.987H8.956A.988.988 0 0 0 7.968 2v1.91H3.519A2.5 2.5 0 0 0 1.021 6.4v12.08a2.5 2.5 0 0 0 2.5 2.493H4.1a1.452 1.452 0 0 0-.1.527 1.476 1.476 0 0 0 2.951 0 1.452 1.452 0 0 0-.1-.528h10.304a1.452 1.452 0 0 0-.1.528 1.476 1.476 0 1 0 2.849-.528h.526a2.5 2.5 0 0 0 2.5-2.493V6.4a2.5 2.5 0 0 0-2.502-2.494zM5.473 21.976a.475.475 0 1 1 .475-.475.476.476 0 0 1-.475.475zm13.055 0A.475.475 0 1 1 19 21.5a.475.475 0 0 1-.472.476zM8.956 2.008L14.972 2v1.91H8.965zm12.969 16.471a1.5 1.5 0 0 1-1.5 1.495H3.519a1.5 1.5 0 0 1-1.5-1.495V6.4a1.5 1.5 0 0 1 1.5-1.5h16.909a1.5 1.5 0 0 1 1.5 1.495z" transform="translate(-1.021 -1.009)" fill="#9099a2"></path><path d="M7.971 7.994a.5.5 0 0 0-.5.5v7.957a.5.5 0 1 0 1 0V8.494a.5.5 0 0 0-.5-.5z" transform="translate(-1.021 -1.026)" fill="#9099a2"></path><path d="M11.953 7.994a.5.5 0 0 0-.5.5v7.957a.5.5 0 0 0 1 0V8.494a.5.5 0 0 0-.5-.5z" transform="translate(-1.021 -1.026)" fill="#9099a2"></path><path d="M16.029 7.994a.5.5 0 0 0-.5.5v7.957a.5.5 0 1 0 1 0V8.494a.5.5 0 0 0-.5-.5z" transform="translate(-1.021 -1.026)" fill="#9099a2"></path></g></g></g></svg>
              </span><span className="py-1 px-2">Bagages enregistrés : 1 pièces, 23 kg chacun</span></li>

              <li className="flex"> <span className="w-25 h-25" >
              <svg aria-hidden="true" width="24" height="24" viewBox="0 0 24 24"><g className="nc-icon-wrapper"><defs><clipPath id="b_hb"><path d="M0 0h24v24H0z"></path></clipPath></defs><g id="a_hb" clip-path="url(#b_hb)"><path d="M21.509 4.683h-5.341V3.2a1.488 1.488 0 0 0-1.487-1.49H9.273A1.487 1.487 0 0 0 7.788 3.2v1.483H2.49a1.475 1.475 0 0 0-1.474 1.474v12.376a1.506 1.506 0 0 0 1.523 1.486h18.933A1.507 1.507 0 0 0 23 18.533V6.169a1.488 1.488 0 0 0-1.491-1.486zM8.788 3.2a.486.486 0 0 1 .485-.486h5.408a.487.487 0 0 1 .487.486v1.483h-6.38zm12.684 15.819H2.539a.506.506 0 0 1-.523-.486v-6.647a1.476 1.476 0 0 0 .5.092h7.5v1.04a1.974 1.974 0 0 0 3.948 0v-1.04H21.5a1.483 1.483 0 0 0 .5-.092v6.647a.507.507 0 0 1-.528.486zm-10.46-6v-2.53h1.948v2.529a.974.974 0 0 1-1.948 0zM22 10.478a.5.5 0 0 1-.5.5h-7.54V9.489h-3.948v1.489h-7.5a.5.5 0 0 1-.5-.5V6.157a.475.475 0 0 1 .474-.474h19.023a.493.493 0 0 1 .491.486v4.309z" transform="translate(0 .016)" fill="#9099a2"></path></g></g></svg>
              </span><span className="py-1 px-2">Bagages à main : 1 pièces, 10 kg au total</span></li>

              <li className="flex"> <span className="w-25 h-25" >
              <svg aria-hidden="true" width="24" height="24" viewBox="0 0 24 24"><g className="nc-icon-wrapper"><defs><clipPath id="b_lo"><path d="M0 0h24v24H0z"></path></clipPath></defs><g id="a_lo" clip-path="url(#b_lo)"><path d="M19.777 9.971v-7.71A1.446 1.446 0 0 0 18.2.955H5.784a1.445 1.445 0 0 0-1.578 1.306v7.69a3.01 3.01 0 0 0-.977 5.849v5.686a1.5 1.5 0 0 0 1.5 1.5h14.558a1.494 1.494 0 0 0 1.484-1.5v-5.664a3.011 3.011 0 0 0-.994-5.851zM4.731 21.987a.5.5 0 0 1-.5-.5v-6.475l-.4-.081a2.013 2.013 0 1 1 2.418-1.986v9.042zm2.518 0v-5.948h9.509l.011 5.948zm9.5-9.016v2.069h-9.5v-2.1a3 3 0 0 0-2.041-2.816V2.261c0-.122.23-.308.578-.308H18.2c.348 0 .579.186.579.308v7.89a3.012 3.012 0 0 0-2.026 2.82zm3.419 1.983l-.4.082v6.454a.488.488 0 0 1-.484.5h-1.516l-.015-9.019a2.016 2.016 0 1 1 2.419 1.981z" transform="translate(0 .016)" fill="#9099a2"></path></g></g></svg>
              </span><span className="py-1 px-2">Sélection de siège incluse</span></li>


          </ul>
          </div>

          <div className={dropOffFlightType!=="Business Tassili Flex"?"hidden":"space-y-1"}><h1>Classe {dropOffFlightType}</h1>
          <ul className="text-sm text-neutral-500 dark:text-neutral-400 space-y-1 md:space-y-2">
            <li className="flex"> <span className="w-25 h-25" >
            <svg aria-hidden="true" width="24" height="24"><g fill="#9099a2"><path d="M20.523 2.947h-2.529v-1.5a.5.5 0 1 0-1 0v1.5H7.018v-1.5a.5.5 0 1 0-1 0v1.5H3.477a2.5 2.5 0 0 0-2.5 2.5v15.042a2.5 2.5 0 0 0 2.5 2.5h17.046a2.5 2.5 0 0 0 2.5-2.5V5.447a2.5 2.5 0 0 0-2.5-2.5zm-17.046 1h2.541v1.5a.5.5 0 0 0 1 0v-1.5h9.976v1.5a.5.5 0 0 0 1 0v-1.5h2.529a1.5 1.5 0 0 1 1.5 1.5v1.478H1.977V5.447a1.5 1.5 0 0 1 1.5-1.5zm17.046 18.042H3.477a1.5 1.5 0 0 1-1.5-1.5V7.925h20.046v12.564a1.5 1.5 0 0 1-1.5 1.5z"></path><path d="M14.021 10.856a.5.5 0 0 0-.123.7l1.947 2.781H7.229a.5.5 0 0 0 0 1h8.563l-1.9 2.787a.5.5 0 1 0 .827.563l2.656-3.9-2.66-3.8a.5.5 0 0 0-.694-.131z"></path></g></svg>
              </span><span className="py-1 px-2">Flexibilité de pouvoir faire des modifications</span></li>
              <li className="flex"> <span className="w-25 h-25" >
              <svg aria-hidden="true" width="24" height="24"><g fill="#9099a2"><path d="M22.862 1.101a.5.5 0 0 0-.707 0L1.137 22.118a.5.5 0 0 0 .354.854.5.5 0 0 0 .353-.147L22.862 1.808a.5.5 0 0 0 0-.707z"></path><path d="M23.023 5.876a.5.5 0 0 0-1 0v4.161a2.042 2.042 0 0 0 0 3.958v4.542a.5.5 0 0 1-.5.5H8.227a.5.5 0 1 0 0 1h13.3a1.5 1.5 0 0 0 1.5-1.5v-5.48h-.5a1.041 1.041 0 0 1 0-2.081h.5V5.888z"></path><path d="M1.506 18.741a.5.5 0 0 0 .5-.5v-4.246a2.041 2.041 0 0 0 0-3.958V5.591a.5.5 0 0 1 .5-.5h3.518V13.681a.5.5 0 0 0 1 0v-8.59h8.541a.5.5 0 1 0 0-1H2.506a1.5 1.5 0 0 0-1.5 1.5v5.385h.5a1.041 1.041 0 1 1 0 2.081h-.5v5.184a.5.5 0 0 0 .5.5z"></path></g></svg>
              </span><span className="py-1 px-2">Annulation sans frais</span></li>

              <li className="flex"> <span className="w-25 h-25" >
              <svg aria-hidden="true" width="24" height="24" viewBox="0 0 24 24"><g className="nc-icon-wrapper"><defs><clipPath id="b_cbg"><path d="M0 0h24v24H0z"></path></clipPath></defs><g id="a_cbg" clip-path="url(#b_cbg)"><g transform="translate(1.021 .972)"><path d="M20.428 3.906h-4.456V2a.989.989 0 0 0-.989-.987H8.956A.988.988 0 0 0 7.968 2v1.91H3.519A2.5 2.5 0 0 0 1.021 6.4v12.08a2.5 2.5 0 0 0 2.5 2.493H4.1a1.452 1.452 0 0 0-.1.527 1.476 1.476 0 0 0 2.951 0 1.452 1.452 0 0 0-.1-.528h10.304a1.452 1.452 0 0 0-.1.528 1.476 1.476 0 1 0 2.849-.528h.526a2.5 2.5 0 0 0 2.5-2.493V6.4a2.5 2.5 0 0 0-2.502-2.494zM5.473 21.976a.475.475 0 1 1 .475-.475.476.476 0 0 1-.475.475zm13.055 0A.475.475 0 1 1 19 21.5a.475.475 0 0 1-.472.476zM8.956 2.008L14.972 2v1.91H8.965zm12.969 16.471a1.5 1.5 0 0 1-1.5 1.495H3.519a1.5 1.5 0 0 1-1.5-1.495V6.4a1.5 1.5 0 0 1 1.5-1.5h16.909a1.5 1.5 0 0 1 1.5 1.495z" transform="translate(-1.021 -1.009)" fill="#9099a2"></path><path d="M7.971 7.994a.5.5 0 0 0-.5.5v7.957a.5.5 0 1 0 1 0V8.494a.5.5 0 0 0-.5-.5z" transform="translate(-1.021 -1.026)" fill="#9099a2"></path><path d="M11.953 7.994a.5.5 0 0 0-.5.5v7.957a.5.5 0 0 0 1 0V8.494a.5.5 0 0 0-.5-.5z" transform="translate(-1.021 -1.026)" fill="#9099a2"></path><path d="M16.029 7.994a.5.5 0 0 0-.5.5v7.957a.5.5 0 1 0 1 0V8.494a.5.5 0 0 0-.5-.5z" transform="translate(-1.021 -1.026)" fill="#9099a2"></path></g></g></g></svg>
              </span><span className="py-1 px-2">Bagages enregistrés : 1 pièces, 30 kg chacun</span></li>

              <li className="flex"> <span className="w-25 h-25" >
              <svg aria-hidden="true" width="24" height="24" viewBox="0 0 24 24"><g className="nc-icon-wrapper"><defs><clipPath id="b_hb"><path d="M0 0h24v24H0z"></path></clipPath></defs><g id="a_hb" clip-path="url(#b_hb)"><path d="M21.509 4.683h-5.341V3.2a1.488 1.488 0 0 0-1.487-1.49H9.273A1.487 1.487 0 0 0 7.788 3.2v1.483H2.49a1.475 1.475 0 0 0-1.474 1.474v12.376a1.506 1.506 0 0 0 1.523 1.486h18.933A1.507 1.507 0 0 0 23 18.533V6.169a1.488 1.488 0 0 0-1.491-1.486zM8.788 3.2a.486.486 0 0 1 .485-.486h5.408a.487.487 0 0 1 .487.486v1.483h-6.38zm12.684 15.819H2.539a.506.506 0 0 1-.523-.486v-6.647a1.476 1.476 0 0 0 .5.092h7.5v1.04a1.974 1.974 0 0 0 3.948 0v-1.04H21.5a1.483 1.483 0 0 0 .5-.092v6.647a.507.507 0 0 1-.528.486zm-10.46-6v-2.53h1.948v2.529a.974.974 0 0 1-1.948 0zM22 10.478a.5.5 0 0 1-.5.5h-7.54V9.489h-3.948v1.489h-7.5a.5.5 0 0 1-.5-.5V6.157a.475.475 0 0 1 .474-.474h19.023a.493.493 0 0 1 .491.486v4.309z" transform="translate(0 .016)" fill="#9099a2"></path></g></g></svg>
              </span><span className="py-1 px-2">Bagages à main : 1 pièces, 10 kg au total</span></li>

              <li className="flex"> <span className="w-25 h-25" >
              <svg aria-hidden="true" width="24" height="24" viewBox="0 0 24 24"><g className="nc-icon-wrapper"><defs><clipPath id="b_lo"><path d="M0 0h24v24H0z"></path></clipPath></defs><g id="a_lo" clip-path="url(#b_lo)"><path d="M19.777 9.971v-7.71A1.446 1.446 0 0 0 18.2.955H5.784a1.445 1.445 0 0 0-1.578 1.306v7.69a3.01 3.01 0 0 0-.977 5.849v5.686a1.5 1.5 0 0 0 1.5 1.5h14.558a1.494 1.494 0 0 0 1.484-1.5v-5.664a3.011 3.011 0 0 0-.994-5.851zM4.731 21.987a.5.5 0 0 1-.5-.5v-6.475l-.4-.081a2.013 2.013 0 1 1 2.418-1.986v9.042zm2.518 0v-5.948h9.509l.011 5.948zm9.5-9.016v2.069h-9.5v-2.1a3 3 0 0 0-2.041-2.816V2.261c0-.122.23-.308.578-.308H18.2c.348 0 .579.186.579.308v7.89a3.012 3.012 0 0 0-2.026 2.82zm3.419 1.983l-.4.082v6.454a.488.488 0 0 1-.484.5h-1.516l-.015-9.019a2.016 2.016 0 1 1 2.419 1.981z" transform="translate(0 .016)" fill="#9099a2"></path></g></g></svg>
              </span><span className="py-1 px-2">Sélection de siège incluse</span></li>


          </ul>
          </div>



        </div>



  



      </div>
    );
  };

function handlevalidertarif()
{
if (onChange) onChange("commande",6) ;
setIsOpen(false);
}


  const renderDetail = () => {
    if ((!active)||(!isOpen)) return null;
    return (
      <div className="p-4 md:p-8 border border-neutral-200 dark:border-neutral-700 rounded-2xl ">
        {renderDetailTop()}

        <div className="my-7 md:my-10 space-y-5 md:pl-24">
          <div className="border-t border-neutral-200 dark:border-neutral-700" />
          <div className="text-neutral-700 dark:text-neutral-300 text-sm md:text-base">

          <div className="w-full border-neutral-200 mt-4 flex">
                      <span className="w-[30%]"><strong>{localStorage.nbrPassengers} Voyageur{localStorage.nbrPassengers>1?"s":""} : </strong>
                      {localStorage.adultInputValue==="0"?"":(localStorage.adultInputValue+" Adult"+(localStorage.adultInputValue>1?"s":""))}
                      {localStorage.childrenInputValue==="0"?"":", "+(localStorage.childrenInputValue+" Enfant"+(localStorage.childrenInputValue>1?"s":""))}
                      {localStorage.infantsInputValue==="0"?"":" et "+(localStorage.infantsInputValue+" Bébé"+(localStorage.infantsInputValue>1?"s":""))}</span>
                      <span className="items-center w-[30%]"><strong><u>Tarif :</u></strong> <b>{tarif}</b></span>
                      <span className="items-right w-[30%]">
                      
                      <ButtonPrimary className="right-[0px] y-[10px] h-30 top-0" onClick={handlevalidertarif}>{validee?"Modifier ce choix":"Choisir ce tarif"}</ButtonPrimary>
                      
                      </span>
                      </div>
          </div>
        </div>



      </div>




    );
  };

const strCondition1="";
/*"<div className='text-sm text-neutral-500 font-normal mt-0.5'>"+
"BAGAGE : 10 KG CABINE ET 30 KG SOUTE</br>"+
"MODIF AUTORISÉE / "+
"REMB AUTORISÉE</br>"+
"VALABLE : 365 J<br/></div>";
*/
const strCondition2="";
/*"<div className='text-sm text-neutral-500 font-normal mt-0.5'>"+
"BAGAGE : 10 KG CABINE ET 20 KG SOUTE</br>"+
"MODIF NON AUTORISÉE / "+
"REMB NON AUTORISÉE</br>"+
"VALABLE : 365 J<br/></div>";
*/

function strInfoVol() {
return "{\"departure_date\":\""+data.fareOfFlights[0].departure_date+"\",\"airline_rcd\":\""+
  data.fareOfFlights[0].airline_rcd+"\",\"flight_number\":\""+data.fareOfFlights[0].flight_number+"\",\"planned_departure_time\":\""+
  data.fareOfFlights[0].planned_departure_time+"\",\"origin_rcd\":\""+data.fareOfFlights[0].origin_rcd+"\",\"arrival_airport_name\":\""+

  data.fareOfFlights[0].arrival_airport_name+"\",\"departure_airport_name\":\""+data.fareOfFlights[0].departure_airport_name+"\",\"duration\":\""+
  data.fareOfFlights[0].duration+"\",\"flight_number\":\""+data.fareOfFlights[0].flight_number+"\",\"aircraft_rcd\":\""+
  data.fareOfFlights[0].aircraft_rcd+"\",\"airline_rcd\":\""+data.fareOfFlights[0].airline_rcd+"\",\"planned_arrival_time\":\""+




  data.fareOfFlights[0].planned_arrival_time+"\",\"destination_rcd\":\""+data.fareOfFlights[0].destination_rcd+"\",\"dropOffFlightType\":\""+
  dropOffFlightType+"\"";
}

  return (
    <div
      className={`nc-FlightCardgroup p-4 sm:p-6 relative bg-white dark:bg-neutral-900 border border-neutral-100
     dark:border-neutral-800 rounded-2xl overflow-hidden hover:shadow-lg transition-shadow space-y-6 ${className}`}
      data-nc-id="FlightCard"
    >
      <div
        className={` sm:pr-20 relative  ${className}`}
        data-nc-id="FlightCard"
      >
        {/*  eslint-disable-next-line jsx-a11y/anchor-has-content */}
        {/*<a href="##" className="absolute inset-0" />*/}


        <span
          className={`absolute right-[45px] bottom-0 sm:bottom-auto sm:top-1/2 sm:-translate-y-1/2 w-10 h-10 bg-neutral-50 dark:bg-neutral-800 rounded-full flex items-center justify-center cursor-pointer ${
            validee ? "" : "hidden"
          }`} 
          onClick={() => setIsOpen(!isOpen)}
          >
        <Icon path={mdiAirplaneCheck} color="green" size={2} />
        </span>

        <span
          className={`absolute right-0 bottom-0 sm:bottom-auto sm:top-1/2 sm:-translate-y-1/2 w-10 h-10 bg-neutral-50 dark:bg-neutral-800 rounded-full flex items-center justify-center cursor-pointer ${
            isOpen ? "transform -rotate-180" : ""
          }`}
          onClick={() => setIsOpen(!isOpen)}
        >
          <i className="text-xl las la-angle-down"></i>
        </span>

        <div className="flex  flex-col sm:flex-row sm:items-center space-y-6 sm:space-y-0">
          {/* LOGO IMG */}
          <div className="w-16 lg:w-16 flex-shrink-0">
            <img src={miniLogoPng} className="w-14" alt="" />
          </div>

          {/* FOR MOBILE RESPONSIVE */}
          <div className="block lg:hidden space-y-1">
            <div className="flex font-semibold">
              <div>
                <span>{data.fareOfFlights[0].planned_departure_time}</span>
                <span className="flex items-center text-sm text-neutral-500 font-normal mt-0.5">
                {data.fareOfFlights[0].origin_rcd}
                </span>
              </div>
              <span className="w-12 flex justify-center">
                <i className=" text-2xl las la-long-arrow-alt-right"></i>
              </span>
              <div>
                <span>{data.fareOfFlights[0].planned_arrival_time}</span>
                <span className="flex items-center text-sm text-neutral-500 font-normal mt-0.5">
                {data.fareOfFlights[0].destination_rcd}
                </span>
              </div>
            </div>

            <div className="text-sm text-neutral-500 font-normal mt-0.5">
              <span className="VG3hNb">Nonstop</span>
              <span className="mx-2">·</span>
              <span>{data.fareOfFlights[0].duration}</span>
              <span className="mx-2">·</span>
              <span>{data.fareOfFlights[0].destination_rcd}</span>
            </div>
          </div>

          {/* TIME - NAME */}
          <div className="hidden lg:block  min-w-[150px] flex-[4] ">
            <div className="font-medium text-lg flex">{data.fareOfFlights[0].planned_departure_time} - {data.fareOfFlights[0].planned_arrival_time}</div>
            <div className="text-sm text-neutral-500 font-normal mt-0.5">
            {data.fareOfFlights[0].departure_date}
            </div>
          </div>

          {/* TIMME */}
          <div className="hidden lg:block flex-[4] whitespace-nowrap">
            <div className="font-medium text-lg flex"> {data.fareOfFlights[0].origin_rcd}<span className="mx-1"> </span><Icon path={mdiAirplane} rotate={45} size={0.8}/><span className="mx-1"> </span>{data.fareOfFlights[0].destination_rcd}</div>
            <div className="text-sm text-neutral-500 font-normal mt-0.5">
            Durée du vol : {data.fareOfFlights[0].duration}
            </div>
          </div>

          {/* TYPE */}
          <div className="hidden lg:block flex-[4] whitespace-nowrap">
            <div className="font-medium text-lg">
{/*}              
"Nbusiness_fare":"8620",
"Nbusiness_fare_id":"{4F2E6820-F8D4-4B00-951F-4EE32179A356}",
"Nbusiness_booking_class":"Y",
"Nbusiness_boarding_class":"Y",
"Neconomic_fare":"N/A",
"Neconomic_fare_id":null,
"Neconomic_booking_class":null,
"Neconomic_boarding_class":null,
"Nlowest_fare":"N/A",
"Nlowest_fare_id":null,
"Nlowest_booking_class":null,
"Nlowest_boarding_class":null,
"Ncurrency_lowest":null,
"Ncurrency_economic":null,
"Ncurrency_business":"DZD",


"business_fare":"N/A",
"business_fare_id":null,
"business_booking_class":null,
"business_boarding_class":null,
"economic_fare":"N/A",
"economic_fare_id":null,
"economic_booking_class":null,
"economic_boarding_class":null,
"lowest_fare":"N/A",
"lowest_fare_id":null,
"lowest_booking_class":null,
"lowest_boarding_class":null,
"currency_lowest":null,
"currency_economic":null,
"currency_business":null,



*/}
              
              
              
              
              {data.fareOfFlights[0].aircraft_rcd} </div>
            <div className="text-sm text-neutral-500 font-normal mt-0.5">
            Numéro de vol : {data.fareOfFlights[0].airline_rcd} {data.fareOfFlights[0].flight_number}
            </div>
          </div>

          {/* PRICE */}
          <div className="flex-[4] whitespace-nowrap sm:text-left">
            <div style={{backgroundImage: `url(${bakclassimg})`,backgroundRepeat  : 'no-repeat', backgroundPosition: 'right'}}
            className={((data.fareOfFlights[0].business_fare==="N/A") || (validee && dropOffFlightType !== "Business Tassili Flex"))?"":`py-1.5 px-4 flex items-center rounded-full font-medium text-xs cursor-pointer mr-2 my-1 sm:mr-3 sm:text-right ${
              dropOffFlightType === "Business Tassili Flex" && active
                ? "bg-[#162d66] shadow-black/10 shadow-lg text-white color-white"
                : "border border-neutral-300 dark:border-neutral-700"
            }`}
           onClick ={(e) => { SetDropOffFlightType("Business Tassili Flex"); 
            OnChange(strInfoVol()+
            ",\"condition\":\""+strCondition1+"\","+
            "\"fare\":\""+data.fareOfFlights[0].business_fare+ "\",\"currency\":\""+data.fareOfFlights[0].currency_business+"\"}"              
              ,defaultOpen,data.fareOfFlights[0].business_fare+" "+data.fareOfFlights[0].currency_business); } }
            >
              <span className={dropOffFlightType === "Business Tassili Flex"?"text-xl font-semibold text-white-alpha-90":"text-xl font-semibold text-secondary-6000"}>
              { data.fareOfFlights[0].business_fare==="N/A" || (validee && dropOffFlightType !== "Business Tassili Flex")?"":data.fareOfFlights[0].business_fare+" "+data.fareOfFlights[0].currency_business} 
              </span>
            </div>

            <div style={{backgroundImage: `url(${bakclassimgbusinessrrelax})`,backgroundRepeat  : 'no-repeat', backgroundPosition: 'right'}}
            className={data.fareOfFlights[0].economic_fare==="N/A" || (validee && dropOffFlightType !== "Business Tassili Relax")?"":`py-1.5 px-4 flex items-center rounded-full font-medium text-xs cursor-pointer mr-2 my-1 sm:mr-3 sm:text-right ${
              dropOffFlightType === "Business Tassili Relax" && active
                ? "bg-green-600 shadow-black/10 shadow-lg text-white color-white"
                : "border border-neutral-300 dark:border-neutral-700"
            }`}
            onClick={(e) => { SetDropOffFlightType("Business Tassili Relax"); 
            OnChange(strInfoVol()+
            ",\"condition\":\""+strCondition1+"\","+
           "\"fare\":\""+data.fareOfFlights[0].economic_fare+ "\",\"currency\":\""+data.fareOfFlights[0].currency_economic+"\"}"              
            ,defaultOpen,data.fareOfFlights[0].economic_fare+" "+data.fareOfFlights[0].currency_economic); } }
            >
              <span className={dropOffFlightType === "Business Tassili Relax"?"text-xl font-semibold text-white-alpha-90":"text-xl font-semibold text-secondary-6000"}>
              { data.fareOfFlights[0].economic_fare==="N/A" || (validee && dropOffFlightType !== "Business Tassili Relax")?"":data.fareOfFlights[0].economic_fare+" "+data.fareOfFlights[0].currency_economic} 
              </span>
            </div>

            <div style={{backgroundImage: `url(${bakclassimgbusinessrpromo})`,backgroundRepeat  : 'no-repeat', backgroundPosition: 'right'}}
            className={data.fareOfFlights[0].lowest_fare==="N/A" || (validee && dropOffFlightType !== "Business Tassili Promo")?"":`py-1.5 px-4 flex items-center rounded-full font-medium text-xs cursor-pointer mr-2 my-1 sm:mr-3 sm:text-right ${
              dropOffFlightType === "Business Tassili Promo" && active
                ? "bg-green-600 shadow-black/10 shadow-lg text-white color-white"
                : "border border-neutral-300 dark:border-neutral-700"
            }`}

            onClick={(e) => { SetDropOffFlightType("Business Tassili Promo"); 
            OnChange(strInfoVol()+
            ",\"condition\":\""+strCondition1+"\","+
           "\"fare\":\""+data.fareOfFlights[0].lowest_fare+ "\",\"currency\":\""+data.fareOfFlights[0].currency_lowest+"\"}"              
            ,defaultOpen,data.fareOfFlights[0].lowest_fare+" "+data.fareOfFlights[0].currency_lowest); } }

              >
              <span className={dropOffFlightType === "Business Tassili Promo"?"text-xl font-semibold text-white-alpha-90":"text-xl font-semibold text-secondary-6000"}>
              { data.fareOfFlights[0].lowest_fare==="N/A" || (validee && dropOffFlightType !== "Économique Tassili Promo")?"":data.fareOfFlights[0].lowest_fare+" "+data.fareOfFlights[0].currency_lowest} 
              </span>
            </div>

            <div   style={{backgroundImage: `url(${bakclassimgecoflex})`,backgroundRepeat  : 'no-repeat', backgroundPosition: 'right'}}
            className={data.fareOfFlights[0].Nbusiness_fare==="N/A" || (validee && dropOffFlightType !== "Économique Tassili Flex")?"":`py-1.5 px-4 flex items-center rounded-full font-medium text-xs cursor-pointer mr-2 my-1 sm:mr-3 sm:text-right ${
              dropOffFlightType === "Économique Tassili Flex" && active
                ? "bg-green-600 shadow-black/10 shadow-lg text-white color-white color-white"
                : "border border-neutral-300 dark:border-neutral-700"
            }`}

            onClick={(e) => { SetDropOffFlightType("Économique Tassili Flex"); 
            OnChange(strInfoVol()+
            ",\"condition\":\""+strCondition2+"\","+
           "\"fare\":\""+data.fareOfFlights[0].Nbusiness_fare+ "\",\"currency\":\""+data.fareOfFlights[0].Ncurrency_business+"\"}"              
            ,defaultOpen,data.fareOfFlights[0].Nbusiness_fare+" "+data.fareOfFlights[0].Ncurrency_business); } }


            >
              <span className={dropOffFlightType === "Économique Tassili Flex"?"text-xl font-semibold text-white-alpha-90":"text-xl font-semibold text-secondary-6000"}>
              { data.fareOfFlights[0].Nbusiness_fare==="N/A" || (validee && dropOffFlightType !== "Économique Tassili Flex")?"":data.fareOfFlights[0].Nbusiness_fare+" "+data.fareOfFlights[0].Ncurrency_business} 
              </span>
            </div>

            <div style={{backgroundImage: `url(${bakclassimgecorelax})`,backgroundRepeat  : 'no-repeat', backgroundPosition: 'right'}}
            className={data.fareOfFlights[0].Neconomic_fare==="N/A" || (validee && dropOffFlightType !== "Économique Tassili Relax")?"":`py-1.5 px-4 flex items-center rounded-full font-medium text-xs cursor-pointer mr-2 my-1 sm:mr-3 sm:text-right ${
              dropOffFlightType === "Économique Tassili Relax" && active
                ? "bg-green-600 shadow-black/10 shadow-lg text-white color-white"
                : "border border-neutral-300 dark:border-neutral-700"
            }`}

            onClick={(e) => { SetDropOffFlightType("Économique Tassili Relax"); 
            OnChange(strInfoVol()+
            ",\"condition\":\""+strCondition2+"\","+
           "\"fare\":\""+data.fareOfFlights[0].Neconomic_fare+ "\",\"currency\":\""+data.fareOfFlights[0].Ncurrency_economic+"\"}"              
            ,defaultOpen,data.fareOfFlights[0].Neconomic_fare+" "+data.fareOfFlights[0].Ncurrency_economic); } }


            >
              <span className={dropOffFlightType === "Économique Tassili Relax"?"text-xl font-semibold text-white-alpha-90":"text-xl font-semibold text-secondary-6000"}>
              { data.fareOfFlights[0].Neconomic_fare==="N/A" || (validee && dropOffFlightType !== "Économique Tassili Relax")?"":data.fareOfFlights[0].Neconomic_fare+" "+data.fareOfFlights[0].Ncurrency_economic} 
              </span>
            </div>

            <div style={{backgroundImage: `url(${bakclassimgecopromo})`,backgroundRepeat  : 'no-repeat', backgroundPosition: 'right'}}
            className={data.fareOfFlights[0].Nlowest_fare==="N/A" || (validee && dropOffFlightType !== "Économique Tassili Promo")?"":`py-1.5 px-4 flex items-center rounded-full font-medium text-xs cursor-pointer mr-2 my-1 sm:mr-3 sm:text-right ${
              dropOffFlightType === "Économique Tassili Promo" && active
                ? "bg-green-600 shadow-black/10 shadow-lg text-white color-white"
                : "border border-neutral-300 dark:border-neutral-700"
            }`}

            onClick={(e) => { SetDropOffFlightType("Économique Tassili Promo"); 
            OnChange(strInfoVol()+
            ",\"condition\":\""+strCondition2+"\","+
           "\"fare\":\""+data.fareOfFlights[0].Nlowest_fare+ "\",\"currency\":\""+data.fareOfFlights[0].Ncurrency_lowest+"\"}"              
            ,defaultOpen,data.fareOfFlights[0].Nlowest_fare+" "+data.fareOfFlights[0].Ncurrency_lowest ); } }


            >
              <span className={dropOffFlightType === "Économique Tassili Promo"?"text-xl font-semibold text-white-alpha-90":"text-xl font-semibold text-secondary-6000"}>
              { data.fareOfFlights[0].Nlowest_fare==="N/A" || (validee && dropOffFlightType !== "Économique Tassili Promo")?"":data.fareOfFlights[0].Nlowest_fare+" "+data.fareOfFlights[0].Ncurrency_lowest} 
              </span>
            </div>

            

          </div>



        </div>
      </div>

      {/* DETAIL */}
      {renderDetail()}
    </div>
  );
};

export default FlightCard;
