"use client";

import React, { Fragment, useState } from "react";
import { FC } from "react";
import DatePicker from "react-datepicker";
import { Popover, Transition } from "@headlessui/react";
import { CalendarIcon } from "@heroicons/react/24/outline";
import ClearDataButton from "../ClearDataButton";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import DatePickerCustomHeaderTwoMonth from "components/DatePickerCustomHeaderTwoMonth";
import DatePickerCustomDay from "components/DatePickerCustomDay";
import fr  from "date-fns/locale/fr";
import { srchResultContext } from '../../SectionHero/SectionHero'; 
import { Console } from "console";
import dayjs from "dayjs";

export interface FlightDateRangeInputProps {
  className?: string;
  fieldClassName?: string;
  hasButtonSubmit?: boolean;
  selectsRange?: boolean;
  startLoc?: string;
  endLoc?: string;
  flyType?: string;
  flyClass?:string;
  adultInputValue?: number;
  childrenInputValue?: number;
  infantsInputValue?: number;

}

const FlightDateRangeInput: FC<FlightDateRangeInputProps> = ({
  className = "",
  fieldClassName = "[ nc-hero-field-padding ]",
  hasButtonSubmit = true,
  selectsRange = true,
  startLoc="",
  endLoc="",
  flyType="Aller simple",
  flyClass="Économique",
  adultInputValue=1,
  childrenInputValue=0,
  infantsInputValue=0,

}) => {
  
  const [startDate, setStartDate] = useState<Date | null>(
    null //new Date()
  );
  const [endDate, setEndDate] = useState<Date | null>(null);

  const onChangeRangeDate = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const renderInput = () => {
    return (
      <>
        <div className="text-neutral-300 dark:text-neutral-400">
          <CalendarIcon className="w-5 h-5 lg:w-7 lg:h-7" />
        </div>
        <div className="flex-grow text-left">
          <span className="block xl:text-lg font-semibold">
            {startDate?.toLocaleDateString("fr-FR", {
              month: "short",
              day: "2-digit",
            }) || "Choisir les dates"}
            {selectsRange && endDate
              ? " - " +
                endDate?.toLocaleDateString("fr-FR", {
                  month: "short",
                  day: "2-digit",
                })
              : ""}
          </span>
          <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
            {selectsRange ? "Départ - Arrivée" : "Date de départ"}
          </span>
        </div>
      </>
    );
  };

  let { srchResult, updateResult } = React.useContext(srchResultContext);

  function handleClick() {

    console.log('Handle click start');

    const xhr = new XMLHttpRequest();

    
    const strStartDate=dayjs(startDate).format("YYYYMMDD");
    let strEndDate="NR";
    if (endDate!==null) strEndDate=dayjs(endDate).format("YYYYMMDD");
    
    localStorage.flightType=flyType;
    localStorage.nbrPassengers=adultInputValue+childrenInputValue+infantsInputValue;
    localStorage.adultInputValue=adultInputValue;
    localStorage.childrenInputValue=childrenInputValue;
    localStorage.infantsInputValue=infantsInputValue;

    console.log("Passager:"+localStorage.nbrPassengers);

if (localStorage.devise ==="") localStorage.devise ="DZD";
let strFlyType="True";
if (flyType==="Aller simple") strFlyType="False"; 
    //(startDate?(startDate.getFullYear()+''+(startDate.getMonth()+1).toString().padStart(2, "0")+''+(startDate.getD).toString().padStart(2, "0")+''):'');
//    flyType="",
//    adultInputValue="",
//    childrenInputValue="",
//    infantsInputValue="",
if (!localStorage.devise) localStorage.devise="DZD";
//endLoc="CDG";

    const url = "https://api.tassiliairlines.dz.tl/tassapi/?from="+startLoc+"&to="+endLoc+"&dated="+strStartDate+"&dater="+strEndDate+"&adult="+adultInputValue+"&enfant="+childrenInputValue+"&bebe="+infantsInputValue+"&devise="+localStorage.devise+"&roundtrip="+strFlyType;

    const urlr = "https://fly.tassiliairlines.com/B2C/fr/Home/Availability/"+startLoc+"/"+endLoc+"/"+strStartDate+"/"+strEndDate+"/"+adultInputValue+"/"+childrenInputValue+"/0/"+infantsInputValue+"/"+localStorage.devise+"/"+strFlyType+"/0#!";

//    window.close();

console.log("strFlyType:"+strFlyType)
updateResult("");
if ((startLoc===null)||(startLoc==='')) {  updateResult("erreur1");  return; }
if ((endLoc===null)||(endLoc==='')) {  updateResult("erreur2");  return; }
if  ((strStartDate==='Invalid Date')||(strStartDate==='NR')||(strStartDate===''))   {  updateResult("erreur3");  return; }
if ( ((strEndDate==='Invalid Date')||(strEndDate==='NR')||(strEndDate==='')) && (strFlyType=='True') ) {  updateResult("erreur4");  return; }

    window.open(urlr,"_self");

    localStorage.srchurl=url;



console.log(urlr);


/*

    xhr.open('GET', url);

    console.log('Get done, wait on load...');

    xhr.onload = function() {
      console.log("Status : "+xhr.status);
      if (xhr.status === 200) {

//        console.log(xhr.responseText);

        
//console.log(xhr.responseText);


        //srchResult=xhr.responseText;

        updateResult(xhr.responseText);
      
      //  setData(JSON.parse(xhr.responseText)[0].departure_date);

      } else console.log("Erreur ");
    };
    xhr.send(); 
    
    */

  }

  
  const [data, setData] = useState(null);


  /*

  function handleClick1() {

    console.log('Handle click start');

    fetch('http://127.0.0.1/tassapi/?from=AZR&to=ALG&dated=20240229&roundtrip=NR&adult=1&devise=EUR')
         .then((response) => response.json())
         .catch((error) => console.log(error));
  }
*/




  return (
    <>
      <Popover className={`FlightDateRangeInput relative flex ${className}`}>
        {({ open }) => (
          <>
            <div
              className={`flex-1 z-10 flex items-center focus:outline-none ${
                open ? "nc-hero-field-focused" : ""
              }`}
            >
              <Popover.Button
                className={`flex-1 z-10 flex relative ${fieldClassName} items-center space-x-3 focus:outline-none `}
                onClickCapture={() => document.querySelector("html")?.click()}
              >
                {renderInput()}

                {startDate && open && (
                  <ClearDataButton
                    onClick={() => onChangeRangeDate([null, null])}
                  />
                )}
              </Popover.Button>

              {/* BUTTON SUBMIT OF FORM */}
              {hasButtonSubmit && (
                <div className="pr-2 xl:pr-4">

              <ButtonPrimary onClick={handleClick} >
                
              <span className="mr-3 md:hidden">Search</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
        />
      </svg>

              </ButtonPrimary>
                </div>
              )}
            </div>

            {open && (
              <div className="h-8 absolute self-center top-1/2 -translate-y-1/2 z-0 -left-0.5 right-10 bg-white dark:bg-neutral-800"></div>
            )}

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute left-0 z-20 mt-3 top-full w-screen max-w-sm -translate-x-1/2 transform px-4 sm:px-0 lg:max-w-3xl">
                <div className="overflow-hidden rounded-3xl shadow-lg ring-1 ring-black ring-opacity-5 bg-white dark:bg-neutral-800 p-8">
                  {selectsRange ? (
                    <DatePicker
                      selected={startDate}
                      onChange={onChangeRangeDate}
                      startDate={startDate}
                      endDate={endDate}
                      selectsRange
                      minDate={new Date()}
                      monthsShown={2}
                      showPopperArrow={false}
                      locale={fr}
                      inline
                      renderCustomHeader={(p) => (
                        <DatePickerCustomHeaderTwoMonth {...p} />
                      )}
                      renderDayContents={(day, date) => (
                        <DatePickerCustomDay dayOfMonth={day} date={date} />
                      )}
                    />
                  ) : (
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date) }
                      monthsShown={2}
                      showPopperArrow={false}
                      minDate={new Date()}
                      locale={fr}
                      inline
                      renderCustomHeader={(p) => (
                        <DatePickerCustomHeaderTwoMonth {...p} />
                      )}
                      renderDayContents={(day, date) => (
                        <DatePickerCustomDay dayOfMonth={day} date={date} />
                      )}
                    />
                  )}
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>



    </>
  );
};

export default FlightDateRangeInput;
