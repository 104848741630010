import React, { FC, ReactNode } from "react";
import iataImg from "images/IATA_logo.png";
import aaacImg from "images/aaco.png";
import afraImg from "images/AFRAA_logo.png";
import tiacaImg from "images/TIACA.png";


export default function SectionOrgan() {
    return (
      <div className="bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="grid grid-cols-1 items-center gap-x-8 gap-y-16 lg:grid-cols-2">
            <div className="mx-auto w-full max-w-xl lg:mx-0">
              <h2 className="text-3xl font-bold tracking-tight text-gray-900">Organisations internationales</h2>
              <p className="mt-6 text-lg leading-8 text-gray-600">
              Tassili Airlines est membre des organisations internationales IATA , AACO, AFRAA et TIACA.
              </p>
              {/*
              <div className="mt-8 flex items-center gap-x-6">
                <a
                  href="#"
                  className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Create account
                </a>
                <a href="#" className="text-sm font-semibold text-gray-900">
                  Contact us <span aria-hidden="true">&rarr;</span>
                </a>
              </div>
    */}
            </div>
            <div className="mx-auto grid w-full max-w-xl grid-cols-2 items-center gap-y-12 sm:gap-y-14 lg:mx-0 lg:max-w-none lg:pl-8">
              <img
                className="max-h-24 w-full object-contain object-left"
                src={iataImg}
                alt="IATA"
                width={140}
                height={140}
              />
              <img
                className="max-h-24 w-full object-contain object-center"
                src={aaacImg}
                alt="AAAC"
                width={140}
                height={140}
              />
              <img
                className="max-h-32 w-full object-contain object-center"
                src={afraImg}
                alt="AFRA"
                width={140}
                height={140}
              />
              <img
                className="max-h-32 w-full object-contain object-center"
                src={tiacaImg}
                alt="TIACA"
                width={140}
                height={140}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
  