import React from "react";

const LogoSvg400 = () => {
  return (
 /*   <svg
      className="w-full block dark:hidden"
      viewBox="0 0 65 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.4444 14.0325C24.4444 21.4807 12.9444 29.3945 12.9444 29.3945C12.9444 29.3945 1.44444 21.4807 1.44444 14.0325C1.44444 7.51522 6.84722 2.39453 12.9444 2.39453C19.0417 2.39453 24.4444 7.51522 24.4444 14.0325Z"
        fill="currentColor"
      />
      <path
        d="M12.9444 29.3945C12.9444 29.3945 24.4444 21.4807 24.4444 14.0325C24.4444 7.51522 19.0417 2.39453 12.9444 2.39453C6.84722 2.39453 1.44444 7.51522 1.44444 14.0325C1.44444 21.4807 12.9444 29.3945 12.9444 29.3945ZM12.9444 29.3945V2.6807"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="13" cy="15" r="4" fill="white" />
      <path
        d="M31.8601 25.2216C32.951 25.2216 33.897 24.3097 33.9055 23.1761C33.897 22.0597 32.951 21.1477 31.8601 21.1477C30.7351 21.1477 29.8061 22.0597 29.8146 23.1761C29.8061 24.3097 30.7351 25.2216 31.8601 25.2216ZM43.9261 11.9091H41.3267V11.0312C41.3267 10.1449 41.6847 9.625 42.75 9.625C43.1847 9.625 43.6278 9.71875 43.9176 9.8125L44.5568 7.08523C44.1051 6.94886 43.108 6.72727 41.9915 6.72727C39.5284 6.72727 37.696 8.11648 37.696 10.9631V11.9091H35.8466V14.6364H37.696V25H41.3267V14.6364H43.9261V11.9091ZM46.1442 25H49.7749V11.9091H46.1442V25ZM47.968 10.2216C49.0504 10.2216 49.9368 9.39489 49.9368 8.38068C49.9368 7.375 49.0504 6.54829 47.968 6.54829C46.8942 6.54829 46.0078 7.375 46.0078 8.38068C46.0078 9.39489 46.8942 10.2216 47.968 10.2216ZM63.5753 15.642C63.2514 13.2301 61.3082 11.7386 58.0014 11.7386C54.652 11.7386 52.4446 13.2898 52.4531 15.8125C52.4446 17.7727 53.6804 19.0426 56.2372 19.554L58.5043 20.0057C59.6463 20.2358 60.1662 20.6534 60.1832 21.3097C60.1662 22.0852 59.3224 22.6392 58.0526 22.6392C56.7571 22.6392 55.8963 22.0852 55.6747 21.0199L52.1037 21.2074C52.4446 23.7131 54.5753 25.2557 58.044 25.2557C61.4361 25.2557 63.8651 23.5256 63.8736 20.9432C63.8651 19.0511 62.6293 17.9176 60.0895 17.3977L57.7202 16.9205C56.5014 16.6562 56.0497 16.2386 56.0582 15.608C56.0497 14.8239 56.9361 14.3125 58.0611 14.3125C59.3224 14.3125 60.0724 15.0028 60.2514 15.8466L63.5753 15.642Z"
        fill="#1F2937"
      />
    </svg>*/
<svg
   height="62"
   viewBox="0 0 24.485721 3.2592992"
   width="400"
   version="1.1"
   id="svg38"
   xmlns="http://www.w3.org/2000/svg"><defs
     id="defs38" /><g
     transform="translate(1.4212449,-146.14491)"
     id="g38"><g
       fill="#231f20"
       id="g32"><path
         d="m -0.7726904,148.95582 c 0,0.0977 0,0.13758 0.063147,0.13758 0.022931,0 0.045861,-0.0113 0.063147,-0.0113 0.011289,0 0.011289,0 0.011289,0.0173 0,0.0402 -0.017286,0.0229 -0.1090083,0.0459 -0.1090083,0.0286 -0.1545167,0.0628 -0.1605139,0.0628 -0.011289,0 -0.022578,-0.006 -0.022578,-0.0169 0,-0.0116 0.016933,-0.0342 0.022578,-0.0801 0.006,-0.0748 0.011642,-0.26987 0.011642,-0.57961 v -0.0688 c 0,-0.16087 0,-0.25259 -0.022931,-0.27552 -0.017286,-0.0173 -0.063147,-0.0229 -0.149225,-0.0229 -0.1033639,0 -0.1778,0.006 -0.2236611,0.0342 -0.040217,0.0173 -0.051506,0.0519 -0.063147,0.0519 -0.011289,0 -0.017639,-0.006 -0.017639,-0.0229 v -0.0289 -0.0857 -0.0233 c 0,-0.0169 0,-0.0229 0.023283,-0.0229 0.022931,0 0.1661584,0.006 0.4303889,0.006 0.3499556,0 0.5506861,-0.006 0.5965472,-0.006 0.016933,0 0.022931,0 0.022931,0.0176 0,0.0459 -0.011642,0.0459 -0.045861,0.0459 -0.1375834,0.006 -0.2811639,0.0226 -0.4191,0.0459 -0.011289,0.37853 -0.011289,0.63676 -0.011289,0.77999"
         id="path1" /><path
         d="m -0.089956,148.84121 v 0.0286 c 0,0.13759 -0.063147,0.21802 -0.1375833,0.2413 v 0.0515 c 0.045861,-0.0233 0.086078,-0.0515 0.1259416,-0.0977 0.011642,0.0921 0.051858,0.13194 0.1262945,0.13194 0.057503,0 0.1319389,-0.0286 0.1319389,-0.0515 0,-0.0289 -0.028575,-0.006 -0.068792,-0.0289 -0.051506,-0.0229 -0.074436,-0.0801 -0.074436,-0.17181 0,-0.0519 0.00564,-0.12065 0.00564,-0.19508 0,-0.15487 -0.034572,-0.22402 -0.1778,-0.22402 -0.028575,0 -0.051505,0 -0.068792,0.006 v 0.0914 h 0.011289 c 0.086078,0 0.1262944,0.0522 0.1262944,0.16087 v 0.0173 c -0.051506,0.006 -0.097367,0.0173 -0.1375833,0.0346 v 0.0459 c 0.034219,-0.0173 0.086078,-0.0286 0.1375833,-0.0402 m -0.1375833,0.26987 c -0.017286,0.005 -0.034572,0.005 -0.057503,0.005 -0.051506,0 -0.086078,-0.0342 -0.086078,-0.0801 0,-0.0631 0.051858,-0.11501 0.1435806,-0.15487 v -0.0459 c -0.1778,0.0515 -0.269875,0.14358 -0.269875,0.24659 0,0.0748 0.045861,0.11465 0.1322916,0.11465 0.045861,0 0.091722,-0.0116 0.1375834,-0.0342 z m 0,-0.57961 v 0.0914 c -0.063147,0 -0.09772,0.0229 -0.12065,0.0229 0,0 -0.00564,-0.005 -0.00564,-0.0109 0,-0.0692 0.040217,-0.0977 0.1262945,-0.10337"
         id="path2" /><path
         d="m 0.2484955,149.17399 c -0.022931,-0.006 -0.022931,-0.0229 -0.022931,-0.0688 v -0.0173 c 0,-0.0631 0.006,-0.0804 0.028575,-0.0804 0.011642,0 0.017286,0.006 0.017286,0.0173 v 0.0113 c 0,0.0748 0.045861,0.10937 0.1375833,0.10937 0.1379361,0 0.2296583,-0.0804 0.2296583,-0.16087 0,-0.0402 -0.028575,-0.0631 -0.086078,-0.0631 h -0.074436 c -0.1262944,0 -0.1894416,-0.0402 -0.1894416,-0.15487 0,-0.13194 0.097719,-0.24095 0.2465916,-0.24095 0.086078,0 0.149225,0.0289 0.149225,0.0402 0,0.0286 -0.016933,0.0744 -0.028575,0.0744 -0.017286,0 -0.068792,-0.0342 -0.1605139,-0.0342 -0.080433,0 -0.1266472,0.0402 -0.1266472,0.10301 0,0.0748 0.063147,0.0804 0.1721556,0.0921 0.1379361,0.0113 0.2180166,0.0342 0.2180166,0.16616 0,0.13794 -0.1262944,0.2353 -0.3210277,0.2353 -0.1033639,0 -0.1721556,-0.0173 -0.1894417,-0.0286"
         id="path3" /><path
         d="m 0.8910445,149.17399 c -0.01693,-0.006 -0.01693,-0.0229 -0.01693,-0.0688 v -0.0173 c 0,-0.0631 0,-0.0804 0.02258,-0.0804 0.01729,0 0.02293,0.006 0.02293,0.0173 v 0.0113 c 0,0.0748 0.04586,0.10937 0.131939,0.10937 0.14358,0 0.235303,-0.0804 0.235303,-0.16087 0,-0.0402 -0.02858,-0.0631 -0.08608,-0.0631 h -0.07444 c -0.131939,0 -0.195086,-0.0402 -0.195086,-0.15487 0,-0.13194 0.103364,-0.24095 0.252589,-0.24095 0.08008,0 0.143227,0.0289 0.143227,0.0402 0,0.0286 -0.01164,0.0744 -0.02858,0.0744 -0.01729,0 -0.06315,-0.0342 -0.160514,-0.0342 -0.08043,0 -0.126647,0.0402 -0.126647,0.10301 0,0.0748 0.06315,0.0804 0.172509,0.0921 0.137583,0.0113 0.223308,0.0342 0.223308,0.16616 0,0.13794 -0.125942,0.2353 -0.327025,0.2353 -0.09737,0 -0.171803,-0.0173 -0.189089,-0.0286"
         id="path4" /><path
         d="m 1.7343604,149.12234 v 0.0286 c 0,0.0117 -0.0056,0.0229 -0.01729,0.0286 -0.05151,0.0173 -0.08608,0.0286 -0.08608,0.0286 -0.01129,0 -0.01129,-0.0286 -0.01129,-0.0801 v -0.37288 c 0,-0.0861 0,-0.12594 -0.05186,-0.12594 -0.01729,0 -0.02858,0.006 -0.04022,0.006 -0.01129,0 -0.01693,-0.0116 -0.01693,-0.0289 0,-0.0173 0.03457,-0.0173 0.09172,-0.0342 0.06879,-0.0229 0.103364,-0.0459 0.120297,-0.0459 0.006,0 0.02293,0.0113 0.02293,0.0229 0,0.0113 -0.01129,0.0917 -0.01129,0.23531 z"
         id="path5" /><path
         d="m 2.0099144,148.49697 c 0,-0.0632 -0.006,-0.10901 -0.006,-0.14323 0,-0.13793 -0.0056,-0.20673 -0.0056,-0.21801 0,-0.0229 0.0056,-0.0173 0.0575,-0.0289 0.08043,-0.0226 0.120297,-0.0402 0.131939,-0.0402 0.0056,0 0.01729,0.006 0.01729,0.0229 0,0.0173 -0.05186,0.0233 -0.06879,0.0748 -0.01764,0.0632 -0.01764,0.29245 -0.01764,0.68827 v 0.0861 c 0,0.12629 0,0.16651 0.03457,0.16651 0.03457,0 0.0575,-0.0173 0.06879,-0.0173 0.0056,0 0.01164,0.0116 0.01164,0.0229 0,0.0233 -0.03457,0.0233 -0.09207,0.0515 -0.08573,0.0346 -0.1146529,0.0748 -0.1315859,0.0748 -0.01729,0 -0.02858,-0.0116 -0.02858,-0.0229 0,-0.0116 0.02858,-0.0744 0.02858,-0.30974 z"
         id="path6" /><path
         d="m 2.5488883,149.12234 v 0.0286 c 0,0.0117 -0.0056,0.0229 -0.02293,0.0286 -0.04551,0.0173 -0.08008,0.0286 -0.08008,0.0286 -0.01164,0 -0.01164,-0.0286 -0.01164,-0.0801 v -0.37288 c 0,-0.0861 -0.0056,-0.12594 -0.05715,-0.12594 -0.01164,0 -0.02328,0.006 -0.03457,0.006 -0.01164,0 -0.01693,-0.0116 -0.01693,-0.0289 0,-0.0173 0.02822,-0.0173 0.09137,-0.0342 0.06915,-0.0229 0.103364,-0.0459 0.114653,-0.0459 0.01164,0 0.02328,0.0113 0.02328,0.0229 0,0.0113 -0.006,0.0917 -0.006,0.23531 z"
         id="path7" /><path
         d="m 3.8226951,148.67488 c -0.07479,0.006 -0.143581,0.006 -0.212372,0.0113 v 0.0688 h 0.149225 c 0.02293,0 0.05151,-0.006 0.09172,-0.006 0.1262939,0.30409 0.1950859,0.45896 0.2180169,0.45896 0.01729,0 0.04022,-0.0346 0.103364,-0.0861 0.06279,-0.0459 0.09137,-0.0575 0.09137,-0.0632 0,-0.0113 -0.0053,-0.0229 -0.01094,-0.0229 -0.01164,0 -0.02893,0.0173 -0.05786,0.0173 -0.103011,0 -0.183444,-0.20073 -0.3496019,-0.64276 -0.115006,-0.2981 -0.120298,-0.43603 -0.143581,-0.43603 -0.01729,0 -0.06279,0.0293 -0.09172,0.0462 v 0.27517 c 0.01729,-0.0399 0.02893,-0.0744 0.04022,-0.10301 0.06879,0.22366 0.125942,0.37853 0.172156,0.48189 m -0.212372,0.0113 h -0.09172 -0.12065 c 0.09772,-0.16616 0.166511,-0.2981 0.212372,-0.39017 v -0.27517 c -0.01729,0.0113 -0.02293,0.0173 -0.02293,0.0229 0,0 0.0056,0.0113 0.0056,0.0229 0,0.0804 -0.109009,0.30974 -0.321028,0.69391 -0.1665109,0.3041 -0.2638779,0.44768 -0.2638779,0.46497 0,0.0116 0.0056,0.0229 0.01693,0.0229 0.03457,0 0.09207,-0.14358 0.2413,-0.35596 0.07444,-0.10301 0.1661589,-0.13194 0.3439589,-0.13758 z"
         id="path8" /><path
         d="m 4.5110059,149.12234 v 0.0286 c 0,0.0117 -0.0056,0.0229 -0.02293,0.0286 -0.04586,0.0173 -0.08043,0.0286 -0.08043,0.0286 -0.01129,0 -0.01129,-0.0286 -0.01129,-0.0801 v -0.37288 c 0,-0.0861 0,-0.12594 -0.05186,-0.12594 -0.01729,0 -0.02822,0.006 -0.04022,0.006 -0.01129,0 -0.01693,-0.0116 -0.01693,-0.0289 0,-0.0173 0.03422,-0.0173 0.09172,-0.0342 0.06879,-0.0229 0.103364,-0.0459 0.1202969,-0.0459 0.006,0 0.02328,0.0113 0.02328,0.0229 0,0.0113 -0.01164,0.0917 -0.01164,0.23531 z"
         id="path9" /><path
         d="m 5.0962609,148.52562 h 0.0056 c 0.0056,0 0.0056,0 0.0056,0.0229 v 0.0861 c 0,0.0402 -0.0056,0.0286 -0.07444,0.0342 -0.12065,0.0116 -0.126295,0.0462 -0.131939,0.16086 -0.01164,0.20073 -0.006,0.30974 -0.01164,0.32138 -0.0056,0.0113 -0.09172,0.0572 -0.103011,0.0572 -0.006,0 -0.006,0 -0.006,-0.0116 0,-0.0113 0.01164,-0.10301 0.01164,-0.30374 v -0.0804 c 0,-0.1263 -0.0056,-0.16651 -0.04621,-0.16651 -0.03986,0 -0.06844,0.0229 -0.08008,0.0229 -0.0056,0 -0.01129,-0.0113 -0.01129,-0.0286 0,-0.0173 0.02293,-0.0173 0.07444,-0.0342 0.09737,-0.0406 0.149225,-0.0804 0.15487,-0.0804 0.01129,0 0.02293,0.006 0.02293,0.0116 0,0.0113 -0.0056,0.0282 -0.0056,0.0688 v 0.0688 c 0.08008,-0.10936 0.160514,-0.14922 0.195086,-0.14922"
         id="path10" /><path
         d="m 5.2798788,148.49697 c 0,-0.0632 -0.0056,-0.10901 -0.0056,-0.14323 0,-0.13793 -0.006,-0.20673 -0.006,-0.21801 0,-0.0229 0.006,-0.0173 0.0575,-0.0289 0.08043,-0.0226 0.120297,-0.0402 0.131939,-0.0402 0.006,0 0.01693,0.006 0.01693,0.0229 0,0.0173 -0.05151,0.0233 -0.06844,0.0748 -0.01764,0.0632 -0.01764,0.29245 -0.01764,0.68827 v 0.0861 c 0,0.12629 0,0.16651 0.03457,0.16651 0.03457,0 0.0575,-0.0173 0.06879,-0.0173 0.006,0 0.01164,0.0116 0.01164,0.0229 0,0.0233 -0.03457,0.0233 -0.09172,0.0515 -0.08608,0.0346 -0.114653,0.0748 -0.131939,0.0748 -0.01729,0 -0.02893,-0.0116 -0.02893,-0.0229 0,-0.0116 0.02893,-0.0744 0.02893,-0.30974 z"
         id="path11" /><path
         d="m 5.8190707,149.12234 v 0.0286 c 0,0.0117 -0.0056,0.0229 -0.02328,0.0286 -0.04586,0.0173 -0.08008,0.0286 -0.08008,0.0286 -0.01129,0 -0.01129,-0.0286 -0.01129,-0.0801 v -0.37288 c 0,-0.0861 -0.0056,-0.12594 -0.0575,-0.12594 -0.01129,0 -0.02293,0.006 -0.03422,0.006 -0.01164,0 -0.01729,-0.0116 -0.01729,-0.0289 0,-0.0173 0.02858,-0.0173 0.09172,-0.0342 0.06879,-0.0229 0.1033639,-0.0459 0.1146529,-0.0459 0.01164,0 0.02293,0.0113 0.02293,0.0229 0,0.0113 -0.0056,0.0917 -0.0056,0.23531 z"
         id="path12" /><path
         d="m 6.0945547,148.82978 c 0,-0.13229 -0.02293,-0.20073 -0.08608,-0.20073 h -0.02893 c -0.01129,0 -0.01693,-0.006 -0.01693,-0.0229 0,-0.0116 0.02293,-0.0116 0.05715,-0.0289 0.06315,-0.0286 0.08643,-0.0515 0.09172,-0.0515 0.01729,0 0.0635,0.0402 0.08643,0.109 0.114653,-0.0688 0.2116669,-0.0974 0.2635249,-0.0974 0.114653,0 0.148872,0.0974 0.148872,0.28081 0,0.12065 -0.0053,0.20673 -0.0053,0.25259 0,0.0226 0.0053,0.0286 0.02858,0.0286 0.01729,0 0.03457,-0.0113 0.05151,-0.0113 0.0056,0 0.01164,0.0113 0.01164,0.0229 0,0.0286 -0.02858,0.0229 -0.09737,0.0459 -0.06914,0.0286 -0.09772,0.0571 -0.109008,0.0571 -0.01164,0 -0.01729,-0.0113 -0.01729,-0.0173 0,-0.0113 0.02858,-0.0917 0.02858,-0.28681 0,-0.19509 -0.04022,-0.2921 -0.15487,-0.2921 -0.04586,0 -0.09737,0.0282 -0.1435799,0.0741 0,0.0229 0.006,0.0575 0.006,0.0977 0,0.18345 -0.006,0.30974 -0.006,0.37853 l -0.08608,0.0346 h -0.0056 c -0.01129,0 -0.01693,-0.0113 -0.01693,-0.0289 z"
         id="path13" /><path
         d="m 7.0236625,148.59448 c 0.01164,0 0.02293,-0.006 0.03457,-0.006 0.04586,0 0.08008,0.0406 0.08008,0.0864 0,0.0572 -0.03986,0.10901 -0.114653,0.13759 v 0.0515 c 0.160867,-0.0572 0.246944,-0.1203 0.246944,-0.16651 0,-0.10301 -0.07479,-0.1718 -0.183797,-0.1718 -0.02293,0 -0.04022,0.006 -0.06315,0.0116 z m 0,0.60219 v -0.11465 c 0.02293,0.0116 0.05151,0.0173 0.08608,0.0173 0.08608,0 0.14358,-0.0346 0.195086,-0.0974 0.02293,-0.0229 0.02858,-0.0462 0.04022,-0.0462 0.01129,0 0.02293,0.0117 0.02293,0.0233 0,0.0169 -0.03457,0.0744 -0.109008,0.16651 -0.02858,0.0399 -0.04586,0.0571 -0.172156,0.0571 -0.02293,0 -0.04022,0 -0.06315,-0.006 m -0.1150059,-0.3556 v -0.0402 c 0,-0.109 0.05186,-0.18309 0.1150059,-0.20637 v -0.0572 c -0.1319389,0.0342 -0.2293059,0.17745 -0.2293059,0.36124 0,0.16616 0.08008,0.27517 0.2293059,0.2981 v -0.11465 c -0.06315,-0.0286 -0.09737,-0.0917 -0.1090079,-0.18345 0.04022,-0.0116 0.07444,-0.0229 0.1090079,-0.0346 v -0.0515 c -0.03457,0.0116 -0.06879,0.0229 -0.1150059,0.0286"
         id="path14" /><path
         d="m 7.4482655,149.17399 c -0.01729,-0.006 -0.01729,-0.0229 -0.01729,-0.0688 v -0.0173 c 0,-0.0631 0,-0.0804 0.02293,-0.0804 0.01729,0 0.02328,0.006 0.02328,0.0173 v 0.0113 c 0,0.0748 0.04586,0.10937 0.1372299,0.10937 0.137936,0 0.230011,-0.0804 0.230011,-0.16087 0,-0.0402 -0.02893,-0.0631 -0.08643,-0.0631 h -0.07479 c -0.1315859,0 -0.1947329,-0.0402 -0.1947329,-0.15487 0,-0.13194 0.1033639,-0.24095 0.2525889,-0.24095 0.08008,0 0.149225,0.0289 0.149225,0.0402 0,0.0286 -0.01764,0.0744 -0.02893,0.0744 -0.01693,0 -0.06879,-0.0342 -0.166511,-0.0342 -0.08043,0 -0.126295,0.0402 -0.126295,0.10301 0,0.0748 0.0635,0.0804 0.172509,0.0921 0.137583,0.0113 0.223308,0.0342 0.223308,0.16616 0,0.13794 -0.125942,0.2353 -0.326672,0.2353 -0.09772,0 -0.1665109,-0.0173 -0.1894419,-0.0286"
         id="path15" /><path
         d="m 5.6582718,148.42243 0.06315,-0.0861 0.114653,0.0861 -0.06279,0.0974 z"
         id="path16" /><path
         d="m 4.367634,148.42243 0.06315,-0.0861 0.1146529,0.0861 -0.06315,0.0974 z"
         id="path17" /><path
         d="m 2.3884133,148.42243 0.06315,-0.0861 0.114653,0.0861 -0.06279,0.0974 z"
         id="path18" /><path
         d="m 1.5908504,148.42243 0.06315,-0.0861 0.114653,0.0861 -0.06279,0.0974 z"
         id="path19" /><path
         d="m 22.64106,148.5805 c 0.384528,-0.15487 0.413456,0.1203 0.413456,0.1203 0,0 -0.12065,0.17815 -0.183798,0.19508 -0.08043,0.0173 -0.160514,0.0346 -0.229658,0.0459 v -0.11465 c 0.172508,-0.0286 0.264231,-0.0801 0.264231,-0.0801 -0.06315,-0.0864 -0.166512,-0.0804 -0.264231,-0.0519 z m -1.0668,-0.0459 c -0.01729,0.13194 -0.223661,0.16616 -0.223661,0.16616 -0.05151,0.26387 -0.47625,0.48224 -0.751417,0.54539 -0.281516,0.0572 -0.292805,-0.16651 -0.292805,-0.16651 l 0.04586,-0.11501 c 0.154869,0.36125 0.826205,-0.0801 0.877711,-0.16615 0.04621,-0.0748 -0.126295,-0.20673 -0.126295,-0.20673 l 0.08608,-0.14923 0.09172,0.0921 c 0.189442,0.0571 0.298803,-0.11501 0.310092,-0.15487 0.0053,-0.0402 0.03422,-0.0346 0.03422,-0.0346 0.08043,0.56232 0.613833,0.48189 0.613833,0.48189 0.166511,-0.12629 0.29845,-0.20073 0.401461,-0.24659 v 0.11465 c -0.137583,0.0459 -0.263525,0.14358 -0.263525,0.14358 0.103011,0.006 0.189089,0 0.263525,-0.0116 v 0.11465 c -0.636411,0.12065 -0.854781,0.0117 -0.957792,-0.13158 -0.08043,-0.121 -0.109008,-0.27552 -0.109008,-0.27552"
         id="path20" /><path
         d="m 22.302777,148.77545 c 0,0 0.109008,-0.0113 0.01729,-0.67134 0,0 0.09172,-0.0801 0.09737,-0.13758 0,0 -0.120297,-0.0917 -0.137584,-0.17215 l -0.09772,0.12629 c 0,0 0.137936,0.60819 0.103364,0.85478 z"
         id="path21" /><path
         d="m 17.598521,148.78702 c 0,0 0.109008,-0.0116 0.01729,-0.66569 0,0 0.09172,-0.0857 0.09172,-0.13723 0,0 -0.114652,-0.0921 -0.137583,-0.17251 l -0.09772,0.12629 c 0,0 0.137936,0.60819 0.109361,0.85478 z"
         id="path22" /><path
         d="m 20.415349,148.38535 -0.07444,0.16051 c 0,0 0.131939,0.15523 0.109008,0.20673 0,0 -0.224014,0.19509 -0.711553,0.26388 -0.493183,0.0748 -1.164519,0.0804 -1.388533,-0.20638 l -0.07444,0.0402 c 0,0 0.258233,0.36125 0.648406,0.35525 0,0 1.141588,0.0921 1.577622,-0.38418 0,0 0.172155,-0.16616 -0.08608,-0.43603"
         id="path23" /><path
         d="m 20.799598,147.73137 -0.103011,0.15487 0.09737,1.02129 c 0,0 0.09772,-0.0974 0.09772,-0.21237 z"
         id="path24" /><path
         d="m 17.873797,148.51721 c 0.309739,-0.14922 0.3556,0.12065 0.3556,0.12065 0,0 -0.03986,0.0572 -0.08608,0.10901 0,0 0.258586,-0.0402 0.29845,-0.0688 0.04022,-0.0289 -0.05115,-0.68827 -0.06279,-0.72284 0,0 0.06279,-0.17216 0.07444,-0.16616 0.01129,0 0.401461,0.28645 0.441678,0.68791 v 0.13794 c -0.01129,0.0861 -0.04022,0.1838 -0.09737,0.28116 0,0 0.154869,-0.4706 -0.292806,-0.84349 l 0.02293,0.55633 c 0,0 -0.09172,0.20109 -0.120297,0.21237 0,0 -0.252589,0.0635 -0.533753,0.10337 v -0.15487 c 0.137583,-0.0289 0.212372,-0.0748 0.212372,-0.0748 -0.0515,-0.0861 -0.137583,-0.0801 -0.212372,-0.0515 z m -0.384175,0.27552 c 0.08008,-0.0575 0.143228,-0.10901 0.200731,-0.15487 0.06879,-0.0575 0.131938,-0.0917 0.183444,-0.12065 v 0.12629 c -0.108656,0.0402 -0.212372,0.13794 -0.212372,0.13794 0.08079,0.006 0.155222,0 0.212372,-0.0116 v 0.15487 c -0.223308,0.0286 -0.458964,0.0459 -0.596547,0.006 0,0 -0.160514,0.0572 -0.177447,-0.50482 0,0 -0.04057,-0.55069 -0.06915,-0.5909 l 0.05186,-0.1263 c 0,0 0.08008,0.24695 0.103012,0.69427 0,0 0.01729,0.24094 0.07479,0.33831 0.02858,0.0406 0.229306,0.0519 0.229306,0.0519"
         id="path25" /><path
         d="m 15.997905,148.75248 c 0.04586,0.21237 0.292453,0.10901 0.292453,0.10901 0.287161,0.13194 0.4191,-0.0744 0.4191,-0.0744 0.0515,0.13194 0.172155,0.0744 0.172155,0.0744 0.1778,-0.0974 0.09172,-0.37853 0.09172,-0.37853 l -0.09172,0.1203 0.01129,0.115 c 0,0 -0.120297,0.0286 -0.09737,-0.13758 v -0.0692 c 0,0 -0.06914,0.25259 -0.315737,0.22931 l 0.03457,-0.12594 c 0,0 -0.235303,0.19544 -0.45332,0.0346 0,0 -0.04586,-0.0579 -0.04586,-0.0748 0.0056,-0.0173 -0.143228,0.22401 -0.252589,0.1203 -0.114653,-0.10337 -0.137583,-0.96344 -0.137583,-0.96344 l -0.08008,0.14323 0.03422,0.52211 c 0,0 -0.09737,0.2353 -0.384175,0.2353 l -0.103364,0.12594 c 0,0 0.01129,0.0575 0.02293,0.0575 0.01729,0 0.281164,0.0575 0.258233,0.12029 -0.02293,0.0692 -1.239308,0.43604 -1.858786,-0.0286 0,0 0.131939,0.44168 1.113014,0.37888 0,0 0.900642,-0.10336 0.900642,-0.40146 0,-0.0691 -0.09172,-0.14393 -0.166159,-0.17215 0,0 0.223309,-0.14358 0.240595,-0.27552 0,0 -0.01129,0.43603 0.183444,0.43603 0.13194,0 0.166512,-0.0861 0.166512,-0.10901 0,-0.0233 0.04586,-0.0116 0.04586,-0.0116"
         id="path26" /><path
         d="m 16.244669,147.97789 0.05151,-0.1203 c 0,0 0.258233,-0.0572 0.275872,-0.0116 0.02258,0.0459 0.01129,0.20108 -0.235656,0.24095 l -0.02293,-0.0399 0.154869,-0.0691 c 0,0 -0.02858,-0.0515 -0.212372,-0.006 z"
         id="path27" /><path
         d="m 21.121046,148.05261 0.09737,-0.12629 0.659694,-0.18909 -0.06879,0.12029 z"
         id="path28" /><path
         d="m 20.197407,148.31652 -0.166158,-0.0861 0.09172,-0.13229 0.1778,0.0921 z"
         id="path29" /><path
         d="m 16.399606,149.11958 -0.1778,-0.0917 -0.06315,0.0861 -0.131939,-0.0688 -0.09772,0.13194 0.166512,0.0861 0.06879,-0.0861 0.126295,0.0692 z"
         id="path30" /><path
         d="m 15.739739,149.25748 -0.172156,-0.0917 -0.06315,0.0857 -0.131586,-0.0684 -0.09772,0.13194 0.166511,0.0857 0.06879,-0.0801 0.126295,0.0631 z"
         id="path31" /><path
         d="m 21.769064,149.12551 -0.177447,-0.0974 -0.0635,0.0857 -0.131939,-0.0688 -0.09172,0.13229 0.160866,0.0857 0.06879,-0.0801 0.126294,0.0684 z"
         id="path32" /></g><path
       d="m 13.65306,146.18676 h -1.219969 c -0.792602,0.74173 -2.7205951,3.00871 -4.0249025,3.00871 h 4.0017505 z"
       fill="#162d66"
       id="path33"
       /><path
       d="m 8.4081883,149.1954 h 4.0017507 l 0.276643,-0.66639 c -0.112453,0.12175 -0.708499,0.49884 -4.2783937,0.66639"
       fill="#74ba24"
       id="path34"
       /><path
       d="m 13.65306,146.18676 h -1.219969 c -0.173403,0.16354 -0.398545,0.39819 -0.661249,0.66619 0.229866,-0.23465 1.224457,-0.34333 1.749629,-0.34333 z"
       fill="#74ba24"
       id="path35"
       /><path
       d="m 11.560733,148.0053 c 0,0.0504 0.0048,0.0671 0.03733,0.0671 0.01441,0 0.02859,-0.004 0.03756,-0.004 0.005,0 0.005,0.004 0.005,0.008 0,0.0209 -0.0094,0.0127 -0.06095,0.0251 -0.06095,0.0127 -0.08906,0.0293 -0.09379,0.0293 -0.0048,0 -0.0094,-0.004 -0.0094,-0.008 0,-0.004 0.0094,-0.0167 0.0094,-0.0376 0.0048,-0.0378 0.0092,-0.13421 0.0092,-0.28488 v -0.0336 c 0,-0.0795 0,-0.12577 -0.01394,-0.13421 -0.0094,-0.008 -0.0378,-0.0124 -0.08458,-0.0124 -0.06095,0 -0.103239,0.004 -0.131352,0.0167 -0.02338,0.008 -0.02811,0.0251 -0.03756,0.0251 -0.0048,0 -0.0092,-0.004 -0.0092,-0.008 v -0.0167 -0.0418 -0.0127 c 0,-0.008 0,-0.0127 0.01394,-0.0127 0.01418,0 0.09379,0.004 0.248766,0.004 0.201517,0 0.318931,-0.004 0.342319,-0.004 0.0094,0 0.01394,0.004 0.01394,0.008 0,0.0253 -0.0045,0.0253 -0.02338,0.0253 -0.08434,0.004 -0.164191,0.008 -0.243805,0.0211 -0.0048,0.18422 -0.0094,0.31421 -0.0094,0.3811"
       fill="#ffffff"
       id="path36"
       /><path
       d="m 12.090843,147.85032 c -0.04229,0.004 -0.08434,0.004 -0.121902,0.004 v 0.0377 c 0.02363,-0.004 0.0515,-0.004 0.08457,-0.004 h 0.05623 c 0.0704,0.15087 0.107727,0.22621 0.121902,0.22621 0.0092,0 0.02811,-0.0209 0.06095,-0.0418 0.03756,-0.0251 0.05622,-0.0295 0.05622,-0.0335 0,-0.004 -0.0094,-0.0127 -0.0094,-0.0127 -0.0092,0 -0.01843,0.0127 -0.0326,0.0127 -0.06095,0 -0.107965,-0.10066 -0.201753,-0.31843 -0.06567,-0.14686 -0.0704,-0.20975 -0.08458,-0.20975 -0.0094,0 -0.03756,0.008 -0.0515,0.0211 v 0.13399 c 0.0092,-0.0209 0.01866,-0.0376 0.02363,-0.0503 0.03733,0.10889 0.07016,0.18444 0.09828,0.23466 m -0.121902,0.004 h -0.05646 -0.0704 c 0.06119,-0.0796 0.09875,-0.14244 0.126864,-0.18865 v -0.13399 c -0.0094,0.004 -0.01418,0.008 -0.01418,0.008 v 0.0124 c 0,0.0378 -0.06071,0.15109 -0.182853,0.33953 -0.09875,0.15067 -0.150252,0.21798 -0.150252,0.23044 0,0.004 0,0.008 0.0094,0.008 0.0189,0 0.05174,-0.0713 0.140802,-0.17198 0.04229,-0.0503 0.09379,-0.0669 0.197028,-0.0669 z"
       fill="#ffffff"
       id="path37"
       /><path
       d="m 12.358201,147.76659 v -0.0713 c 0,-0.088 0,-0.12155 -0.02339,-0.12155 -0.0094,0 -0.01394,0.004 -0.01394,0.004 -0.005,0 -0.0094,-0.004 -0.0094,-0.008 0,-0.008 0.01394,-0.008 0.03733,-0.0169 0.05174,-0.0169 0.0704,-0.0336 0.07985,-0.0336 0,0 0.0094,0.004 0.0094,0.0127 0,0.008 -0.0094,0.0964 -0.0094,0.31843 0,0.0753 0,0.14665 0.0045,0.21376 0.05646,0.0124 0.117413,0.0124 0.188051,0.0124 0.07938,0 0.116941,-0.004 0.121902,-0.004 0.0045,0 0.0045,0 0.0045,0.004 0,0.0378 -0.09851,0.042 -0.304992,0.042 h -0.09379 c 0.0094,-0.0629 0.0094,-0.17598 0.0094,-0.35197"
       fill="#ffffff"
       id="path38"
       /></g></svg>

  
    
  );
};

export default LogoSvg400;
