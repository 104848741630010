import React, { FC,createContext, SetStateAction, useState, Dispatch } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
//import imagePng from "images/hero-right.png";
import imagePng from "images/banner1.jpg";
import HeroSearchForm from "components/HeroSearchForm/HeroSearchForm";
import SectionGridFilterCard from "../../containers/ListingFlightsPage/SectionGridFilterCard";

export interface SectionHeroProps {
  className?: string;
}

interface IsrchResultContext {
  srchResult: String;
  updateResult: Dispatch<SetStateAction<string>>;
}

export let srchResultContext = React.createContext<IsrchResultContext>({
  srchResult: "",
  updateResult: () => {}
});


const SectionHero: FC<SectionHeroProps> = ({ className = "" }) => {


  const [srchResult, updateResult] = React.useState('')
  const value = { srchResult, updateResult};

    function updatesrch() {
        updateResult('aaa');
    }

  return (
    <div
      className={`nc-SectionHero flex flex-col-reverse lg:flex-col relative ${className}`}
      data-nc-id="SectionHero"
    >
      <div className="flex flex-col lg:flex-row lg:items-start">

{/**
      <div className="absolute z-10 inset-x-0 top-[10%] sm:top-[25%] text-center flex flex-col items-center max-w-2xl mx-auto space-y-4 lg:space-y-5 xl:space-y-2">


        <h5 className="font-medium text-2xl md:text-3xl xl:text-4xl !leading-[114%] ">
          Économisez jusqu'à 25%*
          </h5>
          <span className="text-base md:text-lg text-neutral-500 dark:text-neutral-400">
          Offres saisonnières pour des escapades mémorables
          </span>
          <ButtonPrimary>Réserver maintenant</ButtonPrimary>


      </div>
*/}


        <div className="flex-grow">
          <img 
          className="absolute inset-0 object-cover rounded-xl"
          //className="w-full" 
          src={imagePng} alt="hero" />
        </div>


        <div className="flex-shrink-0 lg:w-1/2 flex flex-col items-start space-y-18 sm:space-y-20 pb-14 lg:pb-64 xl:pr-14 lg:mr-10 xl:mr-0">
        </div>
      </div>

      <div className="hidden lg:block z-10 mb-12 lg:mb-0 lg:-mt-40 w-full">
      <srchResultContext.Provider value={{srchResult, updateResult}}>
        <HeroSearchForm />
      </srchResultContext.Provider>
      </div>


    {
      srchResult==='erreur1'?<span className="text-red-600 ml-24 mt-2">Veuillez selectionner un aéroport de départ !</span>:''
    }
    {
      srchResult==='erreur2'?<span className="text-red-600 ml-24 mt-2">Veuillez selectionner un aéroport d'arrivée !</span>:''
    }
    {
      srchResult==='erreur3'?<span className="text-red-600 ml-24 mt-2">Veuillez selectionner une date de départ valide !</span>:''
    }
    {
      srchResult==='erreur4'?<span className="text-red-600 ml-24 mt-2">Veuillez selectionner une date de d'arrivèe valide !</span>:''
    }





      <div className="container pl-0 pr-0 relative">

        {/* SECTION */}
        <SectionGridFilterCard srchResult={srchResult} className="" />
        
</div>



    </div>
  );
};

export default SectionHero;
