import { MessagePayload, onMessage } from "firebase/messaging";
import React, { useEffect, useState, Fragment } from "react";
import MyRouter from "routers/index";
import { getFirebaseToken, messaging } from "./FirebaseConfig";
import { Popover, Transition } from "@headlessui/react";
import {
  BellAlertIcon as BellAlertIcon,
  BellIcon as BellIcon,
} from "@heroicons/react/24/outline";
import ButtonPrimary from "shared/Button/ButtonPrimary";


interface NotificationPayloadProps {
  data?: MessagePayload | undefined;
  open: boolean;
}


const RenderNotification = () => {


  const [open, setOpen] = useState(true);

  // To store notification data from firebase
  const [notificationPayload, setNotificationPayload] = useState<
    (NotificationPayloadProps | undefined)[]
  >([]);

  // This is self invoking function that listen of the notification
  const onMessageListener = (async () => {
    const messagingResolve = await messaging;
    if (messagingResolve) {
      onMessage(messagingResolve, (payload: MessagePayload) => {
        setNotificationPayload([{ data: payload, open: true }]);
        setTimeout(() => setNotificationPayload([{ open: false }]), 6000);
      });
    }
  })();

  const handleGetFirebaseToken = () => {
    getFirebaseToken().then((firebaseToken: string | undefined) => {
      if (firebaseToken) {
//        console.log(firebaseToken);
      }
    });
  };

  // Need this handle FCM token generation when a user manually blocks or allows notification
  useEffect(() => {
    if (
      "Notification" in window &&
      window.Notification?.permission === "granted"
    ) {
      handleGetFirebaseToken();
    }
  }, []);



  return (
    <div className="relative z-40 hidden lg:block">
      <div className="fixed left-3 top-28 z-40 flex items-center">
        <Popover className="relative">
          {({ open }) => (
            <>
              <Popover.Button
                className={`p-2.5 bg-white hover:bg-neutral-100 dark:bg-primary-6000 dark:hover:bg-primary-700 rounded-xl shadow-xl border border-neutral-200 dark:border-primary-6000 z-10 focus:outline-none ${
                  open ? " focus:ring-2 ring-primary-500" : ""
                }`}
              >
                <BellAlertIcon className="w-8 h-8" />
              </Popover.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel className="absolute left-0 z-10 mt-3 w-screen max-w-sm">
                  <div className="rounded-2xl bg-white dark:bg-neutral-800 overflow-hidden nc-custom-shadow-1">



                    <div>
      <div className="App ml-6 mb-6">
        {/* Used to notify user whether he has provided notification permissions or not */}
        {"Notification" in window && Notification.permission !== "granted" && (
                    <div className="notification-banner relative p-6">




                        <a
                          className="flex items-center justify-center w-full px-4 py-2 !rounded-xl text-sm font-medium bg-primary-6000 text-white hover:bg-primary-700"
                          href={
                            "#"
                          }
                          onClick={handleGetFirebaseToken} 
                        >
                          <BellIcon className="w-4 h-4" />
                          <span className="ml-2">Autoriser les notifications</span>
                        </a>
 




            <div className="w-full border-b border-neutral-200 dark:border-neutral-700 mt-4"></div>
                    </div>

        )}


{"Notification" in window && Notification.permission === "granted" && (

        <div  className="relative p-6">


        <a
                          className="flex items-center justify-center w-full px-4 py-2 !rounded-xl text-sm font-medium bg-primary-6000 text-white hover:bg-primary-700"
                          href={
                            "#"
                          }
                          onClick={() => {
                            setOpen(true);
                            setTimeout(() => setOpen(true), 6000); 
                          }}
                        >
                          <BellIcon className="w-4 h-4" />
                          <span className="ml-2">Afficher les notifications</span>
                        </a>

        </div>)}

        {/* Rendering  Notification from firebase */}

        {notificationPayload.map((notification) => {
          return (
            <>
              {notification?.open && (
                <div className="notification">
                  <div className="push-notification-title">
                    <h1>{notification?.data?.notification?.title}</h1>
                    <ButtonPrimary
                      className="close-button"
                      onClick={() => 
                        setNotificationPayload([{ open: false }])
                      }
                    >
                      X
                    </ButtonPrimary>




                  </div>
                  <div>
                    <h1 className="push-notification-text">
                     {notification?.data?.notification?.body}
                    </h1>
                  </div>
                </div>
              )}
            </>
          );
        })}

        {/* Rendering Demo Notification */}

        {/*open && (
          <div
            className="notification"
            onClick={() => {
              setOpen(false);
            }}
          >
            <div className="push-notification-title">
              <h1>New Message</h1>
              <button
                className="close-button"
                onClick={() => {
                  setOpen(false);
                }}
              >
                X
              </button>
            </div>
            <div>
              <h1 className="push-notification-text">
                Hello Welcome, Today you will learn how to use
                firebase-notifications
              </h1>
            </div>
          </div>
        )*/}
      </div>

</div>





                  </div>
                </Popover.Panel>
              </Transition>
            </>
          )}
        </Popover>
      </div>
    </div>
  );
};





function App() {



  return (
    <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">


{RenderNotification()}







      <MyRouter />
    </div>
  );
}

export default App;
