import Heading3 from "components/Heading/Heading3";
import Heading from "components/Heading/Heading";
import React from "react";
import NcImage from "shared/NcImage/NcImage";
import  photoPDG1 from"images/pdg1.jpg"
import  photoPDG2 from"images/pdg2.jpg"
import  photoPDG3 from"images/pdg3.jpg"
import  photoPDG4 from"images/pdg4.jpg"

export interface People {
  id: string;
  name: string;
  job: string;
  avatar: string;
}

const FOUNDER_DEMO: People[] = [
  {
    id: "1",
    name: `Abdessamad Ourihane`,
    job: "Directeur Général",
    avatar: photoPDG4,
  },
];

const SectionFounder = () => {
  return (
    <div className="nc-SectionFounder relative">
      <Heading3
        desc="Les activités principales de Tassili Airlines sont les vols Charters pour la société pétrolière Sonatrach et ses filiales, les compagnies pétrolières internationales et le travail aérien à travers sa filiale « Tassili Travail Aérien »."
      >
        ⛱
      </Heading3>
      <Heading3
        desc="Plus récemment, Tassili Airlines a étendu son réseau au service du grand public pour des vols charters internationaux et le transport régulier national et international."
      >
        ⛱
      </Heading3>
      <Heading desc="">
      Dirigeant de la compagnie
      </Heading>
      <div className="grid sm:grid-cols-2 gap-x-5 gap-y-8 lg:grid-cols-4 xl:gap-x-8">
        {FOUNDER_DEMO.map((item) => (
          <div key={item.id} className="max-w-sm">
            <NcImage
              containerClassName="relative h-0 aspect-h-1 aspect-w-1 rounded-xl overflow-hidden"
              className="absolute inset-0 object-cover"
              src={item.avatar}
            />
            <h3 className="text-lg font-semibold text-neutral-900 mt-4 md:text-xl dark:text-neutral-200">
              {item.name}
            </h3>
            <span className="block text-sm text-neutral-500 sm:text-base dark:text-neutral-400">
              {item.job}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SectionFounder;
