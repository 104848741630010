import React, { FC, useState } from "react";
import LocationInput from "../LocationInput";
import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { Fragment } from "react";
import FlightDateRangeInput from "./FlightDateRangeInput";
import { GuestsObject } from "../type";
import NcInputNumber from "components/NcInputNumber/NcInputNumber";

export interface FlightSearchFormProps {}

const flightClass = [
  {
    name: "Économique",
    href: "##",
  },
  {
    name: "Affaires",
    href: "##",
  },
  {
    name: "Première",
    href: "##",
  },
];

export type TypeDropOffLocationType = "Aller-retour" | "Aller simple" | "";

const FlightSearchForm: FC<FlightSearchFormProps> = () => {
  let [dropOffLocationType, setDropOffLocationType] =
    useState<TypeDropOffLocationType>("Aller-retour");
  let [flightClassState, setFlightClassState] = useState("Économique");

  let [guestAdultsInputValue, setGuestAdultsInputValue] = useState(1);
  let [guestChildrenInputValue, setGuestChildrenInputValue] = useState(0);
  let [guestInfantsInputValue, setGuestInfantsInputValue] = useState(0);

  const handleChangeData = (value: number, type: keyof GuestsObject) => {
    let newValue = {
      Adultes: guestAdultsInputValue,
      Enfant: guestChildrenInputValue,
      Bébé: guestInfantsInputValue,
    };
    if (type === "guestAdults") {
      setGuestAdultsInputValue(value);
      guestAdultsInputValue=value;
      newValue.Adultes = value;
    }
    if (type === "guestChildren") {
      setGuestChildrenInputValue(value);
      guestChildrenInputValue=value;
      newValue.Enfant = value;
    }
    if (type === "guestInfants") {
      setGuestInfantsInputValue(value);
      guestInfantsInputValue=value;
      newValue.Bébé = value;
    }
  };

  const totalGuests =
    guestChildrenInputValue + guestAdultsInputValue + guestInfantsInputValue;

  const renderGuest = () => {
    return (
      <Popover className="relative">
        {({ open }) => (
          <>
            <Popover.Button
              as="button"
              className={`
           ${open ? "" : ""}
            px-4 py-1.5 rounded-md inline-flex items-center font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 text-xs`}
              onClickCapture={() => document.querySelector("html")?.click()}
            >
              <span>{`${totalGuests || ""} Passagers`}</span>
              <ChevronDownIcon
                className={`${
                  open ? "" : "text-opacity-70"
                } ml-2 h-4 w-4 group-hover:text-opacity-80 transition ease-in-out duration-150`}
                aria-hidden="true"
              />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-20 w-full sm:min-w-[340px] max-w-sm bg-white dark:bg-neutral-800 top-full mt-3 left-1/2 -translate-x-1/2  py-5 sm:py-6 px-4 sm:px-8 rounded-3xl shadow-xl ring-1 ring-black/5 dark:ring-white/10">
                <NcInputNumber
                  className="w-full"
                  defaultValue={guestAdultsInputValue}
                  onChange={(value) => handleChangeData(value, "guestAdults")}
                  max={7}
                  min={1}
                  label="Adultes"
                  desc="12 ans et plus"
                />
                <NcInputNumber
                  className="w-full mt-6"
                  defaultValue={guestChildrenInputValue}
                  onChange={(value) => handleChangeData(value, "guestChildren")}
                  max={7}
                  label="Enfant"
                  desc="2 à 11 ans"
                />

                <NcInputNumber
                  className="w-full mt-6"
                  defaultValue={guestInfantsInputValue}
                  onChange={(value) => handleChangeData(value, "guestInfants")}
                  max={2}
                  label="Bébé"
                  desc="Moins de 2 ans"
                />
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const renderSelectClass = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`
           ${open ? "" : ""}
            px-4 py-1.5 rounded-md inline-flex items-center font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 text-xs`}
              onClickCapture={() => document.querySelector("html")?.click()}
            >
              <span>{`${flightClassState}`}</span>
              <ChevronDownIcon
                className={`${
                  open ? "" : "text-opacity-70"
                } ml-2 h-4 w-4 group-hover:text-opacity-80 transition ease-in-out duration-150`}
                aria-hidden="true"
              />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-20 w-screen max-w-[200px] sm:max-w-[220px] px-4 top-full mt-3 transform -translate-x-1/2 left-1/2 sm:px-0  ">
                <div className="overflow-hidden rounded-2xl shadow-lg ring-1 ring-black/5 dark:ring-white/10 ">
                  <div className="relative grid gap-8 bg-white dark:bg-neutral-800 p-7 ">
                    {flightClass.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        onClick={(e) => {
                          e.preventDefault();
                          setFlightClassState(item.name);
                          close();
                        }}
                        className="flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                      >
                        <p className="text-sm font-medium ">{item.name}</p>
                      </a>
                    ))}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const renderRadioBtn = () => {
    return (
      <div className=" py-5 [ nc-hero-field-padding ] flex flex-row flex-wrap border-b border-neutral-100 dark:border-neutral-700">
        <div
          className={`py-1.5 px-4 flex items-center rounded-full font-medium text-xs cursor-pointer mr-2 my-1 sm:mr-3 ${
            dropOffLocationType === "Aller-retour"
              ? "bg-black shadow-black/10 shadow-lg text-white"
              : "border border-neutral-300 dark:border-neutral-700"
          }`}
          onClick={(e) => setDropOffLocationType("Aller-retour")}
        >
          Aller-retour
        </div>
        <div
          className={`py-1.5 px-4 flex items-center rounded-full font-medium text-xs cursor-pointer mr-2 my-1 sm:mr-3 ${
            dropOffLocationType === "Aller simple"
              ? "bg-black text-white shadow-black/10 shadow-lg"
              : "border border-neutral-300 dark:border-neutral-700"
          }`}
          onClick={(e) => setDropOffLocationType("Aller simple")}
        >
          Aller simple
        </div>

        <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8 mr-2 my-1 sm:mr-3"></div>

        <div className="mr-2 my-1 sm:mr-3 border border-neutral-300 dark:border-neutral-700 rounded-full">
          {renderSelectClass()}
        </div>
        <div className="my-1 border border-neutral-300 dark:border-neutral-700 rounded-full">
          {renderGuest()}
        </div>
      </div>
    );
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
}

let [startLocation, setStartLocation] = useState("");

const getStartLocation = (strLoc:string) => {
  setStartLocation(strLoc);
  startLocation=strLoc;
  
  console.log(`location: ${startLocation}`);
};

let [endLocation, setEndLocation] = useState("");

const getEndLocation = (strLocEnd:string) => {
  setEndLocation(strLocEnd);
  endLocation=strLocEnd;
  console.log(`location: ${endLocation}`);
};

  const renderForm = () => {
    return (
      <form onSubmit={e => handleSubmit(e)} className="w-full relative mt-8 rounded-[40px] xl:rounded-[49px] rounded-t-2xl xl:rounded-t-3xl shadow-xl dark:shadow-2xl bg-white dark:bg-neutral-800">
        {renderRadioBtn()}
        <div className="flex flex-1 rounded-full">
          <LocationInput
            placeHolder="Aéroport de départ"
            desc="D'où voulez-vous patir?"
            className="flex-1"
            onChange={getStartLocation}
          />
          <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>
          <LocationInput
            placeHolder="Aéroport d'arrivée"
            desc="Où voulez-vous aller?"
            className="flex-1"
            divHideVerticalLineClass=" -inset-x-0.5"
            onChange={getEndLocation}
            invicon
          />
          <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>
          <FlightDateRangeInput
            selectsRange={dropOffLocationType !== "Aller simple"}
            className="flex-1"
            startLoc={startLocation}
            endLoc={endLocation}
            flyType={dropOffLocationType}
            flyClass={flightClassState}
            adultInputValue={guestAdultsInputValue}
            childrenInputValue={guestChildrenInputValue}
            infantsInputValue={guestInfantsInputValue}
          />
        </div>
      </form>
    );
  };

  return renderForm();
};

export default FlightSearchForm;
