import { Tab } from "@headlessui/react";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import React, { FC, Fragment, useState } from "react";
import TabFilters from "./TabFilters";
import Heading2 from "components/Heading/Heading2";
import FlightCard, { FlightCardProps } from "components/FlightCard/FlightCard";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import visaPng from "images/vis.png";
import mastercardPng from "images/mastercard.svg";
import NcModal from "shared/NcModal/NcModal";
import ModalSelectDate from "components/ModalSelectDate";
import converSelectedDateToString from "utils/converSelectedDateToString";
import ModalSelectGuests from "components/ModalSelectGuests";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import { GuestsObject } from "components/HeroSearchForm/type";
import StartRating from "components/StartRating/StartRating";
import iatalogoheader from "images/iatalogoheader.svg"
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import Checkbox from "shared/Checkbox/Checkbox";
import { Popover, Transition } from "@headlessui/react";
import  IconAirplaneTicket  from "data/iconairplaneticket";
import miniLogoPng from "images/mini-logo.png";
import MicroForm from "containers/CheckOutPage/MicroForm";
import {
  ShoppingBagIcon as ShoppingCartIcon,
  Cog8ToothIcon as CogIcon,
} from "@heroicons/react/24/outline";
import Icon from '@mdi/react';
import { mdiAirplaneTakeoff,mdiAirplaneLanding,mdiAirplane,mdiBagChecked,mdiAccountTie,mdiAccountTieWoman } from '@mdi/js';

export interface SectionGridFilterCardProps {
  className?: string;
  srchResult?: string;
}







/*
const DEMO_DATA: FlightCardProps["data"][] = [
  {
    id: "1",
    price: "$4,100",
    airlines: {
      logo: "https://www.gstatic.com/flights/airline_logos/70px/KE.png",
      name: "Korean Air",
    },
  },
  {
    id: "2",
    price: "$3,380",
    airlines: {
      logo: "https://www.gstatic.com/flights/airline_logos/70px/SQ.png",
      name: "Singapore Airlines",
    },
  },
  {
    id: "3",
    price: "$2,380",
    airlines: {
      logo: "https://www.gstatic.com/flights/airline_logos/70px/multi.png",
      name: "Philippine Airlines",
    },
  },
  {
    id: "1",
    price: "$4,100",
    airlines: {
      logo: "https://www.gstatic.com/flights/airline_logos/70px/KE.png",
      name: "Korean Air",
    },
  },
  {
    id: "2",
    price: "$3,380",
    airlines: {
      logo: "https://www.gstatic.com/flights/airline_logos/70px/SQ.png",
      name: "Singapore Airlines",
    },
  },
  {
    id: "1",
    price: "$4,100",
    airlines: {
      logo: "https://www.gstatic.com/flights/airline_logos/70px/KE.png",
      name: "Korean Air",
    },
  },
  {
    id: "2",
    price: "$3,380",
    airlines: {
      logo: "https://www.gstatic.com/flights/airline_logos/70px/SQ.png",
      name: "Singapore Airlines",
    },
  },
];
*/

const SectionGridFilterCard: FC<SectionGridFilterCardProps> = ({
  className = "", srchResult="",
}) => {

  const options = {
    // passing the client secret obtained from the server
    clientSecret: 'pi_3OoWrpHw7aj427hO1eRUd8mx_secret_5NKegwBTfCcjyBFfSWW8bgJyf',
  };

  const [isOpen, setIsOpen] = React.useState(0);
  const [isManOrWomen, setIsManOrWomen] = React.useState(1);

  const [startDate] = useState<Date | null>(new Date("2023/02/06"));
  const [endDate] = useState<Date | null>(new Date("2023/02/23"));

  const [guests] = useState<GuestsObject>({
    guestAdults: 2,
    guestChildren: 1,
    guestInfants: 1,
  });

  const [activeDepartIndex,setActiveDepartIndex] = React.useState(null);

  const [activeRetourIndex,setActiveRetourIndex] = React.useState(null);



  const [scrollPosition, setScrollPosition] = React.useState(0);
const handleScroll = () => {
    const position = window.pageYOffset;
//    console.log(position);
    setScrollPosition(position);
};

React.useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
}, []);

let [ infoVolAllee, setInfoVolAllee] = React.useState<string>('Selectionnez votre vol allée!');
let [ infoVolRetour, setInfoVolRetour] = React.useState<string>('Selectionnez votre vol retour!');
let [ totalPrice, setTotalPrice]=React.useState<string>('');

let [ departInfo, setDepartInfo]=React.useState<any>(null);

let [ retourInfo, setRetourInfo]=React.useState<any>(null);

let [ departValide, setDepartValide]=React.useState<boolean>(false);


//let [ infoDapert, setInfoDepart ] =  React.useState<boolean>(false);


function SetInfoVolAllee(strInfoVol:string,index:any)
{
if (strInfoVol==="commande") {
//  setReservationStep(index);
if (departValide) {
  setDepartValide(false);
  departValide=false;
} else {
  setDepartValide(true);
  departValide=true;
}
  return;
}


console.log(strInfoVol);

  setActiveDepartIndex(index);

  setInfoVolAllee(strInfoVol);
  infoVolAllee = strInfoVol;

  setDepartInfo(JSON.parse(strInfoVol));
  departInfo=JSON.parse(strInfoVol);


let priceAller=departInfo.fare+" "+departInfo.currency;

console.log("Price:"+priceAller);

if (infoVolRetour!=="Selectionnez votre vol retour!") {
  setRetourInfo(JSON.parse(infoVolRetour));
 retourInfo= JSON.parse(infoVolRetour);

let priceRetour=retourInfo.fare+" "+retourInfo.currency;
let priceTotal=(parseFloat(priceAller.split(' ')[0])+parseFloat(priceRetour.split(' ')[0]))+" "+priceAller.split(' ')[1];

setTotalPrice(priceTotal);
totalPrice=priceTotal;

localStorage.priceTotal=priceTotal;
localStorage.infoVolAllee=infoVolAllee;
localStorage.infoVolRetour=infoVolRetour;

setTotalPrice(priceTotal);
}

}

let [ reservationStep, setReservationStep ]=React.useState<number>(1);

let [ retourValide, setRetourValide]=React.useState<boolean>(false);



function SetInfoVolRetour(strInfoVol:string,index:any)
{
  if (strInfoVol==="commande") {
    //  setReservationStep(index);
if (retourValide) {
    setRetourValide(false);
      retourValide=false;
} else
{
  setRetourValide(true);
    retourValide=true;
}
      return;
    }

  setActiveRetourIndex(index);

  setInfoVolRetour(strInfoVol);
  infoVolRetour = strInfoVol;


  setDepartInfo(JSON.parse(infoVolAllee));
  departInfo=JSON.parse(infoVolAllee);


  setRetourInfo(JSON.parse(strInfoVol));
  retourInfo=JSON.parse(strInfoVol);



let priceAller=departInfo.fare+" "+departInfo.currency;
let priceRetour=retourInfo.fare+" "+retourInfo.currency; 
let priceTotal=(parseFloat(priceAller.split(' ')[0])+parseFloat(priceRetour.split(' ')[0]))+" "+priceAller.split(' ')[1];

console.log("total:"+priceAller+"+"+priceRetour+"="+priceTotal);


localStorage.priceTotal=priceTotal;
localStorage.infoVolAllee=infoVolAllee;
localStorage.infoVolRetour=infoVolRetour;

setTotalPrice(priceTotal);

}

if ((srchResult==="erreur1")||(srchResult==="erreur2")||(srchResult==="erreur3")||(srchResult==="erreur4")||(srchResult==="erreur5")) return null;


  if (srchResult==="") return null;

  const rsltData=JSON.parse(srchResult);


  let strCodeAirpDep:string;
  strCodeAirpDep="";
  let strCodeAirpArv:string;
  strCodeAirpArv="";
  let strOrigin_rcd:string;
  strOrigin_rcd="";
  let strDestination_rcd:string;
  strDestination_rcd="";  


  rsltData[0].forEach((rslt:any) => {

      if (rslt.fareOfFlights[0]!=null) { strCodeAirpDep=  rslt.fareOfFlights[0].departure_airport_name; 
        strCodeAirpArv=  rslt.fareOfFlights[0].arrival_airport_name; 
        strOrigin_rcd=  rslt.fareOfFlights[0].origin_rcd; 
        strDestination_rcd=  rslt.fareOfFlights[0].destination_rcd;       
      }

  });





const renderPopSidebarDepart = () => {
  if (!departValide) return;
  return (
<div>
                  <div className="flex justify-between font-semibold flex">
                  <div className="flex font-semibold "><span>{strCodeAirpDep}</span><span className="mx-1"> </span><Icon path={mdiAirplane} rotate={45} size={0.8}/><span className="mx-1"> </span><span>{strCodeAirpArv}</span></div>
                </div>
                <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                  <span>{departInfo.departure_date}</span>
                </div>
    
                <span
              className={`absolute right-10 top-[150px] sm:bottom-auto sm:top-[150px] sm:-translate-y-1/2 w-10 h-10 bg-neutral-50 dark:bg-neutral-800 rounded-full flex items-center justify-center cursor-pointer ${
                isOpen!==1 ? "" : "transform -rotate-90"
              }`}
              onClick={() => setIsOpen(isOpen===1?0:1)}
            >
              <i className="text-xl las la-angle-down"></i>
            </span>
    
    
            <div className={isOpen===1?"flex my-5 md:my-0":"flex my-5 md:my-0 hidden"}>
                <div className="flex-shrink-0 flex flex-col items-center py-2">
                  <span className="block w-6 h-6 rounded-full border border-neutral-400"></span>
                  <span className="block flex-grow border-l border-neutral-400 border-dashed my-1">
                  </span>
    
                  <span className="absolute w-25 top-[260px] flex">
                <img src={miniLogoPng} className="w-10" alt="" />
                </span>
    
                <span className="absolute w-25 top-[240px] right-[120px] flex text-left items-left">
                  <span className="flex flex-col space-y-1">
                  <span>Vol n° {departInfo.airline_rcd+" "+departInfo.flight_number}</span>
                  <span>Sur un {departInfo.aircraft_rcd}</span>
                  <span>durée : {departInfo.duration}</span>
                  </span>
                </span>
    
                  <span className="block w-6 h-6 rounded-full border border-neutral-400"></span>
                </div>
                <div className="ml-4 space-y-28 text-sm">
                  <div className="flex flex-col space-y-1">
                    <span className=" text-neutral-500 dark:text-neutral-400">
                      
                    {departInfo.planned_departure_time}
                    </span>
                    <span className=" font-semibold flex">
                    <Icon path={mdiAirplaneTakeoff} size={1} />{departInfo.departure_airport_name+" ("+departInfo.origin_rcd+")"}
                    </span>
                  </div>
                  <div className="flex flex-col space-y-1">
                    <span className=" text-neutral-500 dark:text-neutral-400">
                      {departInfo.planned_arrival_time}
                    </span>
                    <span className=" font-semibold flex">
                    <Icon path={mdiAirplaneLanding} size={1} />{departInfo.arrival_airport_name+" ("+departInfo.destination_rcd+")"}
                    </span>
                  </div>
                </div>
    
    
    
              </div>
    
    
    
    <div className={isOpen===1?"":"hidden"}>
    <div className="w-10 border-b border-neutral-200  dark:border-neutral-700"></div>
              <div className={departInfo.dropOffFlightType!=="Économique Tassili Flex"?"hidden":"space-y-1"}><h1>Classe {departInfo.dropOffFlightType}</h1>
              <ul className="text-sm text-neutral-500 dark:text-neutral-400 space-y-1 md:space-y-2">
                <li className="flex"> <span className="w-25 h-25" >
                <svg aria-hidden="true" width="24" height="24"><g fill="#9099a2"><path d="M20.523 2.947h-2.529v-1.5a.5.5 0 1 0-1 0v1.5H7.018v-1.5a.5.5 0 1 0-1 0v1.5H3.477a2.5 2.5 0 0 0-2.5 2.5v15.042a2.5 2.5 0 0 0 2.5 2.5h17.046a2.5 2.5 0 0 0 2.5-2.5V5.447a2.5 2.5 0 0 0-2.5-2.5zm-17.046 1h2.541v1.5a.5.5 0 0 0 1 0v-1.5h9.976v1.5a.5.5 0 0 0 1 0v-1.5h2.529a1.5 1.5 0 0 1 1.5 1.5v1.478H1.977V5.447a1.5 1.5 0 0 1 1.5-1.5zm17.046 18.042H3.477a1.5 1.5 0 0 1-1.5-1.5V7.925h20.046v12.564a1.5 1.5 0 0 1-1.5 1.5z"></path><path d="M14.021 10.856a.5.5 0 0 0-.123.7l1.947 2.781H7.229a.5.5 0 0 0 0 1h8.563l-1.9 2.787a.5.5 0 1 0 .827.563l2.656-3.9-2.66-3.8a.5.5 0 0 0-.694-.131z"></path></g></svg>
                  </span><span className="py-1 px-2">Flexibilité de pouvoir faire 1 modification</span></li>
                  <li className="flex"> <span className="w-25 h-25" >
                  <svg aria-hidden="true" width="24" height="24"><g fill="#9099a2"><path d="M22.862 1.101a.5.5 0 0 0-.707 0L1.137 22.118a.5.5 0 0 0 .354.854.5.5 0 0 0 .353-.147L22.862 1.808a.5.5 0 0 0 0-.707z"></path><path d="M23.023 5.876a.5.5 0 0 0-1 0v4.161a2.042 2.042 0 0 0 0 3.958v4.542a.5.5 0 0 1-.5.5H8.227a.5.5 0 1 0 0 1h13.3a1.5 1.5 0 0 0 1.5-1.5v-5.48h-.5a1.041 1.041 0 0 1 0-2.081h.5V5.888z"></path><path d="M1.506 18.741a.5.5 0 0 0 .5-.5v-4.246a2.041 2.041 0 0 0 0-3.958V5.591a.5.5 0 0 1 .5-.5h3.518V13.681a.5.5 0 0 0 1 0v-8.59h8.541a.5.5 0 1 0 0-1H2.506a1.5 1.5 0 0 0-1.5 1.5v5.385h.5a1.041 1.041 0 1 1 0 2.081h-.5v5.184a.5.5 0 0 0 .5.5z"></path></g></svg>
                  </span><span className="py-1 px-2">Annulation sous 24 heures sans frais</span></li>
    
                  <li className="flex"> <span className="w-25 h-25" >
                  <svg aria-hidden="true" width="24" height="24" viewBox="0 0 24 24"><g className="nc-icon-wrapper"><defs><clipPath id="b_cbg"><path d="M0 0h24v24H0z"></path></clipPath></defs><g id="a_cbg" clip-path="url(#b_cbg)"><g transform="translate(1.021 .972)"><path d="M20.428 3.906h-4.456V2a.989.989 0 0 0-.989-.987H8.956A.988.988 0 0 0 7.968 2v1.91H3.519A2.5 2.5 0 0 0 1.021 6.4v12.08a2.5 2.5 0 0 0 2.5 2.493H4.1a1.452 1.452 0 0 0-.1.527 1.476 1.476 0 0 0 2.951 0 1.452 1.452 0 0 0-.1-.528h10.304a1.452 1.452 0 0 0-.1.528 1.476 1.476 0 1 0 2.849-.528h.526a2.5 2.5 0 0 0 2.5-2.493V6.4a2.5 2.5 0 0 0-2.502-2.494zM5.473 21.976a.475.475 0 1 1 .475-.475.476.476 0 0 1-.475.475zm13.055 0A.475.475 0 1 1 19 21.5a.475.475 0 0 1-.472.476zM8.956 2.008L14.972 2v1.91H8.965zm12.969 16.471a1.5 1.5 0 0 1-1.5 1.495H3.519a1.5 1.5 0 0 1-1.5-1.495V6.4a1.5 1.5 0 0 1 1.5-1.5h16.909a1.5 1.5 0 0 1 1.5 1.495z" transform="translate(-1.021 -1.009)" fill="#9099a2"></path><path d="M7.971 7.994a.5.5 0 0 0-.5.5v7.957a.5.5 0 1 0 1 0V8.494a.5.5 0 0 0-.5-.5z" transform="translate(-1.021 -1.026)" fill="#9099a2"></path><path d="M11.953 7.994a.5.5 0 0 0-.5.5v7.957a.5.5 0 0 0 1 0V8.494a.5.5 0 0 0-.5-.5z" transform="translate(-1.021 -1.026)" fill="#9099a2"></path><path d="M16.029 7.994a.5.5 0 0 0-.5.5v7.957a.5.5 0 1 0 1 0V8.494a.5.5 0 0 0-.5-.5z" transform="translate(-1.021 -1.026)" fill="#9099a2"></path></g></g></g></svg>
                  </span><span className="py-1 px-2">Bagages enregistrés : 1 pièces, 20 kg</span></li>
    
                  <li className="flex"> <span className="w-25 h-25" >
                  <svg aria-hidden="true" width="24" height="24" viewBox="0 0 24 24"><g className="nc-icon-wrapper"><defs><clipPath id="b_hb"><path d="M0 0h24v24H0z"></path></clipPath></defs><g id="a_hb" clip-path="url(#b_hb)"><path d="M21.509 4.683h-5.341V3.2a1.488 1.488 0 0 0-1.487-1.49H9.273A1.487 1.487 0 0 0 7.788 3.2v1.483H2.49a1.475 1.475 0 0 0-1.474 1.474v12.376a1.506 1.506 0 0 0 1.523 1.486h18.933A1.507 1.507 0 0 0 23 18.533V6.169a1.488 1.488 0 0 0-1.491-1.486zM8.788 3.2a.486.486 0 0 1 .485-.486h5.408a.487.487 0 0 1 .487.486v1.483h-6.38zm12.684 15.819H2.539a.506.506 0 0 1-.523-.486v-6.647a1.476 1.476 0 0 0 .5.092h7.5v1.04a1.974 1.974 0 0 0 3.948 0v-1.04H21.5a1.483 1.483 0 0 0 .5-.092v6.647a.507.507 0 0 1-.528.486zm-10.46-6v-2.53h1.948v2.529a.974.974 0 0 1-1.948 0zM22 10.478a.5.5 0 0 1-.5.5h-7.54V9.489h-3.948v1.489h-7.5a.5.5 0 0 1-.5-.5V6.157a.475.475 0 0 1 .474-.474h19.023a.493.493 0 0 1 .491.486v4.309z" transform="translate(0 .016)" fill="#9099a2"></path></g></g></svg>
                  </span><span className="py-1 px-2">Bagages à main : 1 pièces, 10 kg</span></li>
    
                  <li className="flex"> <span className="w-25 h-25" >
                  <svg aria-hidden="true" width="24" height="24" viewBox="0 0 24 24"><g className="nc-icon-wrapper"><defs><clipPath id="b_lo"><path d="M0 0h24v24H0z"></path></clipPath></defs><g id="a_lo" clip-path="url(#b_lo)"><path d="M19.777 9.971v-7.71A1.446 1.446 0 0 0 18.2.955H5.784a1.445 1.445 0 0 0-1.578 1.306v7.69a3.01 3.01 0 0 0-.977 5.849v5.686a1.5 1.5 0 0 0 1.5 1.5h14.558a1.494 1.494 0 0 0 1.484-1.5v-5.664a3.011 3.011 0 0 0-.994-5.851zM4.731 21.987a.5.5 0 0 1-.5-.5v-6.475l-.4-.081a2.013 2.013 0 1 1 2.418-1.986v9.042zm2.518 0v-5.948h9.509l.011 5.948zm9.5-9.016v2.069h-9.5v-2.1a3 3 0 0 0-2.041-2.816V2.261c0-.122.23-.308.578-.308H18.2c.348 0 .579.186.579.308v7.89a3.012 3.012 0 0 0-2.026 2.82zm3.419 1.983l-.4.082v6.454a.488.488 0 0 1-.484.5h-1.516l-.015-9.019a2.016 2.016 0 1 1 2.419 1.981z" transform="translate(0 .016)" fill="#9099a2"></path></g></g></svg>
                  </span><span className="py-1 px-2">Sélection de siège incluse</span></li>
    
    
              </ul>
              </div>
    
              <div className={departInfo.dropOffFlightType!=="Business Tassili Flex"?"hidden":"space-y-1"}><h1>Classe {departInfo.dropOffFlightType}</h1>
              <ul className="text-sm text-neutral-500 dark:text-neutral-400 space-y-1 md:space-y-2">
                <li className="flex"> <span className="w-25 h-25" >
                <svg aria-hidden="true" width="24" height="24"><g fill="#9099a2"><path d="M20.523 2.947h-2.529v-1.5a.5.5 0 1 0-1 0v1.5H7.018v-1.5a.5.5 0 1 0-1 0v1.5H3.477a2.5 2.5 0 0 0-2.5 2.5v15.042a2.5 2.5 0 0 0 2.5 2.5h17.046a2.5 2.5 0 0 0 2.5-2.5V5.447a2.5 2.5 0 0 0-2.5-2.5zm-17.046 1h2.541v1.5a.5.5 0 0 0 1 0v-1.5h9.976v1.5a.5.5 0 0 0 1 0v-1.5h2.529a1.5 1.5 0 0 1 1.5 1.5v1.478H1.977V5.447a1.5 1.5 0 0 1 1.5-1.5zm17.046 18.042H3.477a1.5 1.5 0 0 1-1.5-1.5V7.925h20.046v12.564a1.5 1.5 0 0 1-1.5 1.5z"></path><path d="M14.021 10.856a.5.5 0 0 0-.123.7l1.947 2.781H7.229a.5.5 0 0 0 0 1h8.563l-1.9 2.787a.5.5 0 1 0 .827.563l2.656-3.9-2.66-3.8a.5.5 0 0 0-.694-.131z"></path></g></svg>
                  </span><span className="py-1 px-2">Flexibilité de pouvoir faire des modifications</span></li>
                  <li className="flex"> <span className="w-25 h-25" >
                  <svg aria-hidden="true" width="24" height="24"><g fill="#9099a2"><path d="M22.862 1.101a.5.5 0 0 0-.707 0L1.137 22.118a.5.5 0 0 0 .354.854.5.5 0 0 0 .353-.147L22.862 1.808a.5.5 0 0 0 0-.707z"></path><path d="M23.023 5.876a.5.5 0 0 0-1 0v4.161a2.042 2.042 0 0 0 0 3.958v4.542a.5.5 0 0 1-.5.5H8.227a.5.5 0 1 0 0 1h13.3a1.5 1.5 0 0 0 1.5-1.5v-5.48h-.5a1.041 1.041 0 0 1 0-2.081h.5V5.888z"></path><path d="M1.506 18.741a.5.5 0 0 0 .5-.5v-4.246a2.041 2.041 0 0 0 0-3.958V5.591a.5.5 0 0 1 .5-.5h3.518V13.681a.5.5 0 0 0 1 0v-8.59h8.541a.5.5 0 1 0 0-1H2.506a1.5 1.5 0 0 0-1.5 1.5v5.385h.5a1.041 1.041 0 1 1 0 2.081h-.5v5.184a.5.5 0 0 0 .5.5z"></path></g></svg>
                  </span><span className="py-1 px-2">Annulation sans frais</span></li>
    
                  <li className="flex"> <span className="w-25 h-25" >
                  <svg aria-hidden="true" width="24" height="24" viewBox="0 0 24 24"><g className="nc-icon-wrapper"><defs><clipPath id="b_cbg"><path d="M0 0h24v24H0z"></path></clipPath></defs><g id="a_cbg" clip-path="url(#b_cbg)"><g transform="translate(1.021 .972)"><path d="M20.428 3.906h-4.456V2a.989.989 0 0 0-.989-.987H8.956A.988.988 0 0 0 7.968 2v1.91H3.519A2.5 2.5 0 0 0 1.021 6.4v12.08a2.5 2.5 0 0 0 2.5 2.493H4.1a1.452 1.452 0 0 0-.1.527 1.476 1.476 0 0 0 2.951 0 1.452 1.452 0 0 0-.1-.528h10.304a1.452 1.452 0 0 0-.1.528 1.476 1.476 0 1 0 2.849-.528h.526a2.5 2.5 0 0 0 2.5-2.493V6.4a2.5 2.5 0 0 0-2.502-2.494zM5.473 21.976a.475.475 0 1 1 .475-.475.476.476 0 0 1-.475.475zm13.055 0A.475.475 0 1 1 19 21.5a.475.475 0 0 1-.472.476zM8.956 2.008L14.972 2v1.91H8.965zm12.969 16.471a1.5 1.5 0 0 1-1.5 1.495H3.519a1.5 1.5 0 0 1-1.5-1.495V6.4a1.5 1.5 0 0 1 1.5-1.5h16.909a1.5 1.5 0 0 1 1.5 1.495z" transform="translate(-1.021 -1.009)" fill="#9099a2"></path><path d="M7.971 7.994a.5.5 0 0 0-.5.5v7.957a.5.5 0 1 0 1 0V8.494a.5.5 0 0 0-.5-.5z" transform="translate(-1.021 -1.026)" fill="#9099a2"></path><path d="M11.953 7.994a.5.5 0 0 0-.5.5v7.957a.5.5 0 0 0 1 0V8.494a.5.5 0 0 0-.5-.5z" transform="translate(-1.021 -1.026)" fill="#9099a2"></path><path d="M16.029 7.994a.5.5 0 0 0-.5.5v7.957a.5.5 0 1 0 1 0V8.494a.5.5 0 0 0-.5-.5z" transform="translate(-1.021 -1.026)" fill="#9099a2"></path></g></g></g></svg>
                  </span><span className="py-1 px-2">Bagages enregistrés : 1 pièces, 30 kg</span></li>
    
                  <li className="flex"> <span className="w-25 h-25" >
                  <svg aria-hidden="true" width="24" height="24" viewBox="0 0 24 24"><g className="nc-icon-wrapper"><defs><clipPath id="b_hb"><path d="M0 0h24v24H0z"></path></clipPath></defs><g id="a_hb" clip-path="url(#b_hb)"><path d="M21.509 4.683h-5.341V3.2a1.488 1.488 0 0 0-1.487-1.49H9.273A1.487 1.487 0 0 0 7.788 3.2v1.483H2.49a1.475 1.475 0 0 0-1.474 1.474v12.376a1.506 1.506 0 0 0 1.523 1.486h18.933A1.507 1.507 0 0 0 23 18.533V6.169a1.488 1.488 0 0 0-1.491-1.486zM8.788 3.2a.486.486 0 0 1 .485-.486h5.408a.487.487 0 0 1 .487.486v1.483h-6.38zm12.684 15.819H2.539a.506.506 0 0 1-.523-.486v-6.647a1.476 1.476 0 0 0 .5.092h7.5v1.04a1.974 1.974 0 0 0 3.948 0v-1.04H21.5a1.483 1.483 0 0 0 .5-.092v6.647a.507.507 0 0 1-.528.486zm-10.46-6v-2.53h1.948v2.529a.974.974 0 0 1-1.948 0zM22 10.478a.5.5 0 0 1-.5.5h-7.54V9.489h-3.948v1.489h-7.5a.5.5 0 0 1-.5-.5V6.157a.475.475 0 0 1 .474-.474h19.023a.493.493 0 0 1 .491.486v4.309z" transform="translate(0 .016)" fill="#9099a2"></path></g></g></svg>
                  </span><span className="py-1 px-2">Bagages à main : 1 pièces, 10 kg</span></li>
    
                  <li className="flex"> <span className="w-25 h-25" >
                  <svg aria-hidden="true" width="24" height="24" viewBox="0 0 24 24"><g className="nc-icon-wrapper"><defs><clipPath id="b_lo"><path d="M0 0h24v24H0z"></path></clipPath></defs><g id="a_lo" clip-path="url(#b_lo)"><path d="M19.777 9.971v-7.71A1.446 1.446 0 0 0 18.2.955H5.784a1.445 1.445 0 0 0-1.578 1.306v7.69a3.01 3.01 0 0 0-.977 5.849v5.686a1.5 1.5 0 0 0 1.5 1.5h14.558a1.494 1.494 0 0 0 1.484-1.5v-5.664a3.011 3.011 0 0 0-.994-5.851zM4.731 21.987a.5.5 0 0 1-.5-.5v-6.475l-.4-.081a2.013 2.013 0 1 1 2.418-1.986v9.042zm2.518 0v-5.948h9.509l.011 5.948zm9.5-9.016v2.069h-9.5v-2.1a3 3 0 0 0-2.041-2.816V2.261c0-.122.23-.308.578-.308H18.2c.348 0 .579.186.579.308v7.89a3.012 3.012 0 0 0-2.026 2.82zm3.419 1.983l-.4.082v6.454a.488.488 0 0 1-.484.5h-1.516l-.015-9.019a2.016 2.016 0 1 1 2.419 1.981z" transform="translate(0 .016)" fill="#9099a2"></path></g></g></svg>
                  </span><span className="py-1 px-2">Sélection de siège incluse</span></li>
    
    
              </ul>
              </div>
    </div>
    
    
    
    
    
    
    
    
    
    
    </div>
    
  );
}

const renderPopSidebarRetour = () => {
  if (!retourValide || !rsltData[1]) return;
return(
  <div>
  <div className="flex justify-between font-semibold flex">
    <div className="flex font-semibold "><span>{strCodeAirpArv}</span><span className="mx-1"> </span><Icon path={mdiAirplane} rotate={45} size={0.8}/><span className="mx-1"> </span><span>{strCodeAirpDep}</span></div>
  </div>
  <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
    <span>{retourInfo.departure_date}</span>
  </div>





  <span
className={`absolute right-10  sm:bottom-auto sm:-translate-y-1/2 w-10 h-10 bg-neutral-50 dark:bg-neutral-800 rounded-full flex items-center justify-center cursor-pointer ${
  (isOpen!==2)? "top-[210px] sm:top-[210px]"+(isOpen===1?"top-[625px] sm:top-[625px]":"") : "top-[210px] sm:top-[210px] transform -rotate-90"
}`}
onClick={() => setIsOpen(isOpen===2?0:2)}
>
<i className="text-xl las la-angle-down"></i>
</span>


<div className={isOpen===2?"flex my-5 md:my-0":"flex my-5 md:my-0 hidden"}>
  <div className="flex-shrink-0 flex flex-col items-center py-2">
    <span className="block w-6 h-6 rounded-full border border-neutral-400"></span>
    <span className="block flex-grow border-l border-neutral-400 border-dashed my-1">
    </span>

    <span className="absolute w-25 top-[320px] flex">
  <img src={miniLogoPng} className="w-10" alt="" />
  </span>

  <span className="absolute w-25 top-[300px] right-[120px] flex text-left items-left">
    <span className="flex flex-col space-y-1">
    <span>Vol n° {retourInfo.airline_rcd+" "+retourInfo.flight_number}</span>
    <span>Sur un {retourInfo.aircraft_rcd}</span>
    <span>durée : {retourInfo.duration}</span>
    </span>
  </span>

    <span className="block w-6 h-6 rounded-full border border-neutral-400"></span>
  </div>
  <div className="ml-4 space-y-28 text-sm">
    <div className="flex flex-col space-y-1">
      <span className=" text-neutral-500 dark:text-neutral-400">
        
      {retourInfo.planned_departure_time}
      </span>
      <span className=" font-semibold flex">
      <Icon path={mdiAirplaneTakeoff} size={1} />{retourInfo.departure_airport_name+" ("+retourInfo.origin_rcd+")"}
      </span>
    </div>
    <div className="flex flex-col space-y-1">
      <span className=" text-neutral-500 dark:text-neutral-400">
        {retourInfo.planned_arrival_time}
      </span>
      <span className=" font-semibold flex">
      <Icon path={mdiAirplaneLanding} size={1} />{retourInfo.arrival_airport_name+" ("+retourInfo.destination_rcd+")"}
      </span>
    </div>
  </div>
</div>







<div className={isOpen===2?"":"hidden"}>
<div className="w-10 border-b border-neutral-200  dark:border-neutral-700"></div>
<div className={departInfo.dropOffFlightType!=="Économique Tassili Flex"?"hidden":"space-y-1"}><h1>Classe {departInfo.dropOffFlightType}</h1>
<ul className="text-sm text-neutral-500 dark:text-neutral-400 space-y-1 md:space-y-2">
  <li className="flex"> <span className="w-25 h-25" >
  <svg aria-hidden="true" width="24" height="24"><g fill="#9099a2"><path d="M20.523 2.947h-2.529v-1.5a.5.5 0 1 0-1 0v1.5H7.018v-1.5a.5.5 0 1 0-1 0v1.5H3.477a2.5 2.5 0 0 0-2.5 2.5v15.042a2.5 2.5 0 0 0 2.5 2.5h17.046a2.5 2.5 0 0 0 2.5-2.5V5.447a2.5 2.5 0 0 0-2.5-2.5zm-17.046 1h2.541v1.5a.5.5 0 0 0 1 0v-1.5h9.976v1.5a.5.5 0 0 0 1 0v-1.5h2.529a1.5 1.5 0 0 1 1.5 1.5v1.478H1.977V5.447a1.5 1.5 0 0 1 1.5-1.5zm17.046 18.042H3.477a1.5 1.5 0 0 1-1.5-1.5V7.925h20.046v12.564a1.5 1.5 0 0 1-1.5 1.5z"></path><path d="M14.021 10.856a.5.5 0 0 0-.123.7l1.947 2.781H7.229a.5.5 0 0 0 0 1h8.563l-1.9 2.787a.5.5 0 1 0 .827.563l2.656-3.9-2.66-3.8a.5.5 0 0 0-.694-.131z"></path></g></svg>
    </span><span className="py-1 px-2">Flexibilité de pouvoir faire 1 modification</span></li>
    <li className="flex"> <span className="w-25 h-25" >
    <svg aria-hidden="true" width="24" height="24"><g fill="#9099a2"><path d="M22.862 1.101a.5.5 0 0 0-.707 0L1.137 22.118a.5.5 0 0 0 .354.854.5.5 0 0 0 .353-.147L22.862 1.808a.5.5 0 0 0 0-.707z"></path><path d="M23.023 5.876a.5.5 0 0 0-1 0v4.161a2.042 2.042 0 0 0 0 3.958v4.542a.5.5 0 0 1-.5.5H8.227a.5.5 0 1 0 0 1h13.3a1.5 1.5 0 0 0 1.5-1.5v-5.48h-.5a1.041 1.041 0 0 1 0-2.081h.5V5.888z"></path><path d="M1.506 18.741a.5.5 0 0 0 .5-.5v-4.246a2.041 2.041 0 0 0 0-3.958V5.591a.5.5 0 0 1 .5-.5h3.518V13.681a.5.5 0 0 0 1 0v-8.59h8.541a.5.5 0 1 0 0-1H2.506a1.5 1.5 0 0 0-1.5 1.5v5.385h.5a1.041 1.041 0 1 1 0 2.081h-.5v5.184a.5.5 0 0 0 .5.5z"></path></g></svg>
    </span><span className="py-1 px-2">Annulation sous 24 heures sans frais</span></li>

    <li className="flex"> <span className="w-25 h-25" >
    <svg aria-hidden="true" width="24" height="24" viewBox="0 0 24 24"><g className="nc-icon-wrapper"><defs><clipPath id="b_cbg"><path d="M0 0h24v24H0z"></path></clipPath></defs><g id="a_cbg" clip-path="url(#b_cbg)"><g transform="translate(1.021 .972)"><path d="M20.428 3.906h-4.456V2a.989.989 0 0 0-.989-.987H8.956A.988.988 0 0 0 7.968 2v1.91H3.519A2.5 2.5 0 0 0 1.021 6.4v12.08a2.5 2.5 0 0 0 2.5 2.493H4.1a1.452 1.452 0 0 0-.1.527 1.476 1.476 0 0 0 2.951 0 1.452 1.452 0 0 0-.1-.528h10.304a1.452 1.452 0 0 0-.1.528 1.476 1.476 0 1 0 2.849-.528h.526a2.5 2.5 0 0 0 2.5-2.493V6.4a2.5 2.5 0 0 0-2.502-2.494zM5.473 21.976a.475.475 0 1 1 .475-.475.476.476 0 0 1-.475.475zm13.055 0A.475.475 0 1 1 19 21.5a.475.475 0 0 1-.472.476zM8.956 2.008L14.972 2v1.91H8.965zm12.969 16.471a1.5 1.5 0 0 1-1.5 1.495H3.519a1.5 1.5 0 0 1-1.5-1.495V6.4a1.5 1.5 0 0 1 1.5-1.5h16.909a1.5 1.5 0 0 1 1.5 1.495z" transform="translate(-1.021 -1.009)" fill="#9099a2"></path><path d="M7.971 7.994a.5.5 0 0 0-.5.5v7.957a.5.5 0 1 0 1 0V8.494a.5.5 0 0 0-.5-.5z" transform="translate(-1.021 -1.026)" fill="#9099a2"></path><path d="M11.953 7.994a.5.5 0 0 0-.5.5v7.957a.5.5 0 0 0 1 0V8.494a.5.5 0 0 0-.5-.5z" transform="translate(-1.021 -1.026)" fill="#9099a2"></path><path d="M16.029 7.994a.5.5 0 0 0-.5.5v7.957a.5.5 0 1 0 1 0V8.494a.5.5 0 0 0-.5-.5z" transform="translate(-1.021 -1.026)" fill="#9099a2"></path></g></g></g></svg>
    </span><span className="py-1 px-2">Bagages enregistrés : 1 pièces, 20 kg</span></li>

    <li className="flex"> <span className="w-25 h-25" >
    <svg aria-hidden="true" width="24" height="24" viewBox="0 0 24 24"><g className="nc-icon-wrapper"><defs><clipPath id="b_hb"><path d="M0 0h24v24H0z"></path></clipPath></defs><g id="a_hb" clip-path="url(#b_hb)"><path d="M21.509 4.683h-5.341V3.2a1.488 1.488 0 0 0-1.487-1.49H9.273A1.487 1.487 0 0 0 7.788 3.2v1.483H2.49a1.475 1.475 0 0 0-1.474 1.474v12.376a1.506 1.506 0 0 0 1.523 1.486h18.933A1.507 1.507 0 0 0 23 18.533V6.169a1.488 1.488 0 0 0-1.491-1.486zM8.788 3.2a.486.486 0 0 1 .485-.486h5.408a.487.487 0 0 1 .487.486v1.483h-6.38zm12.684 15.819H2.539a.506.506 0 0 1-.523-.486v-6.647a1.476 1.476 0 0 0 .5.092h7.5v1.04a1.974 1.974 0 0 0 3.948 0v-1.04H21.5a1.483 1.483 0 0 0 .5-.092v6.647a.507.507 0 0 1-.528.486zm-10.46-6v-2.53h1.948v2.529a.974.974 0 0 1-1.948 0zM22 10.478a.5.5 0 0 1-.5.5h-7.54V9.489h-3.948v1.489h-7.5a.5.5 0 0 1-.5-.5V6.157a.475.475 0 0 1 .474-.474h19.023a.493.493 0 0 1 .491.486v4.309z" transform="translate(0 .016)" fill="#9099a2"></path></g></g></svg>
    </span><span className="py-1 px-2">Bagages à main : 1 pièces, 10 kg</span></li>

    <li className="flex"> <span className="w-25 h-25" >
    <svg aria-hidden="true" width="24" height="24" viewBox="0 0 24 24"><g className="nc-icon-wrapper"><defs><clipPath id="b_lo"><path d="M0 0h24v24H0z"></path></clipPath></defs><g id="a_lo" clip-path="url(#b_lo)"><path d="M19.777 9.971v-7.71A1.446 1.446 0 0 0 18.2.955H5.784a1.445 1.445 0 0 0-1.578 1.306v7.69a3.01 3.01 0 0 0-.977 5.849v5.686a1.5 1.5 0 0 0 1.5 1.5h14.558a1.494 1.494 0 0 0 1.484-1.5v-5.664a3.011 3.011 0 0 0-.994-5.851zM4.731 21.987a.5.5 0 0 1-.5-.5v-6.475l-.4-.081a2.013 2.013 0 1 1 2.418-1.986v9.042zm2.518 0v-5.948h9.509l.011 5.948zm9.5-9.016v2.069h-9.5v-2.1a3 3 0 0 0-2.041-2.816V2.261c0-.122.23-.308.578-.308H18.2c.348 0 .579.186.579.308v7.89a3.012 3.012 0 0 0-2.026 2.82zm3.419 1.983l-.4.082v6.454a.488.488 0 0 1-.484.5h-1.516l-.015-9.019a2.016 2.016 0 1 1 2.419 1.981z" transform="translate(0 .016)" fill="#9099a2"></path></g></g></svg>
    </span><span className="py-1 px-2">Sélection de siège incluse</span></li>


</ul>
</div>

<div className={departInfo.dropOffFlightType!=="Business Tassili Flex"?"hidden":"space-y-1"}><h1>Classe {departInfo.dropOffFlightType}</h1>
<ul className="text-sm text-neutral-500 dark:text-neutral-400 space-y-1 md:space-y-2">
  <li className="flex"> <span className="w-25 h-25" >
  <svg aria-hidden="true" width="24" height="24"><g fill="#9099a2"><path d="M20.523 2.947h-2.529v-1.5a.5.5 0 1 0-1 0v1.5H7.018v-1.5a.5.5 0 1 0-1 0v1.5H3.477a2.5 2.5 0 0 0-2.5 2.5v15.042a2.5 2.5 0 0 0 2.5 2.5h17.046a2.5 2.5 0 0 0 2.5-2.5V5.447a2.5 2.5 0 0 0-2.5-2.5zm-17.046 1h2.541v1.5a.5.5 0 0 0 1 0v-1.5h9.976v1.5a.5.5 0 0 0 1 0v-1.5h2.529a1.5 1.5 0 0 1 1.5 1.5v1.478H1.977V5.447a1.5 1.5 0 0 1 1.5-1.5zm17.046 18.042H3.477a1.5 1.5 0 0 1-1.5-1.5V7.925h20.046v12.564a1.5 1.5 0 0 1-1.5 1.5z"></path><path d="M14.021 10.856a.5.5 0 0 0-.123.7l1.947 2.781H7.229a.5.5 0 0 0 0 1h8.563l-1.9 2.787a.5.5 0 1 0 .827.563l2.656-3.9-2.66-3.8a.5.5 0 0 0-.694-.131z"></path></g></svg>
    </span><span className="py-1 px-2">Flexibilité de pouvoir faire des modifications</span></li>
    <li className="flex"> <span className="w-25 h-25" >
    <svg aria-hidden="true" width="24" height="24"><g fill="#9099a2"><path d="M22.862 1.101a.5.5 0 0 0-.707 0L1.137 22.118a.5.5 0 0 0 .354.854.5.5 0 0 0 .353-.147L22.862 1.808a.5.5 0 0 0 0-.707z"></path><path d="M23.023 5.876a.5.5 0 0 0-1 0v4.161a2.042 2.042 0 0 0 0 3.958v4.542a.5.5 0 0 1-.5.5H8.227a.5.5 0 1 0 0 1h13.3a1.5 1.5 0 0 0 1.5-1.5v-5.48h-.5a1.041 1.041 0 0 1 0-2.081h.5V5.888z"></path><path d="M1.506 18.741a.5.5 0 0 0 .5-.5v-4.246a2.041 2.041 0 0 0 0-3.958V5.591a.5.5 0 0 1 .5-.5h3.518V13.681a.5.5 0 0 0 1 0v-8.59h8.541a.5.5 0 1 0 0-1H2.506a1.5 1.5 0 0 0-1.5 1.5v5.385h.5a1.041 1.041 0 1 1 0 2.081h-.5v5.184a.5.5 0 0 0 .5.5z"></path></g></svg>
    </span><span className="py-1 px-2">Annulation sans frais</span></li>

    <li className="flex"> <span className="w-25 h-25" >
    <svg aria-hidden="true" width="24" height="24" viewBox="0 0 24 24"><g className="nc-icon-wrapper"><defs><clipPath id="b_cbg"><path d="M0 0h24v24H0z"></path></clipPath></defs><g id="a_cbg" clip-path="url(#b_cbg)"><g transform="translate(1.021 .972)"><path d="M20.428 3.906h-4.456V2a.989.989 0 0 0-.989-.987H8.956A.988.988 0 0 0 7.968 2v1.91H3.519A2.5 2.5 0 0 0 1.021 6.4v12.08a2.5 2.5 0 0 0 2.5 2.493H4.1a1.452 1.452 0 0 0-.1.527 1.476 1.476 0 0 0 2.951 0 1.452 1.452 0 0 0-.1-.528h10.304a1.452 1.452 0 0 0-.1.528 1.476 1.476 0 1 0 2.849-.528h.526a2.5 2.5 0 0 0 2.5-2.493V6.4a2.5 2.5 0 0 0-2.502-2.494zM5.473 21.976a.475.475 0 1 1 .475-.475.476.476 0 0 1-.475.475zm13.055 0A.475.475 0 1 1 19 21.5a.475.475 0 0 1-.472.476zM8.956 2.008L14.972 2v1.91H8.965zm12.969 16.471a1.5 1.5 0 0 1-1.5 1.495H3.519a1.5 1.5 0 0 1-1.5-1.495V6.4a1.5 1.5 0 0 1 1.5-1.5h16.909a1.5 1.5 0 0 1 1.5 1.495z" transform="translate(-1.021 -1.009)" fill="#9099a2"></path><path d="M7.971 7.994a.5.5 0 0 0-.5.5v7.957a.5.5 0 1 0 1 0V8.494a.5.5 0 0 0-.5-.5z" transform="translate(-1.021 -1.026)" fill="#9099a2"></path><path d="M11.953 7.994a.5.5 0 0 0-.5.5v7.957a.5.5 0 0 0 1 0V8.494a.5.5 0 0 0-.5-.5z" transform="translate(-1.021 -1.026)" fill="#9099a2"></path><path d="M16.029 7.994a.5.5 0 0 0-.5.5v7.957a.5.5 0 1 0 1 0V8.494a.5.5 0 0 0-.5-.5z" transform="translate(-1.021 -1.026)" fill="#9099a2"></path></g></g></g></svg>
    </span><span className="py-1 px-2">Bagages enregistrés : 1 pièces, 30 kg</span></li>

    <li className="flex"> <span className="w-25 h-25" >
    <svg aria-hidden="true" width="24" height="24" viewBox="0 0 24 24"><g className="nc-icon-wrapper"><defs><clipPath id="b_hb"><path d="M0 0h24v24H0z"></path></clipPath></defs><g id="a_hb" clip-path="url(#b_hb)"><path d="M21.509 4.683h-5.341V3.2a1.488 1.488 0 0 0-1.487-1.49H9.273A1.487 1.487 0 0 0 7.788 3.2v1.483H2.49a1.475 1.475 0 0 0-1.474 1.474v12.376a1.506 1.506 0 0 0 1.523 1.486h18.933A1.507 1.507 0 0 0 23 18.533V6.169a1.488 1.488 0 0 0-1.491-1.486zM8.788 3.2a.486.486 0 0 1 .485-.486h5.408a.487.487 0 0 1 .487.486v1.483h-6.38zm12.684 15.819H2.539a.506.506 0 0 1-.523-.486v-6.647a1.476 1.476 0 0 0 .5.092h7.5v1.04a1.974 1.974 0 0 0 3.948 0v-1.04H21.5a1.483 1.483 0 0 0 .5-.092v6.647a.507.507 0 0 1-.528.486zm-10.46-6v-2.53h1.948v2.529a.974.974 0 0 1-1.948 0zM22 10.478a.5.5 0 0 1-.5.5h-7.54V9.489h-3.948v1.489h-7.5a.5.5 0 0 1-.5-.5V6.157a.475.475 0 0 1 .474-.474h19.023a.493.493 0 0 1 .491.486v4.309z" transform="translate(0 .016)" fill="#9099a2"></path></g></g></svg>
    </span><span className="py-1 px-2">Bagages à main : 1 pièces, 10 kg</span></li>

    <li className="flex"> <span className="w-25 h-25" >
    <svg aria-hidden="true" width="24" height="24" viewBox="0 0 24 24"><g className="nc-icon-wrapper"><defs><clipPath id="b_lo"><path d="M0 0h24v24H0z"></path></clipPath></defs><g id="a_lo" clip-path="url(#b_lo)"><path d="M19.777 9.971v-7.71A1.446 1.446 0 0 0 18.2.955H5.784a1.445 1.445 0 0 0-1.578 1.306v7.69a3.01 3.01 0 0 0-.977 5.849v5.686a1.5 1.5 0 0 0 1.5 1.5h14.558a1.494 1.494 0 0 0 1.484-1.5v-5.664a3.011 3.011 0 0 0-.994-5.851zM4.731 21.987a.5.5 0 0 1-.5-.5v-6.475l-.4-.081a2.013 2.013 0 1 1 2.418-1.986v9.042zm2.518 0v-5.948h9.509l.011 5.948zm9.5-9.016v2.069h-9.5v-2.1a3 3 0 0 0-2.041-2.816V2.261c0-.122.23-.308.578-.308H18.2c.348 0 .579.186.579.308v7.89a3.012 3.012 0 0 0-2.026 2.82zm3.419 1.983l-.4.082v6.454a.488.488 0 0 1-.484.5h-1.516l-.015-9.019a2.016 2.016 0 1 1 2.419 1.981z" transform="translate(0 .016)" fill="#9099a2"></path></g></g></svg>
    </span><span className="py-1 px-2">Sélection de siège incluse</span></li>


</ul>
</div>
</div>





</div>

);
}


  const renderPopSidebar = () => {
    //      if (true) return;
          return (
            <div className="w-full flex flex-col sm:rounded-2xl lg:border border-neutral-200 dark:border-neutral-700 space-y-1 sm:space-y-1 px-0 sm:p-6 xl:p-8">
              <span className="text-2xl font-semibold">Détails du voyage</span>
              <span className="flex justify-between text-neutral-6000">{localStorage.flightType}</span>
              <span className="block  text-sm text-neutral-500 dark:text-neutral-400">
              {localStorage.nbrPassengers} Voyageur{localStorage.nbrPassengers>1?"s":""} 
                            {localStorage.adultInputValue==="0"?"":(" · "+localStorage.adultInputValue+" Adult"+(localStorage.adultInputValue>1?"s":""))}
                            {localStorage.childrenInputValue==="0"?"":" · "+(localStorage.childrenInputValue+" Enfant"+(localStorage.childrenInputValue>1?"s":""))}
                            {localStorage.infantsInputValue==="0"?"":" · "+(localStorage.infantsInputValue+" Bébé"+(localStorage.infantsInputValue>1?"s":""))}
                  </span>
                  <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
    
    
    
    {renderPopSidebarDepart()}
    
    
    
                <div className="border-b border-neutral-200 dark:border-neutral-700"></div>


    {renderPopSidebarRetour()}
    
    
    
    
                <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
    
                <div className={retourValide?"flex justify-between font-semibold":"hidden"}>
                  <span>Total</span>
                  <span>{totalPrice}</span>
                </div>
    
  
    
    
            </div>
          );
        };
    
    






  
    const renderSidebar = () => {
//      if (true) return;
      return (
        <div className="w-full flex flex-col sm:rounded-2xl lg:border border-neutral-200 dark:border-neutral-700 space-y-1 sm:space-y-1 px-0 sm:p-6 xl:p-8">
          <span className="text-2xl font-semibold">Détails du voyage</span>
          <span className="flex justify-between text-neutral-6000">{localStorage.flightType}</span>
          <span className="block  text-sm text-neutral-500 dark:text-neutral-400">
          {localStorage.nbrPassengers} Voyageur{localStorage.nbrPassengers>1?"s":""} 
                        {localStorage.adultInputValue==="0"?"":(" · "+localStorage.adultInputValue+" Adult"+(localStorage.adultInputValue>1?"s":""))}
                        {localStorage.childrenInputValue==="0"?"":" · "+(localStorage.childrenInputValue+" Enfant"+(localStorage.childrenInputValue>1?"s":""))}
                        {localStorage.infantsInputValue==="0"?"":" · "+(localStorage.infantsInputValue+" Bébé"+(localStorage.infantsInputValue>1?"s":""))}
              </span>
              <div className="border-b border-neutral-200 dark:border-neutral-700"></div>

<div>
              <div className="flex justify-between font-semibold flex">
              <div className="flex font-semibold "><span>{strCodeAirpDep}</span><span className="mx-1"> </span><Icon path={mdiAirplane} rotate={45} size={0.8}/><span className="mx-1"> </span><span>{strCodeAirpArv}</span></div>
            </div>
            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span>{departInfo.departure_date}</span>
            </div>

            <span
          className={`absolute right-10 top-[190px] sm:bottom-auto sm:top-[190px] sm:-translate-y-1/2 w-10 h-10 bg-neutral-50 dark:bg-neutral-800 rounded-full flex items-center justify-center cursor-pointer ${
            isOpen!==1 ? "" : "transform -rotate-90"
          }`}
          onClick={() => setIsOpen(isOpen===1?0:1)}
        >
          <i className="text-xl las la-angle-down"></i>
        </span>


        <div className={isOpen===1?"flex my-5 md:my-0":"flex my-5 md:my-0 hidden"}>
            <div className="flex-shrink-0 flex flex-col items-center py-2">
              <span className="block w-6 h-6 rounded-full border border-neutral-400"></span>
              <span className="block flex-grow border-l border-neutral-400 border-dashed my-1">
              </span>

              <span className="absolute w-25 top-[300px] flex">
            <img src={miniLogoPng} className="w-10" alt="" />
            </span>

            <span className="absolute w-25 top-[280px] right-[180px] flex text-left items-left">
              <span className="flex flex-col space-y-1">
              <span>Vol n° {departInfo.airline_rcd+" "+departInfo.flight_number}</span>
              <span>Sur un {departInfo.aircraft_rcd}</span>
              <span>durée : {departInfo.duration}</span>
              </span>
            </span>

              <span className="block w-6 h-6 rounded-full border border-neutral-400"></span>
            </div>
            <div className="ml-4 space-y-28 text-sm">
              <div className="flex flex-col space-y-1">
                <span className=" text-neutral-500 dark:text-neutral-400">
                  
                {departInfo.planned_departure_time}
                </span>
                <span className=" font-semibold flex">
                <Icon path={mdiAirplaneTakeoff} size={1} />{departInfo.departure_airport_name+" ("+departInfo.origin_rcd+")"}
                </span>
              </div>
              <div className="flex flex-col space-y-1">
                <span className=" text-neutral-500 dark:text-neutral-400">
                  {departInfo.planned_arrival_time}
                </span>
                <span className=" font-semibold flex">
                <Icon path={mdiAirplaneLanding} size={1} />{departInfo.arrival_airport_name+" ("+departInfo.destination_rcd+")"}
                </span>
              </div>
            </div>



          </div>



<div className={isOpen===1?"":"hidden"}>
<div className="w-10 border-b border-neutral-200  dark:border-neutral-700"></div>
          <div className={departInfo.dropOffFlightType!=="Économique Tassili Flex"?"hidden":"space-y-1"}><h1>Classe {departInfo.dropOffFlightType}</h1>
          <ul className="text-sm text-neutral-500 dark:text-neutral-400 space-y-1 md:space-y-2">
            <li className="flex"> <span className="w-25 h-25" >
            <svg aria-hidden="true" width="24" height="24"><g fill="#9099a2"><path d="M20.523 2.947h-2.529v-1.5a.5.5 0 1 0-1 0v1.5H7.018v-1.5a.5.5 0 1 0-1 0v1.5H3.477a2.5 2.5 0 0 0-2.5 2.5v15.042a2.5 2.5 0 0 0 2.5 2.5h17.046a2.5 2.5 0 0 0 2.5-2.5V5.447a2.5 2.5 0 0 0-2.5-2.5zm-17.046 1h2.541v1.5a.5.5 0 0 0 1 0v-1.5h9.976v1.5a.5.5 0 0 0 1 0v-1.5h2.529a1.5 1.5 0 0 1 1.5 1.5v1.478H1.977V5.447a1.5 1.5 0 0 1 1.5-1.5zm17.046 18.042H3.477a1.5 1.5 0 0 1-1.5-1.5V7.925h20.046v12.564a1.5 1.5 0 0 1-1.5 1.5z"></path><path d="M14.021 10.856a.5.5 0 0 0-.123.7l1.947 2.781H7.229a.5.5 0 0 0 0 1h8.563l-1.9 2.787a.5.5 0 1 0 .827.563l2.656-3.9-2.66-3.8a.5.5 0 0 0-.694-.131z"></path></g></svg>
              </span><span className="py-1 px-2">Flexibilité de pouvoir faire 1 modification</span></li>
              <li className="flex"> <span className="w-25 h-25" >
              <svg aria-hidden="true" width="24" height="24"><g fill="#9099a2"><path d="M22.862 1.101a.5.5 0 0 0-.707 0L1.137 22.118a.5.5 0 0 0 .354.854.5.5 0 0 0 .353-.147L22.862 1.808a.5.5 0 0 0 0-.707z"></path><path d="M23.023 5.876a.5.5 0 0 0-1 0v4.161a2.042 2.042 0 0 0 0 3.958v4.542a.5.5 0 0 1-.5.5H8.227a.5.5 0 1 0 0 1h13.3a1.5 1.5 0 0 0 1.5-1.5v-5.48h-.5a1.041 1.041 0 0 1 0-2.081h.5V5.888z"></path><path d="M1.506 18.741a.5.5 0 0 0 .5-.5v-4.246a2.041 2.041 0 0 0 0-3.958V5.591a.5.5 0 0 1 .5-.5h3.518V13.681a.5.5 0 0 0 1 0v-8.59h8.541a.5.5 0 1 0 0-1H2.506a1.5 1.5 0 0 0-1.5 1.5v5.385h.5a1.041 1.041 0 1 1 0 2.081h-.5v5.184a.5.5 0 0 0 .5.5z"></path></g></svg>
              </span><span className="py-1 px-2">Annulation sous 24 heures sans frais</span></li>

              <li className="flex"> <span className="w-25 h-25" >
              <svg aria-hidden="true" width="24" height="24" viewBox="0 0 24 24"><g className="nc-icon-wrapper"><defs><clipPath id="b_cbg"><path d="M0 0h24v24H0z"></path></clipPath></defs><g id="a_cbg" clip-path="url(#b_cbg)"><g transform="translate(1.021 .972)"><path d="M20.428 3.906h-4.456V2a.989.989 0 0 0-.989-.987H8.956A.988.988 0 0 0 7.968 2v1.91H3.519A2.5 2.5 0 0 0 1.021 6.4v12.08a2.5 2.5 0 0 0 2.5 2.493H4.1a1.452 1.452 0 0 0-.1.527 1.476 1.476 0 0 0 2.951 0 1.452 1.452 0 0 0-.1-.528h10.304a1.452 1.452 0 0 0-.1.528 1.476 1.476 0 1 0 2.849-.528h.526a2.5 2.5 0 0 0 2.5-2.493V6.4a2.5 2.5 0 0 0-2.502-2.494zM5.473 21.976a.475.475 0 1 1 .475-.475.476.476 0 0 1-.475.475zm13.055 0A.475.475 0 1 1 19 21.5a.475.475 0 0 1-.472.476zM8.956 2.008L14.972 2v1.91H8.965zm12.969 16.471a1.5 1.5 0 0 1-1.5 1.495H3.519a1.5 1.5 0 0 1-1.5-1.495V6.4a1.5 1.5 0 0 1 1.5-1.5h16.909a1.5 1.5 0 0 1 1.5 1.495z" transform="translate(-1.021 -1.009)" fill="#9099a2"></path><path d="M7.971 7.994a.5.5 0 0 0-.5.5v7.957a.5.5 0 1 0 1 0V8.494a.5.5 0 0 0-.5-.5z" transform="translate(-1.021 -1.026)" fill="#9099a2"></path><path d="M11.953 7.994a.5.5 0 0 0-.5.5v7.957a.5.5 0 0 0 1 0V8.494a.5.5 0 0 0-.5-.5z" transform="translate(-1.021 -1.026)" fill="#9099a2"></path><path d="M16.029 7.994a.5.5 0 0 0-.5.5v7.957a.5.5 0 1 0 1 0V8.494a.5.5 0 0 0-.5-.5z" transform="translate(-1.021 -1.026)" fill="#9099a2"></path></g></g></g></svg>
              </span><span className="py-1 px-2">Bagages enregistrés : 1 pièces, 20 kg</span></li>

              <li className="flex"> <span className="w-25 h-25" >
              <svg aria-hidden="true" width="24" height="24" viewBox="0 0 24 24"><g className="nc-icon-wrapper"><defs><clipPath id="b_hb"><path d="M0 0h24v24H0z"></path></clipPath></defs><g id="a_hb" clip-path="url(#b_hb)"><path d="M21.509 4.683h-5.341V3.2a1.488 1.488 0 0 0-1.487-1.49H9.273A1.487 1.487 0 0 0 7.788 3.2v1.483H2.49a1.475 1.475 0 0 0-1.474 1.474v12.376a1.506 1.506 0 0 0 1.523 1.486h18.933A1.507 1.507 0 0 0 23 18.533V6.169a1.488 1.488 0 0 0-1.491-1.486zM8.788 3.2a.486.486 0 0 1 .485-.486h5.408a.487.487 0 0 1 .487.486v1.483h-6.38zm12.684 15.819H2.539a.506.506 0 0 1-.523-.486v-6.647a1.476 1.476 0 0 0 .5.092h7.5v1.04a1.974 1.974 0 0 0 3.948 0v-1.04H21.5a1.483 1.483 0 0 0 .5-.092v6.647a.507.507 0 0 1-.528.486zm-10.46-6v-2.53h1.948v2.529a.974.974 0 0 1-1.948 0zM22 10.478a.5.5 0 0 1-.5.5h-7.54V9.489h-3.948v1.489h-7.5a.5.5 0 0 1-.5-.5V6.157a.475.475 0 0 1 .474-.474h19.023a.493.493 0 0 1 .491.486v4.309z" transform="translate(0 .016)" fill="#9099a2"></path></g></g></svg>
              </span><span className="py-1 px-2">Bagages à main : 1 pièces, 10 kg au total</span></li>

              <li className="flex"> <span className="w-25 h-25" >
              <svg aria-hidden="true" width="24" height="24" viewBox="0 0 24 24"><g className="nc-icon-wrapper"><defs><clipPath id="b_lo"><path d="M0 0h24v24H0z"></path></clipPath></defs><g id="a_lo" clip-path="url(#b_lo)"><path d="M19.777 9.971v-7.71A1.446 1.446 0 0 0 18.2.955H5.784a1.445 1.445 0 0 0-1.578 1.306v7.69a3.01 3.01 0 0 0-.977 5.849v5.686a1.5 1.5 0 0 0 1.5 1.5h14.558a1.494 1.494 0 0 0 1.484-1.5v-5.664a3.011 3.011 0 0 0-.994-5.851zM4.731 21.987a.5.5 0 0 1-.5-.5v-6.475l-.4-.081a2.013 2.013 0 1 1 2.418-1.986v9.042zm2.518 0v-5.948h9.509l.011 5.948zm9.5-9.016v2.069h-9.5v-2.1a3 3 0 0 0-2.041-2.816V2.261c0-.122.23-.308.578-.308H18.2c.348 0 .579.186.579.308v7.89a3.012 3.012 0 0 0-2.026 2.82zm3.419 1.983l-.4.082v6.454a.488.488 0 0 1-.484.5h-1.516l-.015-9.019a2.016 2.016 0 1 1 2.419 1.981z" transform="translate(0 .016)" fill="#9099a2"></path></g></g></svg>
              </span><span className="py-1 px-2">Sélection de siège incluse</span></li>


          </ul>
          </div>

          <div className={departInfo.dropOffFlightType!=="Business Tassili Flex"?"hidden":"space-y-1"}><h1>Classe {departInfo.dropOffFlightType}</h1>
          <ul className="text-sm text-neutral-500 dark:text-neutral-400 space-y-1 md:space-y-2">
            <li className="flex"> <span className="w-25 h-25" >
            <svg aria-hidden="true" width="24" height="24"><g fill="#9099a2"><path d="M20.523 2.947h-2.529v-1.5a.5.5 0 1 0-1 0v1.5H7.018v-1.5a.5.5 0 1 0-1 0v1.5H3.477a2.5 2.5 0 0 0-2.5 2.5v15.042a2.5 2.5 0 0 0 2.5 2.5h17.046a2.5 2.5 0 0 0 2.5-2.5V5.447a2.5 2.5 0 0 0-2.5-2.5zm-17.046 1h2.541v1.5a.5.5 0 0 0 1 0v-1.5h9.976v1.5a.5.5 0 0 0 1 0v-1.5h2.529a1.5 1.5 0 0 1 1.5 1.5v1.478H1.977V5.447a1.5 1.5 0 0 1 1.5-1.5zm17.046 18.042H3.477a1.5 1.5 0 0 1-1.5-1.5V7.925h20.046v12.564a1.5 1.5 0 0 1-1.5 1.5z"></path><path d="M14.021 10.856a.5.5 0 0 0-.123.7l1.947 2.781H7.229a.5.5 0 0 0 0 1h8.563l-1.9 2.787a.5.5 0 1 0 .827.563l2.656-3.9-2.66-3.8a.5.5 0 0 0-.694-.131z"></path></g></svg>
              </span><span className="py-1 px-2">Flexibilité de pouvoir faire des modifications</span></li>
              <li className="flex"> <span className="w-25 h-25" >
              <svg aria-hidden="true" width="24" height="24"><g fill="#9099a2"><path d="M22.862 1.101a.5.5 0 0 0-.707 0L1.137 22.118a.5.5 0 0 0 .354.854.5.5 0 0 0 .353-.147L22.862 1.808a.5.5 0 0 0 0-.707z"></path><path d="M23.023 5.876a.5.5 0 0 0-1 0v4.161a2.042 2.042 0 0 0 0 3.958v4.542a.5.5 0 0 1-.5.5H8.227a.5.5 0 1 0 0 1h13.3a1.5 1.5 0 0 0 1.5-1.5v-5.48h-.5a1.041 1.041 0 0 1 0-2.081h.5V5.888z"></path><path d="M1.506 18.741a.5.5 0 0 0 .5-.5v-4.246a2.041 2.041 0 0 0 0-3.958V5.591a.5.5 0 0 1 .5-.5h3.518V13.681a.5.5 0 0 0 1 0v-8.59h8.541a.5.5 0 1 0 0-1H2.506a1.5 1.5 0 0 0-1.5 1.5v5.385h.5a1.041 1.041 0 1 1 0 2.081h-.5v5.184a.5.5 0 0 0 .5.5z"></path></g></svg>
              </span><span className="py-1 px-2">Annulation sans frais</span></li>

              <li className="flex"> <span className="w-25 h-25" >
              <svg aria-hidden="true" width="24" height="24" viewBox="0 0 24 24"><g className="nc-icon-wrapper"><defs><clipPath id="b_cbg"><path d="M0 0h24v24H0z"></path></clipPath></defs><g id="a_cbg" clip-path="url(#b_cbg)"><g transform="translate(1.021 .972)"><path d="M20.428 3.906h-4.456V2a.989.989 0 0 0-.989-.987H8.956A.988.988 0 0 0 7.968 2v1.91H3.519A2.5 2.5 0 0 0 1.021 6.4v12.08a2.5 2.5 0 0 0 2.5 2.493H4.1a1.452 1.452 0 0 0-.1.527 1.476 1.476 0 0 0 2.951 0 1.452 1.452 0 0 0-.1-.528h10.304a1.452 1.452 0 0 0-.1.528 1.476 1.476 0 1 0 2.849-.528h.526a2.5 2.5 0 0 0 2.5-2.493V6.4a2.5 2.5 0 0 0-2.502-2.494zM5.473 21.976a.475.475 0 1 1 .475-.475.476.476 0 0 1-.475.475zm13.055 0A.475.475 0 1 1 19 21.5a.475.475 0 0 1-.472.476zM8.956 2.008L14.972 2v1.91H8.965zm12.969 16.471a1.5 1.5 0 0 1-1.5 1.495H3.519a1.5 1.5 0 0 1-1.5-1.495V6.4a1.5 1.5 0 0 1 1.5-1.5h16.909a1.5 1.5 0 0 1 1.5 1.495z" transform="translate(-1.021 -1.009)" fill="#9099a2"></path><path d="M7.971 7.994a.5.5 0 0 0-.5.5v7.957a.5.5 0 1 0 1 0V8.494a.5.5 0 0 0-.5-.5z" transform="translate(-1.021 -1.026)" fill="#9099a2"></path><path d="M11.953 7.994a.5.5 0 0 0-.5.5v7.957a.5.5 0 0 0 1 0V8.494a.5.5 0 0 0-.5-.5z" transform="translate(-1.021 -1.026)" fill="#9099a2"></path><path d="M16.029 7.994a.5.5 0 0 0-.5.5v7.957a.5.5 0 1 0 1 0V8.494a.5.5 0 0 0-.5-.5z" transform="translate(-1.021 -1.026)" fill="#9099a2"></path></g></g></g></svg>
              </span><span className="py-1 px-2">Bagages enregistrés : 1 pièces, 30 kg</span></li>

              <li className="flex"> <span className="w-25 h-25" >
              <svg aria-hidden="true" width="24" height="24" viewBox="0 0 24 24"><g className="nc-icon-wrapper"><defs><clipPath id="b_hb"><path d="M0 0h24v24H0z"></path></clipPath></defs><g id="a_hb" clip-path="url(#b_hb)"><path d="M21.509 4.683h-5.341V3.2a1.488 1.488 0 0 0-1.487-1.49H9.273A1.487 1.487 0 0 0 7.788 3.2v1.483H2.49a1.475 1.475 0 0 0-1.474 1.474v12.376a1.506 1.506 0 0 0 1.523 1.486h18.933A1.507 1.507 0 0 0 23 18.533V6.169a1.488 1.488 0 0 0-1.491-1.486zM8.788 3.2a.486.486 0 0 1 .485-.486h5.408a.487.487 0 0 1 .487.486v1.483h-6.38zm12.684 15.819H2.539a.506.506 0 0 1-.523-.486v-6.647a1.476 1.476 0 0 0 .5.092h7.5v1.04a1.974 1.974 0 0 0 3.948 0v-1.04H21.5a1.483 1.483 0 0 0 .5-.092v6.647a.507.507 0 0 1-.528.486zm-10.46-6v-2.53h1.948v2.529a.974.974 0 0 1-1.948 0zM22 10.478a.5.5 0 0 1-.5.5h-7.54V9.489h-3.948v1.489h-7.5a.5.5 0 0 1-.5-.5V6.157a.475.475 0 0 1 .474-.474h19.023a.493.493 0 0 1 .491.486v4.309z" transform="translate(0 .016)" fill="#9099a2"></path></g></g></svg>
              </span><span className="py-1 px-2">Bagages à main : 1 pièces, 10 kg au total</span></li>

              <li className="flex"> <span className="w-25 h-25" >
              <svg aria-hidden="true" width="24" height="24" viewBox="0 0 24 24"><g className="nc-icon-wrapper"><defs><clipPath id="b_lo"><path d="M0 0h24v24H0z"></path></clipPath></defs><g id="a_lo" clip-path="url(#b_lo)"><path d="M19.777 9.971v-7.71A1.446 1.446 0 0 0 18.2.955H5.784a1.445 1.445 0 0 0-1.578 1.306v7.69a3.01 3.01 0 0 0-.977 5.849v5.686a1.5 1.5 0 0 0 1.5 1.5h14.558a1.494 1.494 0 0 0 1.484-1.5v-5.664a3.011 3.011 0 0 0-.994-5.851zM4.731 21.987a.5.5 0 0 1-.5-.5v-6.475l-.4-.081a2.013 2.013 0 1 1 2.418-1.986v9.042zm2.518 0v-5.948h9.509l.011 5.948zm9.5-9.016v2.069h-9.5v-2.1a3 3 0 0 0-2.041-2.816V2.261c0-.122.23-.308.578-.308H18.2c.348 0 .579.186.579.308v7.89a3.012 3.012 0 0 0-2.026 2.82zm3.419 1.983l-.4.082v6.454a.488.488 0 0 1-.484.5h-1.516l-.015-9.019a2.016 2.016 0 1 1 2.419 1.981z" transform="translate(0 .016)" fill="#9099a2"></path></g></g></svg>
              </span><span className="py-1 px-2">Sélection de siège incluse</span></li>


          </ul>
          </div>
</div>










</div>






            <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
{rsltData[1]?          
<div>
            <div className="flex justify-between font-semibold flex">
              <div className="flex font-semibold "><span>{strCodeAirpArv}</span><span className="mx-1"> </span><Icon path={mdiAirplane} rotate={45} size={0.8}/><span className="mx-1"> </span><span>{strCodeAirpDep}</span></div>
            </div>
            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span>{retourInfo.departure_date}</span>
            </div>





            <span
          className={`absolute right-10  sm:bottom-auto sm:-translate-y-1/2 w-10 h-10 bg-neutral-50 dark:bg-neutral-800 rounded-full flex items-center justify-center cursor-pointer ${
            (isOpen!==2)? "top-[250px] sm:top-[250px]"+(isOpen===1?"top-[660px] sm:top-[660px]":"") : "top-[250px] sm:top-[250px] transform -rotate-90"
          }`}
          onClick={() => setIsOpen(isOpen===2?0:2)}
        >
          <i className="text-xl las la-angle-down"></i>
        </span>


        <div className={isOpen===2?"flex my-5 md:my-0":"flex my-5 md:my-0 hidden"}>
            <div className="flex-shrink-0 flex flex-col items-center py-2">
              <span className="block w-6 h-6 rounded-full border border-neutral-400"></span>
              <span className="block flex-grow border-l border-neutral-400 border-dashed my-1">
              </span>

              <span className="absolute w-25 top-[360px] flex">
            <img src={miniLogoPng} className="w-10" alt="" />
            </span>

            <span className="absolute w-25 top-[340px] right-[180px] flex text-left items-left">
              <span className="flex flex-col space-y-1">
              <span>Vol n° {retourInfo.airline_rcd+" "+retourInfo.flight_number}</span>
              <span>Sur un {retourInfo.aircraft_rcd}</span>
              <span>durée : {retourInfo.duration}</span>
              </span>
            </span>

              <span className="block w-6 h-6 rounded-full border border-neutral-400"></span>
            </div>
            <div className="ml-4 space-y-28 text-sm">
              <div className="flex flex-col space-y-1">
                <span className=" text-neutral-500 dark:text-neutral-400">
                  
                {retourInfo.planned_departure_time}
                </span>
                <span className=" font-semibold flex">
                <Icon path={mdiAirplaneTakeoff} size={1} />{retourInfo.departure_airport_name+" ("+retourInfo.origin_rcd+")"}
                </span>
              </div>
              <div className="flex flex-col space-y-1">
                <span className=" text-neutral-500 dark:text-neutral-400">
                  {retourInfo.planned_arrival_time}
                </span>
                <span className=" font-semibold flex">
                <Icon path={mdiAirplaneLanding} size={1} />{retourInfo.arrival_airport_name+" ("+retourInfo.destination_rcd+")"}
                </span>
              </div>
            </div>
          </div>







<div className={isOpen===2?"":"hidden"}>
<div className="w-10 border-b border-neutral-200  dark:border-neutral-700"></div>
          <div className={departInfo.dropOffFlightType!=="Économique Tassili Flex"?"hidden":"space-y-1"}><h1>Classe {departInfo.dropOffFlightType}</h1>
          <ul className="text-sm text-neutral-500 dark:text-neutral-400 space-y-1 md:space-y-2">
            <li className="flex"> <span className="w-25 h-25" >
            <svg aria-hidden="true" width="24" height="24"><g fill="#9099a2"><path d="M20.523 2.947h-2.529v-1.5a.5.5 0 1 0-1 0v1.5H7.018v-1.5a.5.5 0 1 0-1 0v1.5H3.477a2.5 2.5 0 0 0-2.5 2.5v15.042a2.5 2.5 0 0 0 2.5 2.5h17.046a2.5 2.5 0 0 0 2.5-2.5V5.447a2.5 2.5 0 0 0-2.5-2.5zm-17.046 1h2.541v1.5a.5.5 0 0 0 1 0v-1.5h9.976v1.5a.5.5 0 0 0 1 0v-1.5h2.529a1.5 1.5 0 0 1 1.5 1.5v1.478H1.977V5.447a1.5 1.5 0 0 1 1.5-1.5zm17.046 18.042H3.477a1.5 1.5 0 0 1-1.5-1.5V7.925h20.046v12.564a1.5 1.5 0 0 1-1.5 1.5z"></path><path d="M14.021 10.856a.5.5 0 0 0-.123.7l1.947 2.781H7.229a.5.5 0 0 0 0 1h8.563l-1.9 2.787a.5.5 0 1 0 .827.563l2.656-3.9-2.66-3.8a.5.5 0 0 0-.694-.131z"></path></g></svg>
              </span><span className="py-1 px-2">Flexibilité de pouvoir faire 1 modification</span></li>
              <li className="flex"> <span className="w-25 h-25" >
              <svg aria-hidden="true" width="24" height="24"><g fill="#9099a2"><path d="M22.862 1.101a.5.5 0 0 0-.707 0L1.137 22.118a.5.5 0 0 0 .354.854.5.5 0 0 0 .353-.147L22.862 1.808a.5.5 0 0 0 0-.707z"></path><path d="M23.023 5.876a.5.5 0 0 0-1 0v4.161a2.042 2.042 0 0 0 0 3.958v4.542a.5.5 0 0 1-.5.5H8.227a.5.5 0 1 0 0 1h13.3a1.5 1.5 0 0 0 1.5-1.5v-5.48h-.5a1.041 1.041 0 0 1 0-2.081h.5V5.888z"></path><path d="M1.506 18.741a.5.5 0 0 0 .5-.5v-4.246a2.041 2.041 0 0 0 0-3.958V5.591a.5.5 0 0 1 .5-.5h3.518V13.681a.5.5 0 0 0 1 0v-8.59h8.541a.5.5 0 1 0 0-1H2.506a1.5 1.5 0 0 0-1.5 1.5v5.385h.5a1.041 1.041 0 1 1 0 2.081h-.5v5.184a.5.5 0 0 0 .5.5z"></path></g></svg>
              </span><span className="py-1 px-2">Annulation sous 24 heures sans frais</span></li>

              <li className="flex"> <span className="w-25 h-25" >
              <svg aria-hidden="true" width="24" height="24" viewBox="0 0 24 24"><g className="nc-icon-wrapper"><defs><clipPath id="b_cbg"><path d="M0 0h24v24H0z"></path></clipPath></defs><g id="a_cbg" clip-path="url(#b_cbg)"><g transform="translate(1.021 .972)"><path d="M20.428 3.906h-4.456V2a.989.989 0 0 0-.989-.987H8.956A.988.988 0 0 0 7.968 2v1.91H3.519A2.5 2.5 0 0 0 1.021 6.4v12.08a2.5 2.5 0 0 0 2.5 2.493H4.1a1.452 1.452 0 0 0-.1.527 1.476 1.476 0 0 0 2.951 0 1.452 1.452 0 0 0-.1-.528h10.304a1.452 1.452 0 0 0-.1.528 1.476 1.476 0 1 0 2.849-.528h.526a2.5 2.5 0 0 0 2.5-2.493V6.4a2.5 2.5 0 0 0-2.502-2.494zM5.473 21.976a.475.475 0 1 1 .475-.475.476.476 0 0 1-.475.475zm13.055 0A.475.475 0 1 1 19 21.5a.475.475 0 0 1-.472.476zM8.956 2.008L14.972 2v1.91H8.965zm12.969 16.471a1.5 1.5 0 0 1-1.5 1.495H3.519a1.5 1.5 0 0 1-1.5-1.495V6.4a1.5 1.5 0 0 1 1.5-1.5h16.909a1.5 1.5 0 0 1 1.5 1.495z" transform="translate(-1.021 -1.009)" fill="#9099a2"></path><path d="M7.971 7.994a.5.5 0 0 0-.5.5v7.957a.5.5 0 1 0 1 0V8.494a.5.5 0 0 0-.5-.5z" transform="translate(-1.021 -1.026)" fill="#9099a2"></path><path d="M11.953 7.994a.5.5 0 0 0-.5.5v7.957a.5.5 0 0 0 1 0V8.494a.5.5 0 0 0-.5-.5z" transform="translate(-1.021 -1.026)" fill="#9099a2"></path><path d="M16.029 7.994a.5.5 0 0 0-.5.5v7.957a.5.5 0 1 0 1 0V8.494a.5.5 0 0 0-.5-.5z" transform="translate(-1.021 -1.026)" fill="#9099a2"></path></g></g></g></svg>
              </span><span className="py-1 px-2">Bagages enregistrés : 1 pièces, 20 kg</span></li>

              <li className="flex"> <span className="w-25 h-25" >
              <svg aria-hidden="true" width="24" height="24" viewBox="0 0 24 24"><g className="nc-icon-wrapper"><defs><clipPath id="b_hb"><path d="M0 0h24v24H0z"></path></clipPath></defs><g id="a_hb" clip-path="url(#b_hb)"><path d="M21.509 4.683h-5.341V3.2a1.488 1.488 0 0 0-1.487-1.49H9.273A1.487 1.487 0 0 0 7.788 3.2v1.483H2.49a1.475 1.475 0 0 0-1.474 1.474v12.376a1.506 1.506 0 0 0 1.523 1.486h18.933A1.507 1.507 0 0 0 23 18.533V6.169a1.488 1.488 0 0 0-1.491-1.486zM8.788 3.2a.486.486 0 0 1 .485-.486h5.408a.487.487 0 0 1 .487.486v1.483h-6.38zm12.684 15.819H2.539a.506.506 0 0 1-.523-.486v-6.647a1.476 1.476 0 0 0 .5.092h7.5v1.04a1.974 1.974 0 0 0 3.948 0v-1.04H21.5a1.483 1.483 0 0 0 .5-.092v6.647a.507.507 0 0 1-.528.486zm-10.46-6v-2.53h1.948v2.529a.974.974 0 0 1-1.948 0zM22 10.478a.5.5 0 0 1-.5.5h-7.54V9.489h-3.948v1.489h-7.5a.5.5 0 0 1-.5-.5V6.157a.475.475 0 0 1 .474-.474h19.023a.493.493 0 0 1 .491.486v4.309z" transform="translate(0 .016)" fill="#9099a2"></path></g></g></svg>
              </span><span className="py-1 px-2">Bagages à main : 1 pièces, 10 kg au total</span></li>

              <li className="flex"> <span className="w-25 h-25" >
              <svg aria-hidden="true" width="24" height="24" viewBox="0 0 24 24"><g className="nc-icon-wrapper"><defs><clipPath id="b_lo"><path d="M0 0h24v24H0z"></path></clipPath></defs><g id="a_lo" clip-path="url(#b_lo)"><path d="M19.777 9.971v-7.71A1.446 1.446 0 0 0 18.2.955H5.784a1.445 1.445 0 0 0-1.578 1.306v7.69a3.01 3.01 0 0 0-.977 5.849v5.686a1.5 1.5 0 0 0 1.5 1.5h14.558a1.494 1.494 0 0 0 1.484-1.5v-5.664a3.011 3.011 0 0 0-.994-5.851zM4.731 21.987a.5.5 0 0 1-.5-.5v-6.475l-.4-.081a2.013 2.013 0 1 1 2.418-1.986v9.042zm2.518 0v-5.948h9.509l.011 5.948zm9.5-9.016v2.069h-9.5v-2.1a3 3 0 0 0-2.041-2.816V2.261c0-.122.23-.308.578-.308H18.2c.348 0 .579.186.579.308v7.89a3.012 3.012 0 0 0-2.026 2.82zm3.419 1.983l-.4.082v6.454a.488.488 0 0 1-.484.5h-1.516l-.015-9.019a2.016 2.016 0 1 1 2.419 1.981z" transform="translate(0 .016)" fill="#9099a2"></path></g></g></svg>
              </span><span className="py-1 px-2">Sélection de siège incluse</span></li>


          </ul>
          </div>

          <div className={departInfo.dropOffFlightType!=="Business Tassili Flex"?"hidden":"space-y-1"}><h1>Classe {departInfo.dropOffFlightType}</h1>
          <ul className="text-sm text-neutral-500 dark:text-neutral-400 space-y-1 md:space-y-2">
            <li className="flex"> <span className="w-25 h-25" >
            <svg aria-hidden="true" width="24" height="24"><g fill="#9099a2"><path d="M20.523 2.947h-2.529v-1.5a.5.5 0 1 0-1 0v1.5H7.018v-1.5a.5.5 0 1 0-1 0v1.5H3.477a2.5 2.5 0 0 0-2.5 2.5v15.042a2.5 2.5 0 0 0 2.5 2.5h17.046a2.5 2.5 0 0 0 2.5-2.5V5.447a2.5 2.5 0 0 0-2.5-2.5zm-17.046 1h2.541v1.5a.5.5 0 0 0 1 0v-1.5h9.976v1.5a.5.5 0 0 0 1 0v-1.5h2.529a1.5 1.5 0 0 1 1.5 1.5v1.478H1.977V5.447a1.5 1.5 0 0 1 1.5-1.5zm17.046 18.042H3.477a1.5 1.5 0 0 1-1.5-1.5V7.925h20.046v12.564a1.5 1.5 0 0 1-1.5 1.5z"></path><path d="M14.021 10.856a.5.5 0 0 0-.123.7l1.947 2.781H7.229a.5.5 0 0 0 0 1h8.563l-1.9 2.787a.5.5 0 1 0 .827.563l2.656-3.9-2.66-3.8a.5.5 0 0 0-.694-.131z"></path></g></svg>
              </span><span className="py-1 px-2">Flexibilité de pouvoir faire des modifications</span></li>
              <li className="flex"> <span className="w-25 h-25" >
              <svg aria-hidden="true" width="24" height="24"><g fill="#9099a2"><path d="M22.862 1.101a.5.5 0 0 0-.707 0L1.137 22.118a.5.5 0 0 0 .354.854.5.5 0 0 0 .353-.147L22.862 1.808a.5.5 0 0 0 0-.707z"></path><path d="M23.023 5.876a.5.5 0 0 0-1 0v4.161a2.042 2.042 0 0 0 0 3.958v4.542a.5.5 0 0 1-.5.5H8.227a.5.5 0 1 0 0 1h13.3a1.5 1.5 0 0 0 1.5-1.5v-5.48h-.5a1.041 1.041 0 0 1 0-2.081h.5V5.888z"></path><path d="M1.506 18.741a.5.5 0 0 0 .5-.5v-4.246a2.041 2.041 0 0 0 0-3.958V5.591a.5.5 0 0 1 .5-.5h3.518V13.681a.5.5 0 0 0 1 0v-8.59h8.541a.5.5 0 1 0 0-1H2.506a1.5 1.5 0 0 0-1.5 1.5v5.385h.5a1.041 1.041 0 1 1 0 2.081h-.5v5.184a.5.5 0 0 0 .5.5z"></path></g></svg>
              </span><span className="py-1 px-2">Annulation sans frais</span></li>

              <li className="flex"> <span className="w-25 h-25" >
              <svg aria-hidden="true" width="24" height="24" viewBox="0 0 24 24"><g className="nc-icon-wrapper"><defs><clipPath id="b_cbg"><path d="M0 0h24v24H0z"></path></clipPath></defs><g id="a_cbg" clip-path="url(#b_cbg)"><g transform="translate(1.021 .972)"><path d="M20.428 3.906h-4.456V2a.989.989 0 0 0-.989-.987H8.956A.988.988 0 0 0 7.968 2v1.91H3.519A2.5 2.5 0 0 0 1.021 6.4v12.08a2.5 2.5 0 0 0 2.5 2.493H4.1a1.452 1.452 0 0 0-.1.527 1.476 1.476 0 0 0 2.951 0 1.452 1.452 0 0 0-.1-.528h10.304a1.452 1.452 0 0 0-.1.528 1.476 1.476 0 1 0 2.849-.528h.526a2.5 2.5 0 0 0 2.5-2.493V6.4a2.5 2.5 0 0 0-2.502-2.494zM5.473 21.976a.475.475 0 1 1 .475-.475.476.476 0 0 1-.475.475zm13.055 0A.475.475 0 1 1 19 21.5a.475.475 0 0 1-.472.476zM8.956 2.008L14.972 2v1.91H8.965zm12.969 16.471a1.5 1.5 0 0 1-1.5 1.495H3.519a1.5 1.5 0 0 1-1.5-1.495V6.4a1.5 1.5 0 0 1 1.5-1.5h16.909a1.5 1.5 0 0 1 1.5 1.495z" transform="translate(-1.021 -1.009)" fill="#9099a2"></path><path d="M7.971 7.994a.5.5 0 0 0-.5.5v7.957a.5.5 0 1 0 1 0V8.494a.5.5 0 0 0-.5-.5z" transform="translate(-1.021 -1.026)" fill="#9099a2"></path><path d="M11.953 7.994a.5.5 0 0 0-.5.5v7.957a.5.5 0 0 0 1 0V8.494a.5.5 0 0 0-.5-.5z" transform="translate(-1.021 -1.026)" fill="#9099a2"></path><path d="M16.029 7.994a.5.5 0 0 0-.5.5v7.957a.5.5 0 1 0 1 0V8.494a.5.5 0 0 0-.5-.5z" transform="translate(-1.021 -1.026)" fill="#9099a2"></path></g></g></g></svg>
              </span><span className="py-1 px-2">Bagages enregistrés : 1 pièces, 30 kg</span></li>

              <li className="flex"> <span className="w-25 h-25" >
              <svg aria-hidden="true" width="24" height="24" viewBox="0 0 24 24"><g className="nc-icon-wrapper"><defs><clipPath id="b_hb"><path d="M0 0h24v24H0z"></path></clipPath></defs><g id="a_hb" clip-path="url(#b_hb)"><path d="M21.509 4.683h-5.341V3.2a1.488 1.488 0 0 0-1.487-1.49H9.273A1.487 1.487 0 0 0 7.788 3.2v1.483H2.49a1.475 1.475 0 0 0-1.474 1.474v12.376a1.506 1.506 0 0 0 1.523 1.486h18.933A1.507 1.507 0 0 0 23 18.533V6.169a1.488 1.488 0 0 0-1.491-1.486zM8.788 3.2a.486.486 0 0 1 .485-.486h5.408a.487.487 0 0 1 .487.486v1.483h-6.38zm12.684 15.819H2.539a.506.506 0 0 1-.523-.486v-6.647a1.476 1.476 0 0 0 .5.092h7.5v1.04a1.974 1.974 0 0 0 3.948 0v-1.04H21.5a1.483 1.483 0 0 0 .5-.092v6.647a.507.507 0 0 1-.528.486zm-10.46-6v-2.53h1.948v2.529a.974.974 0 0 1-1.948 0zM22 10.478a.5.5 0 0 1-.5.5h-7.54V9.489h-3.948v1.489h-7.5a.5.5 0 0 1-.5-.5V6.157a.475.475 0 0 1 .474-.474h19.023a.493.493 0 0 1 .491.486v4.309z" transform="translate(0 .016)" fill="#9099a2"></path></g></g></svg>
              </span><span className="py-1 px-2">Bagages à main : 1 pièces, 10 kg au total</span></li>

              <li className="flex"> <span className="w-25 h-25" >
              <svg aria-hidden="true" width="24" height="24" viewBox="0 0 24 24"><g className="nc-icon-wrapper"><defs><clipPath id="b_lo"><path d="M0 0h24v24H0z"></path></clipPath></defs><g id="a_lo" clip-path="url(#b_lo)"><path d="M19.777 9.971v-7.71A1.446 1.446 0 0 0 18.2.955H5.784a1.445 1.445 0 0 0-1.578 1.306v7.69a3.01 3.01 0 0 0-.977 5.849v5.686a1.5 1.5 0 0 0 1.5 1.5h14.558a1.494 1.494 0 0 0 1.484-1.5v-5.664a3.011 3.011 0 0 0-.994-5.851zM4.731 21.987a.5.5 0 0 1-.5-.5v-6.475l-.4-.081a2.013 2.013 0 1 1 2.418-1.986v9.042zm2.518 0v-5.948h9.509l.011 5.948zm9.5-9.016v2.069h-9.5v-2.1a3 3 0 0 0-2.041-2.816V2.261c0-.122.23-.308.578-.308H18.2c.348 0 .579.186.579.308v7.89a3.012 3.012 0 0 0-2.026 2.82zm3.419 1.983l-.4.082v6.454a.488.488 0 0 1-.484.5h-1.516l-.015-9.019a2.016 2.016 0 1 1 2.419 1.981z" transform="translate(0 .016)" fill="#9099a2"></path></g></g></svg>
              </span><span className="py-1 px-2">Sélection de siège incluse</span></li>


          </ul>
          </div>
</div>





</div>
:""}




            <div className="border-b border-neutral-200 dark:border-neutral-700"></div>

            <div className="flex justify-between font-semibold">
              <span>Total</span>
              <span>{totalPrice}</span>
            </div>



            <div className={reservationStep===3?"w-full space-y-8 hidden":"w-full space-y-8"}>
              <span> </span>
      <ButtonPrimary className="w-full"  disabled={reservationStep===8?false:true} onClick ={() => { setReservationStep(9); reservationStep=9; }} >Choisir les sièges et les options </ButtonPrimary>
      <ButtonPrimary className="w-full" disabled={reservationStep===8?false:true} onClick ={() => { setReservationStep(3); reservationStep=3; }} >Vérifier et effectuer le règlement </ButtonPrimary>
      <ButtonPrimary className="w-full" onClick={() => { setReservationStep(1); reservationStep=1; }}>Précedent</ButtonPrimary>
      </div>
      <br/>
      <div className={reservationStep===3?"w-[400px] space-y-8":"w-full space-y-8 hidden"}>
            <ButtonPrimary className="w-full" onClick={() => { setReservationStep(2); reservationStep=2; }}>Précedent</ButtonPrimary>
      </div>    


        </div>
      );
    };




    const renderControlSelections = () => {
      console.log('Sortie -----------------');
      return (
        <div className="relative z-40 lg:block">
          <div className="fixed right-2 top-[102px] z-40 flex items-center">
            <Popover className="relative">
              {({ open }) => (
                <>
                  <Popover.Button
                    className={`p-2.5 bg-white hover:bg-neutral-100 dark:bg-primary-6000 dark:hover:bg-primary-700 rounded-xl shadow-xl border border-neutral-200 dark:border-primary-6000 z-10 focus:outline-none ${
                      open ? " focus:ring-2 ring-primary-500" : ""
                    }`}
                  > 
                    <IconAirplaneTicket className="w-8 h-8" />
                  </Popover.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className="absolute right-0 z-10 mt-0 top-0 w-screen max-w-sm">
                      <div className="rounded-2xl bg-white dark:bg-neutral-800 overflow-hidden nc-custom-shadow-1">
                      <div className="hidden lg:block flex-grow">{renderPopSidebar()}</div>
                    </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>
          </div>
        </div>
      );
    };






function setPhoneValue()
{
  ;
}
  const renderMain = () => {


    if ((reservationStep===2) || (reservationStep===8) || (reservationStep===9))
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 space-y-8 px-0 sm:p-6 xl:p-8">
        <h2 className="text-3xl lg:text-4xl font-semibold">
        Informations de Contact
        </h2>
        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="space-y-1 space-x-2 flex  flex-col sm:flex-row sm:items-center space-y-6 sm:space-y-0">
                  <div className="space-y-0.2">
                    <Label>Titre </Label>
                    <select><option value="M.">M.</option>
                    <option value="Mme">Mme.</option>
                    <option value="Mlle">Mlle.</option></select>
                  </div>
                  <div className="space-y-0.2 w-[45%]">
                    <Label>Nom </Label>
                    <Input defaultValue="" />
                  </div>
                  <div className="space-y-0.2 w-[45%]">
                    <Label>Prénom </Label>
                    <Input defaultValue="" />
                  </div>
        </div>
        <div className="space-y-2">
                  <div className="space-y-1">
                    <Label>Numéro de Mobile </Label>
                  <PhoneInput
                    placeholder="Entrez votre numéro de téléphone"
                    onChange={setPhoneValue}/>
                  </div>


                  <div className="space-y-1">
                    <Label>Adresse Email </Label>
                    <Input type="email" defaultValue="" />
                  </div>
                  <div className="space-y-1">
                    <Label>Confirmer l'Adresse Email </Label>
                    <Input type="email" defaultValue="" />
                  </div>
                  <div className="space-y-1 space-x-2 flex">
                    <Checkbox  name="jesuispassager"/><Label>Je suis passager sur ce vol </Label>

                  </div>

            </div>

            <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
            <span className="text-3xl lg:text-2xl font-semibold space-y-1">
        Qui voyage?
        </span>
            <div className="space-y-1 space-x-2 flex  flex-col sm:flex-row sm:items-center space-y-6 sm:space-y-0">
            <div className="space-y-0.2 flex flex-col">
            
            <Icon path={isManOrWomen===1?mdiAccountTie:mdiAccountTieWoman} size={2}/>
            <small>1 Adult</small>
            </div>
                  <div className="space-y-0.2">
                    <Label>Titre </Label>
                    <select onChange={ (e: React.ChangeEvent<HTMLSelectElement>) => setIsManOrWomen(Number(e.target.value)) }><option value="1">M.</option>
                    <option value="2">Mme.</option>
                    <option value="3">Mlle.</option></select>
                  </div>
                  <div className="space-y-0.2 w-[45%]">
                    <Label>Nom </Label>
                    <Input defaultValue="" />
                  </div>
                  <div className="space-y-0.2 w-[45%]">
                    <Label>Prénom </Label>
                    <Input defaultValue="" />
                  </div>
        </div>



 
      <div id="ValidateBTN" className="pt-8 w-full space-y-8">
      <ButtonPrimary onClick ={() => { setReservationStep(8); reservationStep=8; }} className="w-full top-[5px]">Valider</ButtonPrimary>
      </div>

      </div>
)
else
return(
  <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
        <h2 className="text-3xl lg:text-4xl font-semibold">
        Confirmation et Paiement
        </h2>

        <div>
          <h3 className="text-2xl font-semibold">Payer avec</h3>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 my-5"></div>

          <div className="mt-6">
            <Tab.Group>
              <Tab.List className="flex my-5 gap-1">
                <Tab as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={`px-4 py-1.5 sm:px-6 sm:py-2.5  rounded-full flex items-center justify-center focus:outline-none ${
                        selected
                          ? "bg-green-600 dark:bg-neutral-200 text-white dark:text-neutral-900"
                          : "text-neutral-6000 dark:text-neutral-400 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                      }`}
                    >
                      <img className="w-12" src={iatalogoheader} alt="IATA" />

                    </button>
                  )}
                </Tab>
                <Tab as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={`px-4 py-1.5 sm:px-6 sm:py-2.5  rounded-full flex items-center justify-center focus:outline-none  ${
                        selected
                          ? "bg-green-600 dark:bg-neutral-200 text-white dark:text-neutral-900"
                          : " text-neutral-6000 dark:text-neutral-400 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                      }`}
                    >
                      <span className="mr-2.5">Credit card (Integration BDL)</span>
                      <img className="w-8" src={visaPng} alt="visa" />
                      <img
                        className="w-8"
                        src={mastercardPng}
                        alt="mastercard"
                      />
                    </button>
                  )}
                </Tab>
                <Tab as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={`px-4 py-1.5 sm:px-6 sm:py-2.5  rounded-full flex items-center justify-center focus:outline-none  ${
                        selected
                          ? "bg-green-600 dark:bg-neutral-200 text-white dark:text-neutral-900"
                          : " text-neutral-6000 dark:text-neutral-400 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                      }`}
                    >
                      <span className="mr-2.5">Credit card</span>
                      <img className="w-8" src={visaPng} alt="visa" />
                      <img
                        className="w-8"
                        src={mastercardPng}
                        alt="mastercard"
                      />
                    </button>
                  )}
                </Tab>
             </Tab.List>

              <Tab.Panels>
                <Tab.Panel className="space-y-5">

{  /*              <iframe title="IATA PAYMENT" id="placeholder_iframe" src="https://ifg.iata.org/webi/wim/wia/#/?lang=%5B%22en%22%2C%22fr%22%2C%22ar%22%5D&amp;access_token=eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJ0YTUxNWlsQGlmZy5pYXRhLm9yZyIsInRyYW5zYWN0aW9uIjoiMWRkZmYxYjUtOGZmZC00YjJiLWI5MzAtYWEzZGExZDM3MTdkIiwic2lnbmF0dXJlIjoiQkkvSjJNZlpKK0RXcnZUaXpGZ0s4dz09IiwiZXhwIjoxNzEwMTY4NTk2fQ.rITBHdqsqe1aHDiCXF9UUr-yzmJUtfihuBeKPkDb8ycLoldVuGGmukYWcZ8Ovo9rQ-hNQ4oPN58OQptYwJRiLA" width="372" height="160"  sandbox="allow-scripts allow-forms allow-modals allow-same-origin allow-popups allow-popups-to-escape-sandbox allow-storage-access-by-user-activation"></iframe>
  */}

                </Tab.Panel>
                <Tab.Panel className="space-y-5">
                <div>

      <MicroForm />


  </div>
                  </Tab.Panel>
                  <Tab.Panel className="space-y-5">
                <div>




  </div>
                  </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>

          </div>
        </div> 
        </div>

    );
  };








if ((reservationStep===2)|| (reservationStep===3) || (reservationStep===8)|| (reservationStep===9))  return (
  <div
    className={`nc-SectionGridFilterCard ${className}`}
    data-nc-id="SectionGridFilterCard"
  >
    

                <div className={`nc-CheckOutPagePageMain ${className}`}>
      <main className="container xl:pl-0 xl:pr-0 xl:mr-0 xl:pr-0 mt-11 flex flex-col-reverse lg:flex-row">
        <div className="w-full lg:w-3/5 xl:w-2/3 lg:pr-10 ">{renderMain()}</div>
        <div className="hidden lg:block flex-grow">{renderSidebar()}</div>
      </main>
    </div>


                </div> );
                else
    



  return (
    <div
      className={`nc-SectionGridFilterCard ${className}`}
      data-nc-id="SectionGridFilterCard"
    >
      {renderControlSelections()}
      <div className={scrollPosition<603?"absolute top-[170px] right-[-100px] z-10 mt-3 w-screen max-w-sm w-[350px] hidden":"fixed top-[100px] right-0 z-10 mt-3 w-screen max-w-sm w-[350px] hidden"}>
                    <div className="rounded-2xl bg-white dark:bg-neutral-800 overflow-hidden nc-custom-shadow-1">
                      <div className="relative p-6">
                        <span className="text-xl font-semibold">VOTRE RÉSERVATION</span>
                        <div className="w-full border-b border-neutral-200 dark:border-neutral-700 mt-4">
                        <p><strong>{localStorage.nbrPassengers} Voyageur{localStorage.nbrPassengers>1?"s":""} : </strong>
                        ({localStorage.adultInputValue==="0"?"":(localStorage.adultInputValue+" Adult"+(localStorage.adultInputValue>1?"s":""))}
                        {localStorage.childrenInputValue==="0"?"":", "+(localStorage.childrenInputValue+" Enfant"+(localStorage.childrenInputValue>1?"s":""))}
                        {localStorage.infantsInputValue==="0"?"":" et "+(localStorage.infantsInputValue+" Bébé"+(localStorage.infantsInputValue>1?"s":""))})</p>
                        <span>{strOrigin_rcd+" - "+strDestination_rcd+" : "+localStorage.flightType}</span>
                        </div>
                        <span><strong> Vol Aller :</strong></span>
                        <div className="w-full border-b border-neutral-200 dark:border-neutral-700 mt-4" dangerouslySetInnerHTML={{ __html: infoVolAllee }}>
                        </div>
                        <span><strong> Vol Retour :</strong></span>
                        <div className="w-full border-b border-neutral-200 dark:border-neutral-700 mt-4" dangerouslySetInnerHTML={{ __html: infoVolRetour }}>
                        </div>
                        <div className={infoVolRetour==='Selectionnez votre vol retour!'?"hidden":""}>
                        <b>Total : {totalPrice}</b>
                        <ButtonPrimary onClick ={() => { setReservationStep(2); reservationStep=2; }} className="right-[-50px] top-[5px]">Valider</ButtonPrimary>

                        </div>
                      </div>
                    </div>
                  </div>
      
      <br/>
      <Heading2
        heading={strCodeAirpDep+' - '+strCodeAirpArv}
        subHeading={
          <span className="block text-neutral-500 dark:text-neutral-400 mt-3">
            
            <span className="mx-2">·</span>
            {localStorage.flightType}
            <span className="mx-2">·</span>{localStorage.nbrPassengers} Passager{localStorage.nbrPassengers>1?"s":""} 
          </span>
        }
      />
{/*      <div className="mb-8 lg:mb-11">
        <TabFilters />
      </div>
      */}

      <h2><b>{departValide?"Vol":"Sélectionnez votre vol"} au départ de {strCodeAirpDep} vers {strCodeAirpArv} {departValide?"Validé":""}</b></h2>
      

      <div className="lg:p-10 lg:bg-neutral-50 lg:dark:bg-black/20 grid grid-cols-1 gap-6 rounded-3xl">
        {rsltData[0].map((item:any, index:any) => ( (item.fareOfFlights[0]!=null)&&(!departValide || (activeDepartIndex === index)) ?
          <FlightCard defaultOpen={index} validee={departValide} active={activeDepartIndex === index} key={index} data={item} infoVol={infoVolAllee} onChange={SetInfoVolAllee}  />:""
        ))}

      </div>
<br/>
{rsltData[1]?      <h2><b>{retourValide?"Vol":"Sélectionnez votre vol"} au retour de {strCodeAirpArv} vers {strCodeAirpDep} {departValide?"Validé":""}</b></h2>
      :""}

{rsltData[1]?
      <div className="lg:p-10 lg:bg-neutral-50 lg:dark:bg-black/20 grid grid-cols-1 gap-6 rounded-3xl">
        {rsltData[1].map((item:any, index:any) => ( (item.fareOfFlights[0]!=null)&&(!retourValide || (activeRetourIndex === index))  ?
          <FlightCard defaultOpen={index} validee={retourValide} active={activeRetourIndex === index}  key={index} data={item} infoVol={infoVolRetour} onChange={SetInfoVolRetour} />:""
        ))}
{/*
        <div className="flex mt-12 justify-center items-center">
          <ButtonPrimary loading>Voir plus</ButtonPrimary>
        </div>  */}
        </div>
      :""}
<br/>
        <div className={`lg:p-10 lg:bg-neutral-50 lg:dark:bg-black/20 grid grid-cols-1 gap-6 rounded-3xl ${
            (retourValide && departValide)||(departValide && !rsltData[1]) ? "" : "hidden"
          }`}
          >

        <div
      className={`nc-FlightCardgroup p-4 sm:p-6 relative bg-white dark:bg-neutral-900 border border-neutral-100
     dark:border-neutral-800 rounded-2xl overflow-hidden hover:shadow-lg transition-shadow space-y-6 flex`}
    >

      <div
        className={`w-full sm:pr-20 relative  `}
      >

<div className="flex w-full justify-right items-right">
        <div className="ml-4 space-y-1 text-sm">
             <span className="w-10"><Icon path={mdiBagChecked} color="green" size={2} /></span>
             </div>
        <div className="w-full ml-8 space-y-1 text-sm text-right flex flex-col">
        <span className="text-neutral-800"><b>TOTAL</b></span>
        <span className=" font-semibold font-medium text-lg"><b>{totalPrice}</b></span>
        </div>
        <div className="ml-4 space-y-1 text-sm text-right w-[30%]">
        <span className="justify-right"><ButtonPrimary onClick ={() => { setReservationStep(2); reservationStep=2; }} >Confirmer</ButtonPrimary></span>
        </div>

        </div>


{/*
        <div className="flex mt-12 justify-center items-center">
          <ButtonPrimary loading>Voir plus</ButtonPrimary>
        </div>  */}
        </div>

</div>
</div>


    </div>
  );
};




export default SectionGridFilterCard;
