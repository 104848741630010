import React, { Fragment, useState, FC,createContext, SetStateAction, Dispatch } from "react";
import { Dialog, Tab, Transition } from "@headlessui/react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { XMarkIcon } from "@heroicons/react/24/solid";
import ButtonSubmit from "./ButtonSubmit";
import { useTimeoutFn } from "react-use";
import FlightSearchForm from "./(flight-search-form)/FlightSearchForm";
import { InputsObject } from "../HeroSearchForm/type";
import dayjs from "dayjs";

interface IsrchResultContext {
  inputsObj: InputsObject ;
  setInputsInput: Dispatch<SetStateAction<InputsObject >>;
}

export let srchResultContext = React.createContext<IsrchResultContext>({
  inputsObj: {
    guestAdults: 1,
    guestChildren: 0,
    guestInfants: 0,
    startDate: null,
    endDate: null,
    locationInputPickUp:'',
    locationInputDropOff:'',
    ropOffLocationType:''
  },
  setInputsInput: () => {}
});


const HeroSearchForm2Mobile = () => {


  const [inputsObj, setInputsInput] = useState<InputsObject>({
    guestAdults: 1,
    guestChildren: 0,
    guestInfants: 0,
    startDate: null,
    endDate: null,
    locationInputPickUp:'',
    locationInputDropOff:'',
    ropOffLocationType:''
  });
  const value = { inputsObj, setInputsInput};

  



  const [showModal, setShowModal] = useState(false);


  const [errmsg,updateErr] = useState('');

  // FOR RESET ALL DATA WHEN CLICK CLEAR BUTTON
  const [showDialog, setShowDialog] = useState(false);
  let [, , resetIsShowingDialog] = useTimeoutFn(() => setShowDialog(true), 1);
  //
  function closeModal() {
    setShowModal(false);
  }

  function openModal() {
    setShowModal(true);
  }

  const renderButtonOpenModal = () => {
    return (
      <button
        onClick={openModal}
        className="relative flex items-center w-full border border-neutral-200 dark:border-neutral-6000 px-4 py-2 pr-11 rounded-full shadow-lg"
      >
        <MagnifyingGlassIcon className="flex-shrink-0 w-5 h-5" />

        <div className="ml-3 flex-1 text-left overflow-hidden">
          <span className="block font-medium text-sm">D'ou voulez-vous partir?</span>
          <div className="block mt-0.5 text-xs font-light text-neutral-500 dark:text-neutral-400 ">
            <span className="line-clamp-1">
              Aéroport de départ
            </span>
          </div>
        </div>

        <span className="absolute right-2 top-1/2 transform -translate-y-1/2 w-9 h-9 flex items-center justify-center rounded-full border border-neutral-200 dark:border-neutral-6000 dark:text-neutral-300">
          <svg
            viewBox="0 0 16 16"
            aria-hidden="true"
            role="presentation"
            focusable="false"
            className="block w-4 h-4"
            fill="currentColor"
          >
            <path d="M5 8c1.306 0 2.418.835 2.83 2H14v2H7.829A3.001 3.001 0 1 1 5 8zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm6-8a3 3 0 1 1-2.829 4H2V4h6.17A3.001 3.001 0 0 1 11 2zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"></path>
          </svg>
        </span>
      </button>
    );
  };

  return (
    <div className="HeroSearchForm2Mobile">
      {renderButtonOpenModal()}
      <Transition appear show={showModal} as={Fragment}>
        <Dialog
          as="div"
          className="HeroSearchFormMobile__Dialog relative z-max"
          onClose={closeModal}
        >
          <div className="fixed inset-0 bg-neutral-100 dark:bg-neutral-900">
            <div className="flex h-full">
              <Transition.Child
                as={Fragment}
                enter="ease-out transition-transform"
                enterFrom="opacity-0 translate-y-52"
                enterTo="opacity-100 translate-y-0"
                leave="ease-in transition-transform"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-52"
              >
                <Dialog.Panel className="relative h-full overflow-hidden flex-1 flex flex-col justify-between ">
                  {showDialog && (
                    <Tab.Group manual>
                      <div className="absolute left-4 top-4">
                        <button className="" onClick={closeModal}>
                          <XMarkIcon className="w-5 h-5 text-black dark:text-white" />
                        </button>
                      </div>

<br/>
                      <div className="flex-1 pt-3 px-1.5 sm:px-4 flex overflow-hidden">
                        <Tab.Panels className="flex-1 overflow-y-auto hiddenScrollbar py-4">
                          <Tab.Panel>
                            <div className="transition-opacity animate-[myblur_0.4s_ease-in-out]">

                            <srchResultContext.Provider value={{inputsObj, setInputsInput}}>
                            <FlightSearchForm  />
                            </srchResultContext.Provider>


                            </div>
                          </Tab.Panel>
                        </Tab.Panels>
                      </div>
                      <div>

                      {
      errmsg==='erreur1'?<span className="text-red-600 ml-6 mt-2">Veuillez selectionner un aéroport de départ !</span>:''
    }
    {
      errmsg==='erreur2'?<span className="text-red-600 m-6 mt-2">Veuillez selectionner un aéroport d'arrivée !</span>:''
    }
    {
      errmsg==='erreur3'?<span className="text-red-600 ml-6 mt-2">Veuillez selectionner une date de départ valide !</span>:''
    }
    {
      errmsg==='erreur4'?<span className="text-red-600 ml-6 mt-2">Veuillez selectionner une date de d'arrivèe valide !</span>:''
    }
                      </div>
                      <div className="px-4 py-3 bg-white dark:bg-neutral-900 border-t border-neutral-200 dark:border-neutral-700 flex justify-between">
                        <button
                          type="button"
                          className="underline font-semibold flex-shrink-0"
                          onClick={() => {
                            setShowDialog(false);
                            resetIsShowingDialog();
                          }}
                        >
                          Initialiser tout
                        </button>
                        <ButtonSubmit
                          onClick={() => {


                            const strStartDate=dayjs(inputsObj.startDate).format("YYYYMMDD");
                            let strEndDate="NR";
                            if (inputsObj.endDate!==null) strEndDate=dayjs(inputsObj.endDate).format("YYYYMMDD");
                            
                            localStorage.flightType=inputsObj.ropOffLocationType;
//                            localStorage.nbrPassengers=inputsObj.guestAdults+inputsObj.guestChildren+inputsObj.guestInfants;
//                            localStorage.adultInputValue=inputsObj.guestAdults;
//                            localStorage.childrenInputValue=inputsObj.guestChildren;
//                            localStorage.infantsInputValue=inputsObj.guestInfants;
                        
                        
                        if (localStorage.devise ==="") localStorage.devise ="DZD";
                        let strFlyType="True";
                        if (inputsObj.ropOffLocationType==="Aller simple") strFlyType="False"; 
                            //(startDate?(startDate.getFullYear()+''+(startDate.getMonth()+1).toString().padStart(2, "0")+''+(startDate.getD).toString().padStart(2, "0")+''):'');
                        //    flyType="",
                        //    adultInputValue="",
                        //    childrenInputValue="",
                        //    infantsInputValue="",
                        if (!localStorage.devise) localStorage.devise="DZD";
                        //endLoc="CDG";
                        let startLoc=( ((inputsObj.locationInputPickUp?inputsObj.locationInputPickUp:"").split('-'))[0]).substr(-4).trim();
                        let endLoc=( ((inputsObj.locationInputDropOff?inputsObj.locationInputDropOff:"").split('-'))[0] ).substr(-4).trim();
//                            const url = "https://api.tassiliairlines.dz.tl/tassapi/?from="+startLoc+"&to="+endLoc+"&dated="+strStartDate+"&dater="+strEndDate+"&adult="+adultInputValue+"&enfant="+childrenInputValue+"&bebe="+infantsInputValue+"&devise="+localStorage.devise+"&roundtrip="+strFlyType;
updateErr('');
if ((startLoc===null)||(startLoc==='')) {  updateErr("erreur1");  return; }
if ((endLoc===null)||(endLoc==='')) {  updateErr("erreur2");  return; }
if ( ((strEndDate==='Invalid Date')||(strEndDate==='NR')||(strEndDate==='')) && (strFlyType=='True') ) {  updateErr("erreur4");  return; }
if  ((strStartDate==='Invalid Date')||(strStartDate==='NR')||(strStartDate===''))   {  updateErr("erreur3");  return; }



                            const urlr = "https://fly.tassiliairlines.com/B2C/fr/Home/Availability/"+startLoc+"/"+endLoc+"/"+strStartDate+"/"+strEndDate+"/"+inputsObj.guestAdults+"/"+inputsObj.guestChildren+"/0/"+inputsObj.guestInfants+"/"+localStorage.devise+"/"+strFlyType+"/0#!";
                        
//                            window.close();
                        
                            window.open(urlr,"_self");
                        
//                            localStorage.srchurl=url;
                        
                        
                        
                        console.log(urlr);
                        

                           // closeModal();
                          }}
                        />
                      </div>
                    </Tab.Group>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default HeroSearchForm2Mobile;
