import { DEMO_POSTS } from "data/posts";
import { PostDataType } from "data/types";
import React from "react";
import { Link } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Comment from "shared/Comment/Comment";
import NcImage from "shared/NcImage/NcImage";
import SocialsList from "shared/SocialsList/SocialsList";
import Textarea from "shared/Textarea/Textarea";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import { Helmet } from "react-helmet";
import imageAero1 from "images/talAirplane1.jpg";
import imageFlotteTal from "images/flotte-tal1.jpg";
import Swipi from 'swipi'

const BlogSingle = () => {
  const renderHeader = () => {
    return (
      <header className="container rounded-xl">
        <div className="max-w-screen-md mx-auto space-y-5">
          <Badge href="##" color="purple" name="Activité" />
          <h1
            className=" text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl dark:text-neutral-100 max-w-4xl "
            title="Quiet ingenuity: 120,000 lunches and counting"
          >
            Vols navette
          </h1>
          <span className="block text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-1">
          Depuis Avril 2009, une navette quotidienne a été mise en place par Tassili Airlines, pour assurer la liaison Alger – Hassi Messaoud et retour destinée exclusivement aux entreprises.
    
          </span>
          <h4>Partenaires utilisant ce service:</h4>
          <div className="w-full border-b border-neutral-100 dark:border-neutral-800"></div>
        </div>
      </header>
    );
  };

  const renderContent = () => {

    

    return (
      <div
        id="single-entry-content"
        className="prose dark:prose-invert mt-3 prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark"
      >
        <h3>
        Vol quotidien en Boeing 737-800 ou Bombardier Q400:
        </h3>
        <ol>
          <li>
          Alger – Hassi Messaoud : Départ à 18h15 ; Arrivée à 19h30
          </li>
          <li>
          Hassi Messaoud – Alger : Départ à 7h00 ; Arrivée à 8h15
          </li>
        </ol>
        <figure>
          <img
            src="https://api.tassiliairlines.dz.tl/images/petro2.jpeg"
            alt="nc blog"
            className="rounded-2xl"
          />
        </figure>
        <h3>Demande d'information</h3>
        <p>Nous proposons un contrat de prestations de service vous donnant la possibilité de réserver un quota de sièges passagers suivant les jours qui vous conviennent.

        Si vous êtes intéressé par nos vols navette, formulez votre demande ci-dessous. Vous recevrez une offre de service adaptée à vos besoin.
        </p>
        <blockquote>
          <p>
          <small><small>Les informations collectées ne seront communiquées à aucune tierce partie et ne serviront qu'à traiter votre demande.</small></small>
          </p>
        </blockquote>
      </div>
    );
  };

  const renderTags = () => {
    return (
      <div className="max-w-screen-md mx-auto flex flex-wrap">
        <a
          className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
          href="##"
        >
          Location
        </a>
        <a
          className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
          href="##"
        >
          Aéronef
        </a>
        <a
          className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
          href="##"
        >
          Contrat
        </a>
      </div>
    );
  };

  const renderAuthor = () => {
    return (
      <div className="max-w-screen-md mx-auto ">
        <div className="nc-SingleAuthor flex">
          <Avatar sizeClass="w-11 h-11 md:w-24 md:h-24" />
          <div className="flex flex-col ml-3 max-w-lg sm:ml-5 space-y-1">
            <span className="text-xs text-neutral-400 uppercase tracking-wider">
              WRITEN BY
            </span>
            <h2 className="text-lg font-semibold text-neutral-900 dark:text-neutral-200">
              <a href="/">Fones Mimi</a>
            </h2>
            <span className="text-sm text-neutral-500 sm:text-base dark:text-neutral-300">
              There’s no stopping the tech giant. Apple now opens its 100th
              store in China.There’s no stopping the tech giant.
              <a className="text-primary-6000 font-medium ml-1" href="/">
                Readmore
              </a>
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderCommentForm = () => {
    return (
      <div className="max-w-screen-md mx-auto pt-5">
        <h3 className="text-xl font-semibold text-neutral-800 dark:text-neutral-200">
        Manifestation d'intérêt commercial / demande de devis
        </h3>
        <form className="nc-SingleCommentForm mt-5">

                <label className="block">
                  <Label>Votre Nom & Prénom</Label>
                  <Input
                    placeholder="Nom Prénom"
                    type="text"
                    className="mt-1"
                  />
                </label>

                <label className="block">
                  <Label>Société ou organisme</Label>
                  <Input
                    placeholder=""
                    type="text"
                    className="mt-1"
                  />
                </label>

                <label className="block">
                  <Label>Téléphone</Label>
                  <Input
                    placeholder=""
                    type="text"
                    className="mt-1"
                  />
                </label>

                <label className="block">
                  <Label>Votre adresse email</Label>

                  <Input
                    type="email"
                    placeholder="adresse@email.com"
                    className="mt-1"
                  />
                </label>
                <label className="block">
                  <Label>Votre message</Label>
          <Textarea />
          </label>
          <div className="mt-2 space-x-3">
            <ButtonPrimary>Envoyez</ButtonPrimary>
          </div>
        </form>
      </div>
    );
  };

  const renderCommentLists = () => {
    return (
      <div className="max-w-screen-md mx-auto">
        <ul className="nc-SingleCommentLists space-y-5">
          <li>
            <Comment />
            <ul className="pl-4 mt-5 space-y-5 md:pl-11">
              <li>
                <Comment isSmall />
              </li>
            </ul>
          </li>
          <li>
            <Comment />
            <ul className="pl-4 mt-5 space-y-5 md:pl-11">
              <li>
                <Comment isSmall />
              </li>
            </ul>
          </li>
        </ul>
      </div>
    );
  };

  const renderPostRelated = (post: PostDataType) => {
    return (
      <div
        key={post.id}
        className="relative aspect-w-3 aspect-h-4 rounded-3xl overflow-hidden group"
      >
        <Link to={post.href} />
        <NcImage
          className="object-cover w-full h-full transform group-hover:scale-105 transition-transform duration-300"
          src={post.featuredImage}
        />
        <div>
          <div className="absolute bottom-0 inset-x-0 h-1/2 bg-gradient-to-t from-black"></div>
        </div>
        <div className="flex flex-col justify-end items-start text-xs text-neutral-300 space-y-2.5 p-4">
          <Badge name="Categories" />
          <h2 className="block text-lg font-semibold text-white ">
            <span className="line-clamp-2">{post.title}</span>
          </h2>

          <div className="flex">
            <span className="block text-neutral-200 hover:text-white font-medium truncate">
              {post.author.displayName}
            </span>
            <span className="mx-1.5 font-medium">·</span>
            <span className="font-normal truncate">{post.date}</span>
          </div>
        </div>
        <Link to={post.href} />
      </div>
    );
  };


  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 6,
      slidesToSlide: 6// optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };

  return (
    <div className="nc-PageSingle pt-8 lg:pt-16 ">
      <Helmet>
        <title>Tassili Airlines || Mise à disposition</title>
      </Helmet>
      {renderHeader()}
 {/*    <NcImage
        className="w-full rounded-xl"
        containerClassName="container my-10 sm:my-12 "
        src={imageAero1}
  />    */}
        <Swipi
    loop  
    spaceBetweenSlides={0}
    autoplay={true}
    showArrows={false}
    autoplaySpeed={1000}
    slidesNumber={6}
  >
            <div>
                <img src="https://api.tassiliairlines.dz.tl/logoPartners/01.png" alt="any" className='w-36' />
            </div>
            <div>
                <img src="https://api.tassiliairlines.dz.tl/logoPartners/02.png" alt="any2" className='w-36' />
            </div>
            <div>
                <img src="https://api.tassiliairlines.dz.tl/logoPartners/03.png" alt="any3" className='w-36' />
            </div>
            <div>
                <img src="https://api.tassiliairlines.dz.tl/logoPartners/04.png" alt="any3" className='w-36' />
            </div>
            <div>
                <img src="https://api.tassiliairlines.dz.tl/logoPartners/05.png" alt="any3" className='w-36' />
            </div>
            <div>
                <img src="https://api.tassiliairlines.dz.tl/logoPartners/06.png" alt="any3" className='w-36' />
            </div>
            <div>
                <img src="https://api.tassiliairlines.dz.tl/logoPartners/07.png" alt="any3" className='w-36' />
            </div>
            <div>
                <img src="https://api.tassiliairlines.dz.tl/logoPartners/08.png" alt="any3" className='w-36' />
            </div>
            <div>
                <img src="https://api.tassiliairlines.dz.tl/logoPartners/09.png" alt="any3" className='w-36' />
            </div>
            <div>
                <img src="https://api.tassiliairlines.dz.tl/logoPartners/10.png" alt="any3" className='w-36' />
            </div>
            <div>
                <img src="https://api.tassiliairlines.dz.tl/logoPartners/11.png" alt="any3" className='w-36' />
            </div>
            <div>
                <img src="https://api.tassiliairlines.dz.tl/logoPartners/12.png" alt="any3" className='w-36' />
            </div>
            </Swipi>

      <div className="nc-SingleContent container space-y-10">
        {renderContent()}
        {/*renderTags()*/}
        <div className="max-w-screen-md mx-auto border-b border-t border-neutral-100 dark:border-neutral-700"></div>
        {/*renderAuthor()*/}
        {renderCommentForm()}
        {/*renderCommentLists()*/}
      </div>

<br/>
{/*
      <div className="relative bg-neutral-100 dark:bg-neutral-800 py-16 lg:py-28 mt-16 lg:mt-24">
        <div className="container ">
          <h2 className="text-3xl font-semibold">Autres services</h2>
          <div className="mt-10 grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 md:gap-8">
            {DEMO_POSTS.filter((_, i) => i < 4).map(renderPostRelated)}
          </div>
        </div>
      </div>

*/}

    </div>
  );
};

export default BlogSingle;
