import React from "react";
import { DEMO_POSTS } from "data/posts";
import { Helmet } from "react-helmet";
import SectionAds from "./SectionAds";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SectionMagazine5 from "./SectionMagazine5";
import SectionLatestPosts from "./SectionLatestPosts";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";

// DEMO DATA
const POSTS = DEMO_POSTS;

// DEMO POST FOR MAGAZINE SECTION
const MAGAZINE1_POSTS = POSTS.filter((_, i) => i >= 0 && i < 8);
//

const BlogPage: React.FC = () => {
  return (
    <div className="nc-BlogPage overflow-hidden relative">
      <Helmet>
        <title>Tassili airlines || Vols réguliers</title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />
      {/* ======== ALL SECTIONS ======== */}
      {/* ======= START CONTAINER ============= */}
      <div className="container relative">

        {/* === SECTION 1 === 
        <SectionAds />*/}
<div className="content-center w-full">
<iframe className="content-center relative left-1/2 transform -translate-x-1/2" title="IATA PAYMENT" id="placeholder_iframe" src="https://api.tassiliairlines.dz.tl/timetable/TIMETABLE_INTERNATIONAL.html" width="1000" height="780"  sandbox="allow-scripts allow-forms allow-modals allow-same-origin allow-popups allow-popups-to-escape-sandbox allow-storage-access-by-user-activation"></iframe>
</div>
        {/* === SECTION 8 === 
        <SectionLatestPosts className="py-16 lg:py-28" />*/}

        {/* === SECTION 1 === */}
        <SectionSubscribe2 className="pb-16 lg:pb-28" />
      </div>
    </div>
  );
};

export default BlogPage;
