import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
import PageHome from "containers/PageHome/PageHome";
import Page404 from "containers/Page404/Page404";
import PageContact from "containers/PageContact/PageContact";
import PageAbout from "containers/PageAbout/PageAbout";
import PageSignUp from "containers/PageSignUp/PageSignUp";
import PageLogin from "containers/PageLogin/PageLogin";
import PageSubcription from "containers/PageSubcription/PageSubcription";
import BlogPage from "containers/BlogPage/BlogPage";
import MiseADisposition from "containers/BlogPage/miseADisposition";
import volsALaDemande from "containers/BlogPage/volsALaDemande";
import volsCharters from "containers/BlogPage/volsCharters";
import volsReguliers from "containers/BlogPage/volsReguliers";
import volsNavette from "containers/BlogPage/volsNavette";
import volsCargo from "containers/BlogPage/volsCargo";
import travailAerien from "containers/BlogPage/travailAerien";
import progNational from "containers/BlogPage/progNational";
import progInternational from "containers/BlogPage/progInternational";
import chartePDP from "containers/BlogPage/chartePDP";
import conditionsTransport from "containers/BlogPage/conditionsTransport";
import mentionsLegales from "containers/BlogPage/mentionsLegales";
import bagage from "containers/BlogPage/bagage";
import SiteHeader from "containers/SiteHeader";
import FooterNav from "components/FooterNav";
import useWindowSize from "hooks/useWindowResize";

export const pages: Page[] = [
  { path: "/", exact: true, component: PageHome },
  { path: "/#", exact: true, component: PageHome },
  { path: "/web", exact: true, component: PageHome },
  //
  //
  { path: "/mise-a-disposition", component: MiseADisposition },
  { path: "/vols-a-la-demande", component: volsALaDemande },
  { path: "/vols-charters", component: volsCharters },
  { path: "/vols-reguliers", component: volsReguliers },
  { path: "/vols-navette", component: volsNavette },
  { path: "/vols-cargo", component: volsCargo },
  { path: "/travail-aerien", component: travailAerien },
  { path: "/prog-national", component: progNational },
  { path: "/prog-international", component: progInternational },
  { path: "/charte-pdp", component: chartePDP },
  { path: "/mentions-legales", component: mentionsLegales },
  { path: "/conditions-transport", component: conditionsTransport },
  { path: "/bagage", component: bagage },
  //
  { path: "/contact", component: PageContact },
  { path: "/about", component: PageAbout },
  { path: "/signup", component: PageSignUp },
  { path: "/login", component: PageLogin },
  { path: "/subscription", component: PageSubcription },
  //
  //

  { path: "/web/mise-a-disposition", component: MiseADisposition },
  { path: "/web/vols-a-la-demande", component: volsALaDemande },
  { path: "/web/vols-charters", component: volsCharters },
  { path: "/web/vols-reguliers", component: volsReguliers },
  { path: "/web/vols-navette", component: volsNavette },
  { path: "/web/vols-cargo", component: volsCargo },
  { path: "/web/cargo", component: volsCargo },
  { path: "/web/travail-aerien", component: travailAerien },
  { path: "/web/prog-national", component: progNational },
  { path: "/web/prog-international", component: progInternational },
  { path: "/web/charte-pdp", component: chartePDP },
  { path: "/web/mentions-legales", component: mentionsLegales },
  { path: "/web/conditions-transport", component: conditionsTransport },
  { path: "/web/bagage", component: bagage },
  //
  { path: "/web/contact", component: PageContact },
  { path: "/web/about", component: PageAbout },
  { path: "/web/signup", component: PageSignUp },
  { path: "/web/login", component: PageLogin },
  { path: "/web/destinations", component: volsReguliers },

];

const MyRoutes = () => {
  let WIN_WIDTH = useWindowSize().width;
  if (typeof window !== "undefined") {
    WIN_WIDTH = WIN_WIDTH || window.innerWidth;
  }

  return (
    <BrowserRouter>
      <ScrollToTop />
      <SiteHeader />

      <Routes>
        {pages.map(({ component, path }) => {
          const Component = component;
          return <Route key={path} element={<Component />} path={path} />;
        })}
        <Route element={<Page404 />} />
      </Routes>

      {WIN_WIDTH < 768 && <FooterNav />}
      <Footer />
    </BrowserRouter>
  );
};

export default MyRoutes;
