import { initializeApp } from "firebase/app";
import { getToken, getMessaging, isSupported } from "firebase/messaging";
import { getAnalytics } from "firebase/analytics";
import dotenv from "dotenv";
/*
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FB_API_KEY,
  authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FB_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FB_APP_ID,
  measurementId: process.env.REACT_APP_FB_MEASUREMENT_ID,
};
*/
const firebaseConfig = {
  apiKey: "AIzaSyDp2bxrB5xJqp4XaGsSidq9Q7E3olK2i98",
  authDomain: "tassiliairlines-1716576452103.firebaseapp.com",
  projectId: "tassiliairlines-1716576452103",
  storageBucket: "tassiliairlines-1716576452103.appspot.com",
  messagingSenderId: "334510610768",
  appId: "1:334510610768:web:54f941739571611f937455",
  measurementId: "G-DY3HGEP50X"
};



const UrlFirebaseConfig = new URLSearchParams(
  {
    apiKey: "AIzaSyDp2bxrB5xJqp4XaGsSidq9Q7E3olK2i98",
    authDomain: "tassiliairlines-1716576452103.firebaseapp.com",
    projectId: "tassiliairlines-1716576452103",
    storageBucket: "tassiliairlines-1716576452103.appspot.com",
    messagingSenderId: "334510610768",
    appId: "1:334510610768:web:54f941739571611f937455",
    }.toString()
);

const swUrl = `http://localhost:3006/firebase-messaging-sw.js?${UrlFirebaseConfig}`;


// Initialize Firebase
//const app = initializeApp(firebaseConfig);

export const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);

//export const messaging = getMessaging(firebaseApp);
export const messaging = (async () => {
  try {
    const isSupportedBrowser = await isSupported();
    if (isSupportedBrowser) {
      return getMessaging(firebaseApp);
    }
    console.log("Firebase is not supported in this browser");
    return null;
  } catch (err) {
    console.log(err);
    return null;
  }
})();

// getOrRegisterServiceWorker function is used to try and get the service worker if it exists, otherwise it will register a new one.
export const getOrRegisterServiceWorker = () => {
  if (
    "serviceWorker" in navigator &&
    typeof window.navigator.serviceWorker !== "undefined"
  ) {
    return window.navigator.serviceWorker
      .getRegistration("/firebase-push-notification-scope")
      .then((serviceWorker) => {
        if (serviceWorker) return serviceWorker;
        return window.navigator.serviceWorker.register(
          "/firebase-messaging-sw.js",
          {
            scope: "/firebase-push-notification-scope",
          }
        );
      });
  }
  throw new Error("The browser doesn`t support service worker.");
};
 
// getFirebaseToken function generates the FCM token 
export const getFirebaseToken = async () => {
  try {
    const messagingResolve = await messaging;
    if (messagingResolve) {
      return getOrRegisterServiceWorker().then((serviceWorkerRegistration) => {
        return Promise.resolve(
          getToken(messagingResolve, {
            vapidKey: process.env.REACT_APP_FB_VAPID_KEY,
            serviceWorkerRegistration,
          })
        );
      });
    }
  } catch (error) {
    console.log("An error occurred while retrieving token. ", error);
  }
};