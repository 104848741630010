import { DEMO_POSTS } from "data/posts";
import { PostDataType } from "data/types";
import React from "react";
import { Link } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Comment from "shared/Comment/Comment";
import NcImage from "shared/NcImage/NcImage";
import SocialsList from "shared/SocialsList/SocialsList";
import Textarea from "shared/Textarea/Textarea";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import { Helmet } from "react-helmet";
import imageAero1 from "images/tassili-sante.jpg";
import imageFlotteTal from "images/tassili-feux.jpeg";
import imageFlotteTal1 from "images/tassili-helico.jpeg";

const BlogSingle = () => {
  const renderHeader = () => {
    return (
      <header className="container rounded-xl">
        <div className="max-w-screen-md mx-auto space-y-5">
          <Badge href="##" color="purple" name="Activité" />
          <h1
            className=" text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl dark:text-neutral-100 max-w-4xl "
            title="Quiet ingenuity: 120,000 lunches and counting"
          >
            Travail aérien
          </h1>
          <span className="block text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-1">
          Tassili Airlines propose des contrats de mise à disposition d’appareils pour une période donnée, généralement d’une (01) année ou plus, où Tassili Airlines « Fréteur » affecte au client « Affréteur » des aéronefs contre le paiement d’un forfait mensuel représentant un volume horaire appelé aussi « Minimum Garanti ».
          </span>

          <div className="w-full border-b border-neutral-100 dark:border-neutral-800"></div>
        </div>
      </header>
    );
  };

  const renderContent = () => {
    return (
      <div
        id="single-entry-content"
        className="prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark"
      >
        <h4>
        Tassili Airlines intervient également sur ces différents segments :
        </h4>
        <ul>
        <li>Transport de la petite relève du personnel du secteur des hydrocarbures</li>
        <li>Transport de délégations du secteur des hydrocarbures (TAXI et VIP)</li>
        <li>EVASAN (Evacuation sanitaires)</li>
        <li>Surveillance héliportée des installations industrielles</li>
        <li>Lavage des isolateurs des lignes électriques HT et THT, la thermographie et les inspections visuelles</li>
        <li>Offshore héliporté.</li>
        </ul>
        
        <figure>
          <img
            src={imageFlotteTal}
            alt="nc blog"
            className="rounded-2xl"
          />
        </figure>


        <h4>AGRICOLE</h4>
      <ul>
      <li>Traitement phytosanitaire par voie aérienne ;</li>
      <li>Lutte contre les feux de forêts.</li>
      </ul>
      
      <figure>
          <img
            src={imageAero1}
            alt="nc blog"
            className="rounded-2xl"
          />
        </figure>

      <h4>AUTRES</h4>
      <ul>
      <li>Transport de passagers version TAXI et VIP ;</li>
      <li>EVASAN (Evacuation sanitaires) ;</li>
      <li>Des levés topographiques et modélisation en 3D héliportés par satellite «laser» ;</li>
      <li>Du service de prises de vue aériennes, documentaires, publicités, film,……</li>
      </ul>

        <h3>Demande d'information</h3>
        <p>
        Si vous êtes intéressé par l'un des services cités précédemment, formulez votre demande ci-dessous. Vous recevrez le modèle de contrat, les disponibilités et vous serez contacté par un opérateur dans les plus brefs délés.
        </p>
        <blockquote>
          <p>
          <small><small>Les informations collectées ne seront communiquées à aucune tierce partie et ne serviront qu'à traiter votre demande.</small></small>
          </p>
        </blockquote>
      </div>
    );
  };

  const renderTags = () => {
    return (
      <div className="max-w-screen-md mx-auto flex flex-wrap">
        <a
          className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
          href="##"
        >
          Location
        </a>
        <a
          className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
          href="##"
        >
          Aéronef
        </a>
        <a
          className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
          href="##"
        >
          Contrat
        </a>
      </div>
    );
  };

  const renderAuthor = () => {
    return (
      <div className="max-w-screen-md mx-auto ">
        <div className="nc-SingleAuthor flex">
          <Avatar sizeClass="w-11 h-11 md:w-24 md:h-24" />
          <div className="flex flex-col ml-3 max-w-lg sm:ml-5 space-y-1">
            <span className="text-xs text-neutral-400 uppercase tracking-wider">
              WRITEN BY
            </span>
            <h2 className="text-lg font-semibold text-neutral-900 dark:text-neutral-200">
              <a href="/">Fones Mimi</a>
            </h2>
            <span className="text-sm text-neutral-500 sm:text-base dark:text-neutral-300">
              There’s no stopping the tech giant. Apple now opens its 100th
              store in China.There’s no stopping the tech giant.
              <a className="text-primary-6000 font-medium ml-1" href="/">
                Readmore
              </a>
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderCommentForm = () => {
    return (
      <div className="max-w-screen-md mx-auto pt-5">
        <h3 className="text-xl font-semibold text-neutral-800 dark:text-neutral-200">
          Manifestation d'intéré pour la mise à disposition d’appareils
        </h3>
        <form className="nc-SingleCommentForm mt-5">

                <label className="block">
                  <Label>Votre Nom & Prénom</Label>
                  <Input
                    placeholder="Nom Prénom"
                    type="text"
                    className="mt-1"
                  />
                </label>

                <label className="block">
                  <Label>Société ou organisme</Label>
                  <Input
                    placeholder=""
                    type="text"
                    className="mt-1"
                  />
                </label>

                <label className="block">
                  <Label>Téléphone</Label>
                  <Input
                    placeholder=""
                    type="text"
                    className="mt-1"
                  />
                </label>

                <label className="block">
                  <Label>Votre adresse email</Label>

                  <Input
                    type="email"
                    placeholder="adresse@email.com"
                    className="mt-1"
                  />
                </label>
                <label className="block">
                  <Label>Votre message</Label>
          <Textarea />
          </label>
          <div className="mt-2 space-x-3">
            <ButtonPrimary>Envoyez</ButtonPrimary>
          </div>
        </form>
      </div>
    );
  };

  const renderCommentLists = () => {
    return (
      <div className="max-w-screen-md mx-auto">
        <ul className="nc-SingleCommentLists space-y-5">
          <li>
            <Comment />
            <ul className="pl-4 mt-5 space-y-5 md:pl-11">
              <li>
                <Comment isSmall />
              </li>
            </ul>
          </li>
          <li>
            <Comment />
            <ul className="pl-4 mt-5 space-y-5 md:pl-11">
              <li>
                <Comment isSmall />
              </li>
            </ul>
          </li>
        </ul>
      </div>
    );
  };

  const renderPostRelated = (post: PostDataType) => {
    return (
      <div
        key={post.id}
        className="relative aspect-w-3 aspect-h-4 rounded-3xl overflow-hidden group"
      >
        <Link to={post.href} />
        <NcImage
          className="object-cover w-full h-full transform group-hover:scale-105 transition-transform duration-300"
          src={post.featuredImage}
        />
        <div>
          <div className="absolute bottom-0 inset-x-0 h-1/2 bg-gradient-to-t from-black"></div>
        </div>
        <div className="flex flex-col justify-end items-start text-xs text-neutral-300 space-y-2.5 p-4">
          <Badge name="Categories" />
          <h2 className="block text-lg font-semibold text-white ">
            <span className="line-clamp-2">{post.title}</span>
          </h2>

          <div className="flex">
            <span className="block text-neutral-200 hover:text-white font-medium truncate">
              {post.author.displayName}
            </span>
            <span className="mx-1.5 font-medium">·</span>
            <span className="font-normal truncate">{post.date}</span>
          </div>
        </div>
        <Link to={post.href} />
      </div>
    );
  };

  return (
    <div className="nc-PageSingle pt-8 lg:pt-16 ">
      <Helmet>
        <title>Tassili Airlines || Mise à disposition</title>
      </Helmet>
      {renderHeader()}
      <NcImage
        className="w-100 rounded-xl content-center relative left-1/2 transform -translate-x-1/2"
        containerClassName="container my-10 sm:my-12 "
        src={imageFlotteTal1}
      />

      <div className="nc-SingleContent container space-y-10">
        {renderContent()}
        {renderTags()}
        <div className="max-w-screen-md mx-auto border-b border-t border-neutral-100 dark:border-neutral-700"></div>
        {/*renderAuthor()*/}
        {renderCommentForm()}
        {/*renderCommentLists()*/}
      </div>

<br/>
{/*
      <div className="relative bg-neutral-100 dark:bg-neutral-800 py-16 lg:py-28 mt-16 lg:mt-24">
        <div className="container ">
          <h2 className="text-3xl font-semibold">Autres services</h2>
          <div className="mt-10 grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 md:gap-8">
            {DEMO_POSTS.filter((_, i) => i < 4).map(renderPostRelated)}
          </div>
        </div>
      </div>

*/}

    </div>
  );
};

export default BlogSingle;
