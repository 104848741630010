import rightImg from "images/about-hero-right.png";
import React, { FC } from "react";
import SectionFounder from "./SectionFounder";
import SectionOrgan from "./SectionOrgan";
import SectionStatistic from "./SectionStatistic";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionHero from "./SectionHero";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";

export interface PageAboutProps {
  className?: string;
}

const PageAbout: FC<PageAboutProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id="PageAbout"
    >
      <Helmet>
        <title>Tassili Airlines - A propos</title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className="container py-16 lg:py-28 space-y-14 lg:space-y-14">
        <SectionHero
          rightImg={rightImg}
          heading="🛩️ À propos de Tassili Airlines."
          btnText=""
          subHeading="Tassili Airlines a été créée en Mars 1998. La compagnie concentre l’essentiel de ses activités au profit du secteur pétrolier en opérant des Charters et Navettes aussi bien en Domestique qu’en International. "
        />

        <SectionFounder />

        <SectionOrgan />

      {/*  <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay uniqueClassName="PageAbout_" />
        </div>
  */}
  {/*      <SectionStatistic />   */}

        <SectionSubscribe2 />
      </div>
    </div>
  );
};

export default PageAbout;
