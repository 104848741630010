import React, { useState } from "react";
import LocationInput from "../LocationInput";
import GuestsInput from "../GuestsInput";
import DatesRangeInput from "../DatesRangeInput";
import { InputsObject, GuestsObject } from "components/HeroSearchForm/type";
import converSelectedDateToString from "utils/converSelectedDateToString";
import { srchResultContext } from '../HeroSearchForm2Mobile'; 



const FlightSearchForm = () => {


  let { inputsObj , setInputsInput } = React.useContext(srchResultContext);


  
//  inputsObj: InputsObject ;
//  setInputsInput: Dispatch<SetStateAction<InputsObject >>;


  //
  const [fieldNameShow, setFieldNameShow] = useState<
    "locationPickup" | "locationDropoff" | "dates" | "guests" | "general"
  >("locationPickup");
  //
  const [locationInputPickUp, setLocationInputPickUp] = useState("");
  const [locationInputDropOff, setLocationInputDropOff] = useState("");
  const [startDate,setStartDate] = useState<Date | null>(null);
  const [endDate,setEndDate] = useState<Date | null>(null);

  const [dropOffLocationType, setDropOffLocationType] = useState<
    "Aller-retour" | "Aller simple" | ""
  >("Aller-retour");
  const [flightClassState, setFlightClassState] = useState("Économique");

  const [guestInput, setGuestInput] = useState<GuestsObject>({
    guestAdults: 1,
    guestChildren: 0,
    guestInfants: 0,
  });

  const renderInputLocationPickup = () => {
    const isActive = fieldNameShow === "locationPickup";
    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 ${
          isActive
            ? "rounded-2xl shadow-lg"
            : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
        }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow("locationPickup")}
          >
            <span className="text-neutral-400">D'ou voulez-vous partir?</span>
            <span>{locationInputPickUp || "Aéroport de départ"}</span>
          </button>
        ) : (
          <LocationInput
            headingText="D'ou voulez-vous partir?"
            defaultValue={locationInputPickUp}
            onChange={(value) => {
              setLocationInputPickUp(value);
              inputsObj.locationInputPickUp=value;
              setInputsInput(inputsObj);
              setFieldNameShow("dates");
            }}
          />
        )}
      </div>
    );
  };

  const renderInputLocationDropoff = () => {
    const isActive = fieldNameShow === "locationDropoff";
    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 ${
          isActive
            ? "rounded-2xl shadow-lg"
            : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
        }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow("locationDropoff")}
          >
            <span className="text-neutral-400">Où voulez-vous aller?</span>
            <span>{locationInputDropOff || "Aéroport d'arrivée"}</span>
          </button>
        ) : (
          <LocationInput
            headingText="Où voulez-vous aller?"
            defaultValue={locationInputDropOff}
            onChange={(value) => {
              setLocationInputDropOff(value);
              inputsObj.locationInputDropOff=value;
              setInputsInput(inputsObj);
              setFieldNameShow("dates");
            }}
          />
        )}
      </div>
    );
  };

  const renderInputDates = () => {
    const isActive = fieldNameShow === "dates";

    function onChangeDateRange(start:Date|null,end:Date|null) {
      setStartDate(start);
      setEndDate(end);
    }

    inputsObj.startDate=startDate;
    inputsObj.endDate=endDate;
    setInputsInput(inputsObj);    

    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 overflow-hidden ${
          isActive
            ? "rounded-2xl shadow-lg"
            : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
        }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4  `}
            onClick={() => setFieldNameShow("dates")}
          >
            <span className="text-neutral-400">quand est ce que?</span>
            <span>
              {startDate
                ? converSelectedDateToString([startDate, endDate])
                : "Choisir la date"}
            </span>
          </button>
        ) : (
          <DatesRangeInput
          selectsRange={dropOffLocationType !== "Aller simple"}
          flyType={dropOffLocationType}
          flyClass={flightClassState}
          onChange={onChangeDateRange}
        />
        )}
      </div>
    );
  };

  const renderGenerals = () => {
    const isActive = fieldNameShow === "general";
    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 overflow-hidden ${
          isActive
            ? "rounded-2xl shadow-lg"
            : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
        }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow("general")}
          >
            <span className="text-neutral-400">Type de vol?</span>
            <span>{`${dropOffLocationType}, ${flightClassState}`}</span>
          </button>
        ) : (
          <div className="p-5">
            <span className="block font-semibold text-xl sm:text-2xl">
            Type de vol?
            </span>
            <div className="relative mt-5">
              <div className="flex space-x-2">
                <div
                  className={`py-1.5 px-4 flex items-center rounded-full font-medium text-xs cursor-pointer select-none ${
                    dropOffLocationType === "Aller-retour"
                      ? "bg-black shadow-black/10 shadow-lg text-white"
                      : "border border-neutral-300 dark:border-neutral-700"
                  }`}
                  onClick={(e) => { setDropOffLocationType("Aller-retour");
                  inputsObj.ropOffLocationType="Aller-retour";
                  setInputsInput(inputsObj);    
                   }}
                >
                  Aller-retour
                </div>
                <div
                  className={`py-1.5 px-4 flex items-center rounded-full font-medium text-xs cursor-pointer select-none ${
                    dropOffLocationType === "Aller simple"
                      ? "bg-black text-white shadow-black/10 shadow-lg"
                      : "border border-neutral-300 dark:border-neutral-700"
                  }`}
                  onClick={(e) => { setDropOffLocationType("Aller simple"); 
                    inputsObj.ropOffLocationType="Aller simple";
                    setInputsInput(inputsObj);    
                    }}
                >
                  Aller simple
                </div>
              </div>

              <div className="mt-6">
                <label className="text-base font-semibold" htmlFor="">
                  Ticket Class:
                </label>
                <div className="mt-4 grid grid-cols-1 sm:grid-cols-3 gap-4">
                  {renderRadio("class", "Économique", "Économique")}
                  {renderRadio("class", "Affaires", "Affaires")}
                  {renderRadio("class", "Première", "Première")}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderRadio = (
    name: string,
    id: string,
    label: string,
    defaultChecked?: boolean
  ) => {
    return (
      <div className="flex items-center ">
        <input
          defaultChecked={flightClassState === label}
          id={id + name}
          name={name}
          onChange={() => setFlightClassState(label)}
          type="radio"
          className="focus:ring-primary-500 h-6 w-6 text-primary-500 border-neutral-300 !checked:bg-primary-500 bg-transparent"
        />
        <label
          htmlFor={id + name}
          className="ml-3 block text-sm font-medium text-neutral-700 dark:text-neutral-300"
        >
          {label}
        </label>
      </div>
    );
  };

  const renderInputGuests = () => {
    const isActive = fieldNameShow === "guests";
    let guestSelected = "";
    if (guestInput.guestAdults || guestInput.guestChildren) {
      const guest =
        (guestInput.guestAdults || 0) + (guestInput.guestChildren || 0);
      guestSelected += `${guest} passagers`;
    }

    if (guestInput.guestInfants) {
      guestSelected += `, ${guestInput.guestInfants} bébé`;
    }
    inputsObj.guestAdults=guestInput.guestAdults;
    inputsObj.guestChildren=guestInput.guestChildren;
    inputsObj.guestInfants=guestInput.guestInfants;
    setInputsInput(inputsObj);    

    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 overflow-hidden ${
          isActive
            ? "rounded-2xl shadow-lg"
            : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
        }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow("guests")}
          >
            <span className="text-neutral-400">Qui voyage?</span>
            <span>{guestSelected || `Ajouter des voyageurs`}</span>
          </button>
        ) : (
          <GuestsInput defaultValue={guestInput} onChange={setGuestInput} />
        )}
      </div>
    );
  };

  return (
    <div>
      <div className="w-full space-y-5">
        {renderInputLocationPickup()}
        {/*  */}
        {renderInputLocationDropoff()}
        {/*  */}
        {renderGenerals()}
        {/*  */}
        {renderInputDates()}
        {/*  */}
        {renderInputGuests()}
      </div>
    </div>
  );
};

export default FlightSearchForm;
