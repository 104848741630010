import { DEMO_POSTS } from "data/posts";
import { PostDataType } from "data/types";
import React from "react";
import { Link } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Comment from "shared/Comment/Comment";
import NcImage from "shared/NcImage/NcImage";
import SocialsList from "shared/SocialsList/SocialsList";
import Textarea from "shared/Textarea/Textarea";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import { Helmet } from "react-helmet";
import imageAero1 from "images/talAirplane1.jpg";
import imageFlotteTal from "images/flotte-tal1.jpg";

const BlogSingle = () => {
  const renderHeader = () => {
    return (
      <header className="container rounded-xl">
        <div className="space-y-5 text-center">
          <h1
            className=" text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl dark:text-neutral-100 max-w-4xlcontent-center relative left-1/2 transform -translate-x-1/2"
            title="Quiet ingenuity: 120,000 lunches and counting" 
          >
            CONDITIONS GÉNÉRALES DE VENTE
          </h1>

          <div className="w-full border-b border-neutral-100 dark:border-neutral-800"></div>
        </div>
      </header>
    );
  };

  const renderContent = () => {
    return (
      <div
      id="single-entry-content"
      className="prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark"
    >










<p className="text-justify">Notre service de réservation est soumis aux présentes conditions générales de vente dont vous reconnaissez avoir pris connaissance et acceptez préalablement à toute réservation.</p>

<p className="text-justify">Le cas échéant, des conditions particulières indiquées lors de la réservation s’appliqueront en complément des présentes conditions générales. En cas de différends, les conditions particulières prévalent.</p>

<h3>Passeport Biométrique</h3>

<p className="text-justify">Les dispositions réglementaires régissant l’entrée en Algérie des binationaux non détenteurs d’un passeport biométrique algérien, exigent que les titres de voyages, de ou vers l’étranger, ne peuvent être délivrés qu’aux passagers titulaires d’un passeport biométrique algérien ou d’un passeport étranger avec visa d’entrée en Algérie en cours de validité</p>


<h3>Réservation</h3>

<ul>
<li className="text-justify">Vous pouvez effectuer une réservation à tout moment en agence et au minimum 72 heures sur le site internet de Tassili Airlines avant le départ du vol.</li>
<li className="text-justify">Vous pouvez réserver jusqu’à 6 personnes (adulte(s) et/ou enfant(s)) étant précisé que chaque adulte peut voyager avec un bébé sur ses genoux (l’âge et le sexe du bébé doivent être renseignés). Au-delà, vous pouvez effectuer une autre réservation en ouvrant un nouveau dossier.</li>
<li className="text-justify">Pour effectuer une réservation, vous devez obligatoirement être majeur d’âge. La responsabilité de Tassili Airlines ne pourra être engagée dans le cas d’une utilisation frauduleuse par une personne mineure au moment de la réservation.</li>
<li className="text-justify">Vous devez, en outre, vous assurer que vous êtes en règle avec les autorités locales du pays de destination pour les formalités de douanes, de police et de santé. Vous êtes de manière générale responsable de l’accomplissement de toutes les formalités requises.</li>
</ul>

<h3>Paiement</h3>

<h4>Le tarif :</h4>
<ul>
<li className="text-justify">Les tarifs sont indiqués en Dinar Algérien et en Euro et incluent le montant des taxes.</li>
<li className="text-justify">L’application de certains tarifs peut être soumise à des conditions particulières indiquées lors de la réservation.</li>
<li className="text-justify">Les offres promotionnelles et tarifs spéciaux (tel que pour les bébés et enfants) font l’objet d’un quota de places limitées : ils sont donc proposés sous réserve des disponibilités.</li>
</ul>

<h4>Mode de Paiement :</h4>
<ul>
<li className="text-justify">Le paiement se fait en espèces, chèque certifié ou carte interbancaire (CIB) et par carte de paiement sur le site internet de Tassili Airlines. (Les cartes de paiement acceptées sont CB, Visa et Mastercard.)</li>
<li className="text-justify">Pour chaque transaction effectuée sur le site internet de Tassili Airlines, une demande d’autorisation est effectuée en ligne selon les règles de sécurité applicables.</li>
<li className="text-justify">Le débit de la carte bancaire se fera après confirmation de la transaction bancaire.</li>
<li className="text-justify">La réservation du billet vous sera alors confirmée par e-mail envoyé à l’adresse communiquée lors de l’opération de réservation. Cette confirmation reprendra l’ensemble des éléments de la réservation (numéro de dossier, numéro de vol, provenance, destination, heure de départ et d’arrivée, prix payé) et indiquera l’heure limite d’enregistrement.</li>
</ul>

<h3>Utilisation des Billets</h3>
<ul>
<li className="text-justify">Le billet acheté est valable uniquement pour le transport du point de départ au point de destination figurant sur ce billet. Chaque parcours de vol sera valable pour le transport dans la classe tarifaire spécifiée, à la date et pour le vol correspondant.</li>
<li className="text-justify">Les billets devront être utilisés dans l’ordre séquentiel des coupons de vol, sauf accord préalable de Tassili Airlines.</li>
<li className="text-justify">En cas de modification d’horaires postérieure à l’achat de billet résultant d’un changement de programme, Tassili Airlines s’efforcera d’en informer le passager dans les meilleurs délais par téléphone et/ou autres moyens.</li>
<li className="text-justify">Le billet est nominatif et ne peut être cédé.</li>
</ul>

<h3>Durée de Validité des Billets</h3>

<p className="text-justify">Sauf conditions particulières spécifiées lors de la réservation, le billet est valable 1 an à compter de sa date d’émission.</p>

<h3>Changement de Réservation / Annulation / Remboursement d’un Billet</h3>

<p>Vous pouvez demander à modifier votre billet si les conditions tarifaires le permettent.</p>

<p>Vous devrez alors contacter :</p>

<p>Le <b>021 737 800 au tarif d’une communication locale</b> si vous êtes en pour Algérie</p>
<ul>
<li>Horaires d’ouverture 7j/7j de 8h à 20h</li>
<li>Email : reservation@tassiliairlines.com</li>
</ul>
<p>Le <b>0820 90 12 13<sup>**</sup></b> si vous êtes en France</p>

<ul>
<li>Horaires d’ouverture duLundi au Vendredide9h à 13h et de 14h à 17h</li>
<li>Email : Tassili.France@aviareps.com</li>
</ul>
<strong><sup>**</sup>0.112€ les 56 premières secondes puis 0.118€ le reste de la communication</strong>

<p>ou vous adresser à un comptoir vente de la Compagnie.</p>
<ul>
<li className="text-justify">CTassili Airlines se réserve le droit de refuser toute modification du billet (destination, horaires, dates) en raison notamment des disponibilités. En cas de modification, le billet initial n’aura plus de valeur et devra être restitué.</li>
<li className="text-justify">CUn billet d’avion est non cessible.</li>
<li className="text-justify">CEn cas de demande de modification acceptée, vous devrez, le cas échéant, payer la différence de prix. ​Toute modification sera faite sur un tarif égal ou supérieur.</li>
<li className="text-justify">CEn cas de changement, des pénalités peuvent être applicables suivant les conditions tarifaires initiales.</li>
<li className="text-justify">CLe remboursement du billet en cas d’annulation ne pourra être obtenu que si les conditions tarifaires le permettent. Tassili Airlines dispose d’un délai minimum d’un mois à compter de la réception de l’ensemble des documents demandés pour effectuer le remboursement.</li>
<li className="text-justify">CLes frais de dossiers ne sont pas remboursables.</li>
<li className="text-justify">CLes frais d’envoi par courrier postal ou express, le cas échéant, ne seront pas remboursés.</li>
</ul>

<p className="text-justify">Ces conditions s’appliquent sur les vols réguliers (nationaux et internationaux) les passagers des vols charters sont soumis à d’autres dispositions contractuelles particulières (contrat fréteur – affréteur) ;</p>


<h3>Franchises bagage</h3>

<h4>Classe «Affaire »</h4>       

40 KG en soute sur une ou plusieurs pièces et jusqu’à  12 KG en cabine

<h4>Classe «Economique »</h4>  

30 KG en soute sur une ou plusieurs pièces et jusqu’à 10 KG en cabine

<h4>Bébés</h4> 

10 KG en soute +chargement gratuit en soute d’une poussette entièrement pliable, et d’un couffin ou d’un siège auto

<h3>Protection des Données Personnelles</h3>
<ul>
<li className="text-justify">L’ensemble de vos données personnelles transmises lors de la réservation seront traitées par Tassili Airlines qui s’engage à respecter les principes moraux et légaux de protection de la vie privée et de confidentialité.</li>
<li className="text-justify">Tassili Airlines peut être tenu légalement à divulguer les données transmises dans le cadre d’une action en justice ou si ces informations sont requises par les autorités publiques compétentes.</li>
<li className="text-justify">Tassili Airlines et ses partenaires se réservent le droit d’utiliser les données collectées dans un but de communication promotionnelle ou commerciale via différents supports (téléphone, email, etc.)</li>
<li className="text-justify">Des études internes pourront en outre être effectuées au moyen de ces données dans le but d’une amélioration des services proposés et afin de satisfaire au mieux vos attentes.</li>
</ul>

<h3>Responsabilité du Transporteur</h3>

<p className="text-justify">La responsabilité de Tassili Airlines est soumise à la convention de Varsovie du 12 octobre 1929 ainsi qu’à la réglementation algérienne.</p>

<h3>Modification des Conditions d’Utilisation</h3>

<p className="text-justify">Tassili Airlines pourra à tout moment modifier ou mettre à jour les présentes conditions sans préavis.</p>

<h3>Loi applicable</h3>

<p className="text-justify">Les conditions générales sont soumises au droit algérien, par conséquent, en cas de différend, les tribunaux algériens seront seuls compétents.</p>









        
      </div>
    );
  };

  const renderTags = () => {
    return (
      <div className="max-w-screen-md mx-auto flex flex-wrap">
        <a
          className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
          href="##"
        >
          Location
        </a>
        <a
          className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
          href="##"
        >
          Aéronef
        </a>
        <a
          className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
          href="##"
        >
          Contrat
        </a>
      </div>
    );
  };

  const renderAuthor = () => {
    return (
      <div className="max-w-screen-md mx-auto ">
        <div className="nc-SingleAuthor flex">
          <Avatar sizeClass="w-11 h-11 md:w-24 md:h-24" />
          <div className="flex flex-col ml-3 max-w-lg sm:ml-5 space-y-1">
            <span className="text-xs text-neutral-400 uppercase tracking-wider">
              WRITEN BY
            </span>
            <h2 className="text-lg font-semibold text-neutral-900 dark:text-neutral-200">
              <a href="/">Fones Mimi</a>
            </h2>
            <span className="text-sm text-neutral-500 sm:text-base dark:text-neutral-300">
              There’s no stopping the tech giant. Apple now opens its 100th
              store in China.There’s no stopping the tech giant.
              <a className="text-primary-6000 font-medium ml-1" href="/">
                Readmore
              </a>
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderCommentForm = () => {
    return (
      <div className="max-w-screen-md mx-auto pt-5">
        <h3 className="text-xl font-semibold text-neutral-800 dark:text-neutral-200">
          Manifestation d'intéré pour la mise à disposition d’appareils
        </h3>
        <form className="nc-SingleCommentForm mt-5">

                <label className="block">
                  <Label>Votre Nom & Prénom</Label>
                  <Input
                    placeholder="Nom Prénom"
                    type="text"
                    className="mt-1"
                  />
                </label>

                <label className="block">
                  <Label>Société ou organisme</Label>
                  <Input
                    placeholder=""
                    type="text"
                    className="mt-1"
                  />
                </label>

                <label className="block">
                  <Label>Téléphone</Label>
                  <Input
                    placeholder=""
                    type="text"
                    className="mt-1"
                  />
                </label>

                <label className="block">
                  <Label>Votre adresse email</Label>

                  <Input
                    type="email"
                    placeholder="adresse@email.com"
                    className="mt-1"
                  />
                </label>
                <label className="block">
                  <Label>Votre message</Label>
          <Textarea />
          </label>
          <div className="mt-2 space-x-3">
            <ButtonPrimary>Envoyez</ButtonPrimary>
          </div>
        </form>
      </div>
    );
  };

  const renderCommentLists = () => {
    return (
      <div className="max-w-screen-md mx-auto">
        <ul className="nc-SingleCommentLists space-y-5">
          <li>
            <Comment />
            <ul className="pl-4 mt-5 space-y-5 md:pl-11">
              <li>
                <Comment isSmall />
              </li>
            </ul>
          </li>
          <li>
            <Comment />
            <ul className="pl-4 mt-5 space-y-5 md:pl-11">
              <li>
                <Comment isSmall />
              </li>
            </ul>
          </li>
        </ul>
      </div>
    );
  };

  const renderPostRelated = (post: PostDataType) => {
    return (
      <div
        key={post.id}
        className="relative aspect-w-3 aspect-h-4 rounded-3xl overflow-hidden group"
      >
        <Link to={post.href} />
        <NcImage
          className="object-cover w-full h-full transform group-hover:scale-105 transition-transform duration-300"
          src={post.featuredImage}
        />
        <div>
          <div className="absolute bottom-0 inset-x-0 h-1/2 bg-gradient-to-t from-black"></div>
        </div>
        <div className="flex flex-col justify-end items-start text-xs text-neutral-300 space-y-2.5 p-4">
          <Badge name="Categories" />
          <h2 className="block text-lg font-semibold text-white ">
            <span className="line-clamp-2">{post.title}</span>
          </h2>

          <div className="flex">
            <span className="block text-neutral-200 hover:text-white font-medium truncate">
              {post.author.displayName}
            </span>
            <span className="mx-1.5 font-medium">·</span>
            <span className="font-normal truncate">{post.date}</span>
          </div>
        </div>
        <Link to={post.href} />
      </div>
    );
  };

  return (
    <div className="nc-PageSingle pt-8 lg:pt-16 ">
    <Helmet>
      <title>Tassili Airlines || Charte de protection des données personnelles</title>
    </Helmet>
    {renderHeader()}

    <div className="nc-SingleContent container space-y-10">
      {renderContent()}
        <div className="max-w-screen-md mx-auto border-b border-t border-neutral-100 dark:border-neutral-700"></div>
        {/*renderAuthor()*/}
        {/*renderCommentLists()*/}
      </div>

<br/>
{/*
      <div className="relative bg-neutral-100 dark:bg-neutral-800 py-16 lg:py-28 mt-16 lg:mt-24">
        <div className="container ">
          <h2 className="text-3xl font-semibold">Autres services</h2>
          <div className="mt-10 grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 md:gap-8">
            {DEMO_POSTS.filter((_, i) => i < 4).map(renderPostRelated)}
          </div>
        </div>
      </div>

*/}

    </div>
  );
};

export default BlogSingle;
