import { MegamenuItem, NavItemType } from "shared/Navigation/NavigationItem";
import ncNanoId from "utils/ncNanoId";
import __megamenu from "./jsons/__megamenu.json";

const megaMenuDemo: MegamenuItem[] = [
  {
    id: ncNanoId(),
    image:
    "https://images.pexels.com/photos/4511194/pexels-photo-4511194.jpeg?auto=compress&cs=tinysrgb&w=600",
    title: "Nos destinations",
    items: __megamenu.map((i) => ({
      id: ncNanoId(),
      href: "/vols-reguliers",
      name: i.Company,
    })),
  },
  {
    id: ncNanoId(),
    image:
      "https://images.pexels.com/photos/9180227/pexels-photo-9180227.jpeg?auto=compress&cs=tinysrgb&w=600",
    title: "",
    items: __megamenu.map((i) => ({
      id: ncNanoId(),
      href: "/vols-reguliers",
      name: i.City,
    })),
  },
  {
    id: ncNanoId(),
    image:
    "https://images.pexels.com/photos/2092453/pexels-photo-2092453.jpeg?auto=compress&cs=tinysrgb&w=600",
    title: "",
    items: __megamenu.map((i) => ({
      id: ncNanoId(),
      href: "/vols-reguliers",
      name: i.Contruction,
    })),
  },
  {
    id: ncNanoId(),
    image:
      "https://images.pexels.com/photos/15844943/pexels-photo-15844943/free-photo-of-great-mosque-of-algiers-against-blue-sky-in-algeria.jpeg?auto=compress&cs=tinysrgb&w=600",
    title: "",
    items: __megamenu.map((i) => ({
      id: ncNanoId(),
      href: "/vols-reguliers",
      name: i.Country,
    })),
  },
];

const demoChildMenus: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/",
    name: "Gerer ma reservation",
  },
  {
    id: ncNanoId(),
    href: "/",
    name: "Web checkin",
    isNew: true,
  },
  {
    id: ncNanoId(),
    href: "/",
    name: "Confirmation",
    isNew: true,
  },
];

const otherPageChildMenus: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/blog",
    name: "Blog Page",
  },
  {
    id: ncNanoId(),
    href: "/blog-single",
    name: "Blog Single",
  },
  {
    id: ncNanoId(),
    href: "/about",
    name: "About",
  },
  {
    id: ncNanoId(),
    href: "/contact",
    name: "Contact us",
  },
  {
    id: ncNanoId(),
    href: "/login",
    name: "Login",
  },
  {
    id: ncNanoId(),
    href: "/signup",
    name: "Signup",
  },
  {
    id: ncNanoId(),
    href: "/subscription",
    name: "Subscription",
  },
];

const templatesChildrenMenus: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/add-listing-1",
    name: "Add Listings",
    type: "dropdown",
    children: [
      {
        id: ncNanoId(),
        href: "/add-listing-1",
        name: "Add Listings 1",
      },
      {
        id: ncNanoId(),
        href: "/add-listing-2",
        name: "Add Listings 2",
      },
      {
        id: ncNanoId(),
        href: "/add-listing-3",
        name: "Add Listings 3",
      },
      {
        id: ncNanoId(),
        href: "/add-listing-4",
        name: "Add Listings 4",
      },
      {
        id: ncNanoId(),
        href: "/add-listing-5",
        name: "Add Listings 5",
      },
      {
        id: ncNanoId(),
        href: "/add-listing-6",
        name: "Add Listings 6",
      },
      {
        id: ncNanoId(),
        href: "/add-listing-7",
        name: "Add Listings 7",
      },
      {
        id: ncNanoId(),
        href: "/add-listing-8",
        name: "Add Listings 8",
      },
      {
        id: ncNanoId(),
        href: "/add-listing-9",
        name: "Add Listings 9",
      },
      {
        id: ncNanoId(),
        href: "/add-listing-10",
        name: "Add Listings 10",
      },
    ],
  },
  //
  { id: ncNanoId(), href: "/checkout", name: "Checkout" },
  { id: ncNanoId(), href: "/pay-done", name: "Pay done" },
  //
  { id: ncNanoId(), href: "/author", name: "Author Page" },
  { id: ncNanoId(), href: "/account", name: "Account Page" },
];

export const NAVIGATION_DEMO: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/",
    name: "Accueil",
    type: "megaMenu",
    megaMenu: megaMenuDemo,
  },
  {
    id: ncNanoId(),
    href: "/vols-reguliers",
    name: "Activités",
    type: "dropdown",
    isNew: true,
    children: [
      {
        id: ncNanoId(),
        href: "/vols-reguliers",
        name: "Vols réguliers",
      },
      //
      {
        id: ncNanoId(),
        href: "/vols-a-la-demande",
        name: "Vols à la demande",
      },
      //
      {
        id: ncNanoId(),
        href: "/vols-charters",
        name: "Vols charters",
      },
      //
      {
        id: ncNanoId(),
        href: "/vols-navette",
        name: "Vols navette",
      },
      //
      {
        id: ncNanoId(),
        href: "/vols-cargo",
        name: "Vols cargo",
      },
    ],
  },
  {
    id: ncNanoId(),
    href: "https://fly.tassiliairlines.com/B2C/fr/ManageBooking",
    name: "Ma Reservation",
    type: "dropdown",
    children: [
      {
        id: ncNanoId(),
        href: "https://fly.tassiliairlines.com/B2C/fr/ManageBooking",
        name: "Modifier ma réservation",
      },
    {
      id: ncNanoId(),
      href: "https://fly.tassiliairlines.com/B2C/fr/RetrieveTicket",
      name: "Email de confirmation",
    },
    {
      id: ncNanoId(),
      href: "https://fly.tassiliairlines.com/B2C/fr/WebCheckin",
      name: "Web checkin",
    },
]
},
  {
    id: ncNanoId(),
    href: "/contact",
    name: "Aide",
    type: "dropdown",
    children: [
      {
        id: ncNanoId(),
        href: "/contact",
        name: "Contactez-nous",
      },
      {
        id: ncNanoId(),
        href: "/prog-national",
        name: "Horaire vols nationaux",
      },
      {
        id: ncNanoId(),
        href: "/prog-international",
        name: "Vols internationaux",
      },
      {
        id: ncNanoId(),
        href: "/bagage",
        name: "Franchise bagages",
      },
      {
        id: ncNanoId(),
        href: "/about",
        name: "À propos de T A L",
      },
    ],
  },
/*
  {
    id: ncNanoId(),
    href: "/blog",
    name: "Other pages",
    type: "dropdown",
    children: otherPageChildMenus,
  },*/
];
