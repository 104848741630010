import React, { InputHTMLAttributes, useState, useEffect } from "react";
import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Checkbox from "shared/Checkbox/Checkbox";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import axios from 'axios';
import { frFR } from '@mui/x-date-pickers/locales';
import dayjs, { Dayjs } from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateField } from '@mui/x-date-pickers/DateField';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';

const StyledButton = styled(IconButton)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
}));
const StyledDay = styled(PickersDay)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  color:
    theme.palette.mode === 'light'
      ? theme.palette.secondary.dark
      : theme.palette.secondary.light,
}));


const   FormInputCadre = {
    borderColor: 'rgb(0 0 0 / 0.25)',
    borderWidth: 'thin',
  }

const MicroForm = () => {

  let [ captureContex, setCaptureContex]=React.useState<string>('');



  const handleSubmit = async (event:any) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();


  };

function handleVerify()
{
  ;
}



useEffect(() => {

  axios.get('http://localhost:3000/capturecontext')
  .then(response => {
    setCaptureContex(response.data);
    const script = document.createElement('script');
    script.src = "https://flex.cybersource.com/cybersource/assets/microform/0.11/flex-microform.min.js";
    script.async = true;
    document.body.appendChild(script);
    const script1 = document.createElement('script');
    script1.src = "https://paracenter.dz/tassapi/microformLoad.js?"+response.data;
    document.body.appendChild(script1);
  return () => {
      document.body.removeChild(script);
    }  
  
  
  })
  .catch(error => {
    console.error(error);
  });






  }, []);



  const renderMonthContent = (month:number, shortMonth:string, longMonth:string, day:number) => {
    const fullYear = new Date(day).getFullYear();
    const tooltipText = `Tooltip for month: ${longMonth} ${fullYear}`;

    return <span title={tooltipText}>{shortMonth}</span>;
  };

  const [startDate, setStartDate] = useState<Date>();

  let [dateExpiration, setDateExpValue] = React.useState<Dayjs | null>(dayjs(new Date()));
  let [expYear, setExpYear] = React.useState<number>(dayjs(new Date()).get('year'));
  let [expMonth, setExpMonth] = React.useState<number>(dayjs(new Date()).get('month'));
 

function SetDateExpValue(newValue:Dayjs | null)
{
  setDateExpValue(newValue);
  dateExpiration=newValue;

  setExpYear( (dateExpiration!==null?dateExpiration.get('year'):2025 ));
  setExpMonth( 1+(dateExpiration!==null?dateExpiration.get('month'):12 ));

  expYear = (dateExpiration!==null?dateExpiration.get('year'):2025 );
  expMonth = 1+(dateExpiration!==null?dateExpiration.get('month'):12 );

}



  return (

<div className="space-y-5">
<div className="space-y-5">
    <h1>Paiement par carte</h1>
    <div id="errors-output" role="alert"></div>
    <form action="http://localhost:3000/receipt" id="my-sample-form" method="post">
        <div className="form-group space-y-2">


        <div className="space-y-2">
                    <Label>Nom du porteur</Label>
                    <Input id="cardholderName" className="form-control" name="cardholderName" placeholder="Nom sur la carte" defaultValue="" />
        </div>
        <div className="space-y-2 space-x-5 flex">
          <div className="w-[60%]">
            <label id="cardNumber-label">Numéro de carte</label>
            <div id="number-container" className="h-12 form-control flex-microform block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3 flex-microform-invalid" style={FormInputCadre}></div>
          </div>
          <div className="w-[20%]">
          <Label>Date d'expiration</Label>
        <LocalizationProvider dateAdapter={AdapterDayjs} localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}>
        <DateField

        sx={{ border: 0 }}
          defaultValue={null}
          aria-placeholder="MM/AAAA"
          format="MM/YYYY"
          className="h-12"  
          onChange={(newValue) => SetDateExpValue(newValue)}
        />
    </LocalizationProvider>

          </div>

          <div className="w-[20%]">
            <label >CVC</label>
            <div id="securityCode-container" className="h-12 form-control flex-microform block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3 flex-microform-invalid"  style={FormInputCadre}></div>
          </div>

        </div>
        </div>

        <div className="pt-8 w-full">

<div className="space-y-2 space-x-2 pb-4 flex">
              <Checkbox  name="regleconfid"/><Label>J'accepte la politique de confidentialité et les condition d'utilisation.</Label>

            </div>

            <input type="hidden"  id="expYear" name="expYear" value={expYear} />
            <input type="hidden"  id="expMonth" name="expMonth" value={expMonth} />

            <button type="button" id="pay-button" className="btn btn-primary">Payer</button>

    
      </div>


        <input type="hidden" id="flexresponse" name="flexresponse" />
    </form>

    <ButtonPrimary id="pay-button1" className="w-full" disabled={false}>Confirmer et payer</ButtonPrimary>


</div>



</div>









  )
};

export default MicroForm;