import DatePicker from "react-datepicker";
import React, { FC, useState } from "react";
import DatePickerCustomHeaderTwoMonth from "components/DatePickerCustomHeaderTwoMonth";
import DatePickerCustomDay from "components/DatePickerCustomDay";
import { CalendarIcon } from "@heroicons/react/24/outline";

export interface StayDatesRangeInputProps {
  className?: string;
  fieldClassName?: string;
  hasButtonSubmit?: boolean;
  selectsRange?: boolean;
  startLoc?: string;
  endLoc?: string;
  flyType?: string;
  flyClass?:string;
  adultInputValue?: number;
  childrenInputValue?: number;
  infantsInputValue?: number;
  onChange?:(strtDate:Date|null,edDate:Date|null) => void;
}

const StayDatesRangeInput: FC<StayDatesRangeInputProps> = ({
  className = "",
  fieldClassName = "[ nc-hero-field-padding ]",
  hasButtonSubmit = true,
  selectsRange = true,
  startLoc="",
  endLoc="",
  flyType="Aller simple",
  flyClass="Économique",
  adultInputValue=1,
  childrenInputValue=0,
  infantsInputValue=0,  
  onChange
}) => {
  const [startDate, setStartDate] = useState<Date | null>(
    new Date()
  );
  const [endDate, setEndDate] = useState<Date | null>(new Date());

  const onChangeDate = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    onChange?onChange(start,end):setEndDate(end);
  };

  const renderInput = () => {
    return (
      <>
        <div className="text-neutral-300 dark:text-neutral-400">
          <CalendarIcon className="w-5 h-5 lg:w-7 lg:h-7" />
        </div>
        <div className="flex-grow text-left">
          <span className="block xl:text-lg font-semibold">
            {startDate?.toLocaleDateString("fr-FR", {
              month: "short",
              day: "2-digit",
            }) || "Choisir les dates"}
            {selectsRange && endDate
              ? " - " +
                endDate?.toLocaleDateString("fr-FR", {
                  month: "short",
                  day: "2-digit",
                })
              : ""}
          </span>
          <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
            {selectsRange ? "Départ - Arrivée" : "Date de départ"}
          </span>
        </div>
      </>
    );
  };

  return (
    <div>
      <div className="p-5">
        <span className="block font-semibold text-xl sm:text-2xl">
          {` C'est pour quand, votre voyage?`}          
        </span>
      </div>
      <div
        className={`relative flex-shrink-0 flex justify-center z-10 py-5 ${className} `}
      >

{selectsRange ? (
        <DatePicker
          selected={startDate}
          onChange={onChangeDate}
          startDate={startDate}
          endDate={endDate}
          selectsRange
          monthsShown={2}
          showPopperArrow={false}
          minDate={new Date()}
          inline
          renderCustomHeader={(p) => <DatePickerCustomHeaderTwoMonth {...p} />}
          renderDayContents={(day, date) => (
            <DatePickerCustomDay dayOfMonth={day} date={date} />
          )}
        />
      ) : (
        <DatePicker
          selected={startDate}
          onChange={(date) => { setStartDate(date); onChange?onChange(date,null):setStartDate(date); } }
          monthsShown={2}
          showPopperArrow={false}
          minDate={new Date()}
          inline
          renderCustomHeader={(p) => <DatePickerCustomHeaderTwoMonth {...p} />}
          renderDayContents={(day, date) => (
            <DatePickerCustomDay dayOfMonth={day} date={date} />
          )}
/>
        )}
      </div>
    </div>
  );
};

export default StayDatesRangeInput;
